import { SWROptions } from '..';
import { ApiRecord, ResourceType } from '../resources';
import { useApiCache } from './useApiCache';

export type ListingParams = { filter?: string; search?: string; skip?: number; limit?: number; sort?: string };
export type ListingResponse<T> = { count: number; data: T[] };

export function useApiList<const T extends ResourceType, TData = ApiRecord<T>>(
  resourceType: T,
  options?: SWROptions<ListingResponse<TData>> & { params?: ListingParams },
) {
  return useApiCache<T, { count: number; data: TData[] }>(resourceType, '', options);
}

import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { useTranslation } from 'react-i18next';

import { FlexBox, Span } from '../../atoms';

export function NoRowsOverlay() {
  const { t } = useTranslation();

  return (
    <FlexBox column sx={{ alignItems: 'center', gap: 1, height: '100%', justifyContent: 'center' }}>
      <InboxTwoToneIcon fontSize="large" />
      <Span>{t('tables.labels.noRows')}</Span>
    </FlexBox>
  );
}

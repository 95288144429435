import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GiftCard, GiftCardStatus, StatusChange } from '../../../../common/api/resources/gift-card';
import { TableColDef } from '../../../../common/tables';
import { useGiftCardStatusLabels } from '../../../../hooks/useGiftCardStatusLabels';
import { useIsEditor } from '../../../../hooks/useIsEditor';
import { Link, Span } from '../../../atoms';
import { Table } from '../../../organisms';
import { GiftCardHistoryDialog } from './GiftCardHistoryDialog';
import { GiftCardStatusUpdateDialog } from './GiftCardStatusUpdateDialog';

export function GiftCardsAdminTable() {
  const { t } = useTranslation();

  const labels = useMemo(() => ({ deletion: t('tables.giftcards.labels.deletionInfo') }), [t]);

  const [statusChanges, setStatusChanges] = useState<StatusChange[]>();
  const [currentGiftCard, setCurrentGiftCard] = useState<GiftCard>();

  const statusLabels = useGiftCardStatusLabels();
  const isEditor = useIsEditor('adminGiftCardRequests');

  const getStatusCell = useCallback(
    ({ formattedValue, row: giftCard }: GridRenderCellParams<GiftCard>) => {
      if (formattedValue && !giftCard.deletedAt) {
        const span = (
          <Span
            sx={[
              { textTransform: 'uppercase' },
              giftCard.status === 'REJECTED' && { color: 'error.main' },
              giftCard.status === 'ACTIVE' && { color: 'success.main' },
            ]}
          >
            {formattedValue}
          </Span>
        );

        return isEditor ? (
          <Link
            onClick={() => {
              if (giftCard.status !== 'ACTIVE' && !giftCard.deletedAt) {
                setCurrentGiftCard(giftCard);
              }
            }}
            sx={[
              giftCard.status === 'REJECTED' && {
                textDecorationColor: ({ palette }) => palette.error.light,
                '&:hover': { textDecorationColor: ({ palette }) => palette.error.main },
              },
              giftCard.status === 'ACTIVE' && { cursor: 'default', textDecoration: 'none' },
            ]}
          >
            {span}
          </Link>
        ) : (
          span
        );
      }
    },
    [isEditor],
  );

  const columns = useMemo(
    (): TableColDef<GiftCard>[] => [
      { field: 'company.name', valueGetter: (_, { company }) => company?.name },
      { field: 'createdAt', valueType: 'date' },
      { field: 'nameForClient' },
      { field: 'prefixVoucher' },
      { field: 'amount', valueType: 'money' },
      { field: 'quantity' },
      { field: 'totalAmount', valueType: 'money' },
      {
        field: 'status',
        renderCell: getStatusCell,
        valueFormatter: (value) =>
          typeof value === 'string' && value in statusLabels ? statusLabels[value as GiftCardStatus] : undefined,
      },
      {
        field: 'statusLastChangedBy',
        renderCell: ({ formattedValue, row: giftCard }) => (
          <Link onClick={() => setStatusChanges(giftCard.statusChanges)}>{formattedValue}</Link>
        ),
        sortFields: ['lastStatusChange.author.firstname'],
        valueGetter: (_, giftCard) => giftCard.lastStatusChange?.author,
        valueFormatter: (changeAuthor) => {
          if (changeAuthor) {
            const { firstname, lastname } = changeAuthor as { firstname: string; lastname: string };
            return `${firstname} ${lastname}`;
          }

          return '';
        },
      },
    ],
    [getStatusCell, statusLabels],
  );

  const deletionInfoText = useCallback(
    ({ row: giftCard }: { row: GiftCard }) => {
      if (giftCard.vouchers?.length) {
        return labels.deletion;
      }
    },
    [labels.deletion],
  );

  const closeHistoryDialog = useCallback(() => {
    setStatusChanges(undefined);
  }, []);

  const closeUpdateDialog = useCallback(() => {
    setCurrentGiftCard(undefined);
  }, []);

  return (
    <>
      <Table
        columns={columns}
        deletionInfoText={deletionInfoText}
        isRowDeletable={({ row: { vouchers } }) => !vouchers?.length}
        name="giftCardsAdmin"
      />

      <GiftCardHistoryDialog changes={statusChanges} onClose={closeHistoryDialog} open={Boolean(statusChanges)} />

      <GiftCardStatusUpdateDialog
        giftCard={currentGiftCard}
        onClose={closeUpdateDialog}
        open={isEditor && Boolean(currentGiftCard)}
      />
    </>
  );
}

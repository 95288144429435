import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { withNonBreakingHyphens } from '@sbiz/util-strings';

import { ApiFetcherOptions } from '../../../../common/api';
import { useApi } from '../../../../common/api/hooks/useApi';
import { Statement } from '../../../../common/api/resources/statement';
import { useFormErrorAlertProp } from '../../../../hooks/useFormErrorAlertProp';
import { DateField } from '../../../atoms';
import { DialogFormProps, FormDialog, FormDialogOverrideProps } from '../../../molecules/FormDialog';
import { Form } from '../../../organisms';

const STATEMENT_PAYMENT_DIALOG_NAME = 'statementPayment';

export function StatementPaymentDialog({
  isAdmin,
  statement,
  ...props
}: { isAdmin?: boolean; statement?: Statement } & Omit<FormDialogOverrideProps, 'open'>) {
  const { t } = useTranslation();

  const title = useMemo(
    () =>
      t(`dialogs.${STATEMENT_PAYMENT_DIALOG_NAME}.title`, {
        number: statement?.invoiceRef && withNonBreakingHyphens(statement.invoiceRef),
      }),
    [statement?.invoiceRef, t],
  );

  const Form = useCallback(
    (props: DialogFormProps) => <StatementPaymentForm isAdmin={isAdmin} statementId={statement?._id} {...props} />,
    [isAdmin, statement?._id],
  );

  return (
    <FormDialog
      Form={Form}
      maxWidth="xs"
      name={STATEMENT_PAYMENT_DIALOG_NAME}
      open={Boolean(statement?._id)}
      title={title}
      {...props}
    />
  );
}

function StatementPaymentForm({
  isAdmin,
  onSubmit,
  statementId,
  ...props
}: { isAdmin?: boolean; statementId?: string } & DialogFormProps) {
  const { t } = useTranslation();

  const label = useMemo(() => t('resources.statement.propertyNames.paidAt'), [t]);

  const [date, setDate] = useState<Date | null>(new Date());
  const [isDateError, setIsDateError] = useState(false);

  const { patch } = useApi('statement');
  const { patch: adminPatch } = useApi('statementAdmin');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();

  const parts = useMemo(
    () => [
      () => (
        <DateField
          label={label}
          maxDate={new Date()}
          onChange={setDate}
          onError={(error) => {
            setIsDateError(Boolean(error));
          }}
          required
          value={date}
        />
      ),
    ],
    [date, label],
  );

  const submitBtnProps = useMemo(() => {
    if (isDateError) {
      return { disabled: true };
    }
  }, [isDateError]);

  const handleSubmit = useCallback(async () => {
    if (statementId) {
      const path = `${statementId}/payment`;
      const data = { action: 'pay', date };

      const fetcher: ApiFetcherOptions = { clear: ['statement', 'statementAdmin'] };
      const { error } = await (isAdmin ? adminPatch(path, { data, fetcher }) : patch(path, { data, fetcher }));

      setErrorAlert(error);

      if (!error) {
        onSubmit();
      }
    }
  }, [adminPatch, date, isAdmin, onSubmit, patch, setErrorAlert, statementId]);

  return <Form alert={errorAlert} onSubmit={handleSubmit} parts={parts} submitBtnProps={submitBtnProps} {...props} />;
}

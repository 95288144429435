import { useMemo } from 'react';

import { FULLNAME_TABLE_COLUMN, TableColDef } from '../../../common/tables';
import { Table } from '../../organisms';

type Manager = {
  _id: string;
  deletedAt: string;
  firstname: string;
  lastname: string;
};

export function ManagersTable() {
  const columns = useMemo(
    (): TableColDef<Manager>[] => [
      FULLNAME_TABLE_COLUMN,
      { field: 'email' },
      { field: 'createdAt', valueType: 'date' },
    ],
    [],
  );

  return <Table columns={columns} name="managers" />;
}

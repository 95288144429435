import { SWISS_PHONE_CODE } from '@sbiz/common';

export function capitalize(string: string) {
  return replaceAt(string, 0, string.charAt(0).toUpperCase());
}

export function escapeRegex(string: string) {
  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
}

export function formatPhoneNumber(phoneNumber: string) {
  if (phoneNumber.length < SWISS_PHONE_CODE.length) {
    return SWISS_PHONE_CODE;
  }

  return phoneNumber;
}

export function getChars(string: string) {
  return string.split('');
}

export function replaceAt(string: string, index: number, replacement: string) {
  if (index > string.length - 1) {
    return string;
  }

  return `${string.substring(0, index)}${replacement}${string.substring(index + 1)}`;
}

export function uncapitalize(string: string) {
  return replaceAt(string, 0, string.charAt(0).toLowerCase());
}

export function withNonBreakingHyphens(string: string) {
  return string.replaceAll('-', '\u{2011}');
}

import { Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyJson } from '@sbiz/business';

import { AddressLink, Link } from '../../../atoms';
import { FormDialog } from '../../../molecules';
import { CompanyTable } from '../CompanyTable';
import { LocationDeletionButton } from './LocationDeletionButton';
import { LocationForm } from './LocationForm';

export function LocationList({ company, onDeletion }: { company: CompanyJson; onDeletion: () => void }) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      address: t('resources.company.propertyNames.dropOffAreas_fullAddress'),
      name: t('resources.company.propertyNames.dropOffAreas_name'),
      noLocations: t('pages.company_locations.noLocations'),
      radius: t('resources.company.propertyNames.dropOffAreas_radius'),
    }),
    [t],
  );

  const [editedLocationIndex, setEditedLocationIndex] = useState<number>();
  const isEdition = typeof editedLocationIndex === 'number';

  const headers = useMemo(
    () => [labels.name, labels.address, labels.radius, ''],
    [labels.address, labels.name, labels.radius],
  );

  const closeForm = useCallback(() => {
    setEditedLocationIndex(undefined);
  }, []);

  const openForm = useCallback(
    (index: number) => () => {
      setEditedLocationIndex(index);
    },
    [],
  );

  const rows = useMemo(
    () =>
      company.dropOffAreas?.map(({ address, name, radius }, index) => [
        <Link key={0} onClick={openForm(index)}>
          {name}
        </Link>,
        <AddressLink address={address} key={1} />,
        radius,
        <LocationDeletionButton index={index} key={2} locations={company.dropOffAreas} onDeletion={onDeletion} />,
      ]),
    [company.dropOffAreas, onDeletion, openForm],
  );

  return (
    <>
      {rows?.length ? <CompanyTable headers={headers} rows={rows} /> : <Typography>{labels.noLocations}</Typography>}

      <FormDialog
        Form={
          company &&
          isEdition &&
          ((props) => <LocationForm company={company} locationIndex={editedLocationIndex} {...props} />)
        }
        name="locationUpdate"
        onClose={closeForm}
        open={isEdition}
      />
    </>
  );
}

import { Table, TableBody, TableCell, TableContainer, TableHead, TableProps, TableRow } from '@mui/material';
import { ReactNode } from 'react';

export function CompanyTable({ headers, rows, ...props }: { headers: string[]; rows: ReactNode[][] } & TableProps) {
  return (
    <TableContainer sx={{ maxHeight: 480, pb: 0.2, scrollbarWidth: 'thin' }}>
      <Table {...props}>
        <TableHead sx={{ backgroundColor: ({ palette: { background } }) => background.primary }}>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((cells, rowIndex) => (
            <TableRow key={rowIndex} sx={[rowIndex % 2 !== 0 && { backgroundColor: 'background.default' }]}>
              {cells.map((cell, cellIndex) => (
                <TableCell key={cellIndex}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { Box, Paper } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getDateInFuture } from '@sbiz/util-dates';

import { useFormatDate } from '../../../../../hooks/useFormatDate';
import { FlexBox } from '../../../../atoms';

export function OfferMonths({
  defaultSelection,
  monthCount,
  onChange,
}: {
  defaultSelection: Set<number>;
  monthCount: number;
  onChange: (selection: Set<number>) => void;
}) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [previewEndIndex, setPreviewEndIndex] = useState<number>();
  const [previewStartIndex, setPreviewStartIndex] = useState<number>();
  const [selection, setSelection] = useState(defaultSelection);

  const isSelectionChange = useRef(false);

  const formatDate = useFormatDate();

  const isRemoval = useMemo(
    () => previewStartIndex !== undefined && selection.has(previewStartIndex),
    [previewStartIndex, selection],
  );

  const months = useMemo(
    (): Readonly<string[]> =>
      Array.from({ length: monthCount }, (_, index) => {
        const date = index === 0 ? new Date() : getDateInFuture(index, 'months');
        return formatDate(date, 'MMMM yyyy');
      }),
    [formatDate, monthCount],
  );

  const preview = useMemo(() => {
    if (previewStartIndex !== undefined) {
      const endIndex = previewEndIndex ?? previewStartIndex;
      const [start, end] = [previewStartIndex, endIndex].sort((a, b) => a - b);
      return { start, end };
    }
  }, [previewEndIndex, previewStartIndex]);

  const handleClickEnd = useCallback(() => {
    setIsMouseDown(false);
    setPreviewStartIndex(undefined);
    setPreviewEndIndex(undefined);
  }, []);

  const handleMouseDown = useCallback(
    (index: number) => () => {
      setIsMouseDown(true);
      setPreviewStartIndex(index);
    },
    [],
  );

  const handleMouseEnter = useCallback(
    (index: number) => (event: MouseEvent) => {
      event.stopPropagation();

      if (isMouseDown) {
        setPreviewEndIndex(index);
      } else {
        setPreviewStartIndex(index);
      }
    },
    [isMouseDown],
  );

  const handleMouseLeave = useCallback(() => {
    setPreviewEndIndex(undefined);

    if (!isMouseDown) {
      setPreviewStartIndex(undefined);
    }
  }, [isMouseDown]);

  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (preview) {
        setSelection((currentValue) => {
          const newSelection = new Set(currentValue);

          for (let index = preview.start; index <= preview.end; index++) {
            if (isRemoval) {
              newSelection.delete(index);
            } else {
              newSelection.add(index);
            }
          }

          return newSelection;
        });

        isSelectionChange.current = true;
      }

      handleClickEnd();
    },
    [handleClickEnd, isRemoval, preview],
  );

  useEffect(() => {
    if (isSelectionChange.current) {
      isSelectionChange.current = false;
      onChange(selection);
    }
  }, [onChange, selection]);

  useEffect(() => {
    document.addEventListener('mouseup', handleClickEnd);

    return () => {
      document.removeEventListener('mouseup', handleClickEnd);
    };
  }, [handleClickEnd]);

  return (
    <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(3, 1fr)', mb: 4 }} onMouseLeave={handleMouseLeave}>
      {months.map((month, index) => {
        const isInPreview = Boolean(preview && index >= preview.start && index <= preview.end);
        const isAdded = isInPreview && !isRemoval;
        const isSelected = !isInPreview && selection.has(index);

        return (
          <Paper
            component={FlexBox}
            key={month}
            onMouseDown={handleMouseDown(index)}
            onMouseEnter={handleMouseEnter(index)}
            onMouseUp={handleMouseUp}
            sx={[
              {
                alignItems: 'center',
                backgroundColor: ({ palette: { grey } }) => grey[400],
                cursor: 'pointer',
                height: 100,
                justifyContent: 'center',
                p: 0.8,
                textTransform: 'capitalize',
                transition: 'background-color 0.3s',
                userSelect: 'none',
              },
              isInPreview && {
                backgroundColor: ({ palette: { grey, primary } }) => (isRemoval ? grey[200] : primary.light),
              },
              isSelected && { backgroundColor: ({ palette: { primary } }) => primary.main },
              (isAdded || isSelected) && { color: ({ palette: { common } }) => common.white },
            ]}
          >
            {month}
          </Paper>
        );
      })}
    </Box>
  );
}

import { Paper, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckTotalsDisplay } from '../../../../common/api/resources/check';
import { FlexBox, Span } from '../../../atoms';

export function CheckSummary({ totals: { employeeCount: count, totalAmount } }: { totals: CheckTotalsDisplay }) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      count: t('tables.checks.labels.summaryEmployeeCount', { count }),
      title: t('tables.checks.labels.summaryTitle'),
    }),
    [count, t],
  );

  return (
    <Paper component={FlexBox} sx={{ alignItems: 'center', gap: 1, px: 2.5, py: 1 }}>
      <Typography color="text.secondary" component={Span} sx={{ fontSize: '0.95em' }}>
        {labels.title}
      </Typography>
      <Typography component={Span} sx={{ fontSize: '1.05em', fontWeight: 600 }}>
        {totalAmount}
      </Typography>
      <Typography color="text.secondary" component={Span} sx={{ fontSize: '0.95em' }}>
        {labels.count}
      </Typography>
    </Paper>
  );
}

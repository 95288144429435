import { Box } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { Link } from '../../../../atoms';

const LINE_HEIGHT = '1rem';
const MAX_HEIGHT = '2rem';
const MAX_LINE_COUNT = 2;

export type ProductOptionsProps = {
  isExport?: boolean;
  labels: Record<'showLess' | 'showMore', string>;
  options: { name: string; quantity: number }[];
};

export function ProductOptions({ isExport, labels, options }: ProductOptionsProps) {
  const [maxHeight, setMaxHeight] = useState<string | undefined>(isExport ? undefined : MAX_HEIGHT);

  const isToggleDisplayed = useMemo(() => !isExport && options && options.length > MAX_LINE_COUNT, [isExport, options]);

  const toggleMaxHeight = useCallback(() => {
    if (isToggleDisplayed) {
      setMaxHeight((height) => (height ? undefined : MAX_HEIGHT));
    }
  }, [isToggleDisplayed]);

  if (!options?.length) {
    return null;
  }

  return (
    <Box sx={{ fontSize: '0.8em' }}>
      <Box sx={{ maxHeight, overflowY: 'hidden' }}>
        {options.map(({ name, quantity }, index) => (
          <Box key={index} sx={{ lineHeight: LINE_HEIGHT }}>
            {quantity} x {name}
          </Box>
        ))}
      </Box>

      {isToggleDisplayed && <Link onClick={toggleMaxHeight}>{maxHeight ? labels.showMore : labels.showLess}</Link>}
    </Box>
  );
}

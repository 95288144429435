import { createContext, ReactNode, useContext } from 'react';

import { EMPTY_ASYNC_FUNCTION } from '@sbiz/common';

import { AuthUser } from '../common/session';
import { useAuthRedirection } from '../hooks/useAuthRedirection';
import { AuthUserMutator } from '../hooks/useAuthUser';

const AuthUserContext = createContext<{ authUser: AuthUser; mutate: AuthUserMutator }>({
  authUser: {} as AuthUser,
  mutate: EMPTY_ASYNC_FUNCTION,
});

export function AuthUserContextProvider({ children }: { children: ReactNode }) {
  const { authUser, mutate, routeOverride } = useAuthRedirection();

  return authUser?.accessToken && !routeOverride ? (
    <AuthUserContext.Provider value={{ authUser, mutate }}>{children}</AuthUserContext.Provider>
  ) : null;
}

export function useAuthUserContext() {
  return useContext(AuthUserContext);
}

import { Table, TableBody, TableCell, TableContainer, TableContainerProps, TableRow } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGS } from '@sbiz/common';

import { InvalidCsvRow } from '../../../common/api';
import { styledProps } from '../../../common/styles';
import { useFormatDate } from '../../../hooks/useFormatDate';

export const FILE_DATE_FORMAT = 'yyyy-MM-dd';

export function FileInvalidRows({ rows, ...containerProps }: { rows: InvalidCsvRow[] } & TableContainerProps) {
  return (
    <TableContainer {...styledProps({ maxHeight: 480, pb: 0.2, scrollbarWidth: 'thin' }, containerProps)}>
      <Table size="small" sx={{ borderCollapse: 'collapse' }}>
        <TableBody>
          {rows?.map(({ row: cells, error, invalidCells }, rowIndex) => (
            <>
              <TableRow key={rowIndex}>
                {cells.map((cell, cellIndex) => (
                  <FileCell error={invalidCells?.[cellIndex]} key={cellIndex} value={cell} />
                ))}
              </TableRow>
              {error && (
                <TableRow>
                  <FileCell error={error} value="" colSpan={cells.length} />
                </TableRow>
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function FileCell({ colSpan, error, value }: { colSpan?: number; error?: string | null; value: string }) {
  const formatDate = useFormatDate();
  const { t } = useTranslation();

  const content = useMemo(() => {
    if (error) {
      const params = {
        dateString: formatDate(new Date(), FILE_DATE_FORMAT),
        format: t(`common.dateFormat.${FILE_DATE_FORMAT}`),
        langs: `"${LANGS.map((lang) => lang.toUpperCase()).join('", "')}"`,
      };

      const text = t(
        [`forms.fileUpload.errors.${error}`, `forms.fields.errors.${error}`, `api.error.code.${error}`],
        params,
      );

      return `${text}${value ? `\n${value} \u{274c}` : ''}`;
    }
    return value;
  }, [error, formatDate, t, value]);

  return (
    <TableCell
      align="center"
      colSpan={colSpan ?? 1}
      sx={[
        { border: '1px solid', borderColor: ({ palette }) => palette.divider, whiteSpace: 'pre-line' },
        Boolean(colSpan) && { fontSize: 'small', py: 0.4 },
        Boolean(error) && { backgroundColor: '#fdeded', color: '#5f2120' },
      ]}
    >
      {content}
    </TableCell>
  );
}

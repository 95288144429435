import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GIFT_CARD_STATUSES, GiftCardStatus } from '../common/api/resources/gift-card';

export function useGiftCardStatusLabels() {
  const { t } = useTranslation();

  return useMemo(() => {
    const labelEntries = GIFT_CARD_STATUSES.map((status) => [status, t(`status.${status.toLowerCase()}`)]);
    return Object.fromEntries(labelEntries) as Record<GiftCardStatus, string>;
  }, [t]);
}

import { useMemo } from 'react';

import { styledProps } from '../../common/styles';
import { Link, LinkProps } from './Link';

export function TableCellLink({ children, ...props }: LinkProps) {
  const linkProps = useMemo(
    () =>
      styledProps(
        { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
        { ...props, ...(!props.title && typeof children === 'string' && { title: children }) },
      ),
    [children, props],
  );

  return <Link {...linkProps}>{children}</Link>;
}

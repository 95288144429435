import { Statement } from '../../../../common/api/resources/statement';
import { StatementDownloadButton } from './StatementDownloadButton';
import { StatementPaymentButton } from './StatementPaymentButton';

export function StatementActionButtons({
  onPaymentButtonClick,
  statement,
  ...props
}: {
  isAdmin?: boolean;
  onPaymentButtonClick: () => Promise<void>;
  statement: Statement;
}) {
  const statementId = statement._id;

  return (
    <>
      <StatementDownloadButton statementId={statementId} type="pdf" {...props} />
      <StatementDownloadButton statementId={statementId} type="csv" {...props} />
      <StatementPaymentButton
        onClick={onPaymentButtonClick}
        permissionsScope={props.isAdmin ? 'adminOpenInvoices' : 'accounting'}
        statement={statement}
      />
    </>
  );
}

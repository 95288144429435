import { InsertLink, Send } from '@mui/icons-material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InvitationInterval } from '@sbiz/business';
import { getDateInFuture } from '@sbiz/util-dates';

import { ApiFetcherOptions } from '../../../../common/api';
import { useApi } from '../../../../common/api/hooks/useApi';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { useIsEditor } from '../../../../hooks/useIsEditor';
import { Button } from '../../../atoms';

export function InvitationExtraActions({
  interval,
  invitationId,
  invitedAt: initialInvitedAt,
  tableName,
}: {
  interval?: InvitationInterval | null;
  invitationId: string;
  invitedAt: string;
  tableName: 'employeeInvitations' | 'managerInvitations';
}) {
  const isManagerInvitation = tableName === 'managerInvitations';

  const { t } = useTranslation();

  const [refreshedAt, setRefreshedAt] = useState<string>();
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);

  const { get: getEmployee, patch: patchEmployee } = useApi('employeeInvitation');
  const { get: getManager, patch: patchManager } = useApi('managerInvitation');
  const formatDate = useFormatDate();
  const isAllowed = useIsEditor(isManagerInvitation ? 'managers' : 'employees');

  const invitedAt = useMemo(() => refreshedAt ?? initialInvitedAt, [initialInvitedAt, refreshedAt]);

  const limitDate = useMemo(() => {
    if (invitedAt) {
      if (interval) {
        const { amount, unit } = interval;
        return getDateInFuture(amount, unit, invitedAt);
      }
    } else {
      return new Date();
    }
  }, [interval, invitedAt]);
  const isAfterInterval = limitDate && new Date() >= limitDate;

  const linkTooltipTitle = useMemo(
    () => t(`tables.${tableName}.labels.clipboard.${isLinkCopied ? 'copied' : 'copy'}`),
    [isLinkCopied, t, tableName],
  );

  const refreshTooltipTitle = useMemo(() => {
    if (isRefreshed) {
      return t(`tables.${tableName}.labels.refresh.sent`);
    }

    if (isAfterInterval || !limitDate) {
      return t(`tables.${tableName}.labels.refresh.send`);
    }

    return t('tooltips.invitations.interval', { limitDate: formatDate(limitDate, 'PPp') });
  }, [formatDate, isAfterInterval, isRefreshed, limitDate, t, tableName]);

  const copyToClipboard = useCallback((text: string) => {
    const type = 'text/plain';
    navigator.clipboard.write([new ClipboardItem({ [type]: new Blob([text], { type }) })]);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 2000);
  }, []);

  const copyLinkToClipboard = useCallback(async () => {
    const path = `${invitationId}/link`;
    const { data: link } = await (isManagerInvitation ? getManager<string>(path) : getEmployee<string>(path));

    if (link) {
      copyToClipboard(link);
    }
  }, [copyToClipboard, getEmployee, getManager, invitationId, isManagerInvitation]);

  const refresh = useCallback(async () => {
    if (isAfterInterval) {
      const fetcher: ApiFetcherOptions = { clearOptions: { omit: ['/interval$'] } };
      if (isManagerInvitation) {
        fetcher.clear = 'managerInvitation';
        await patchManager(invitationId, { fetcher });
      } else {
        fetcher.clear = 'employeeInvitation';
        await patchEmployee(invitationId, { fetcher });
      }

      setRefreshedAt(new Date().toISOString());
      setIsRefreshed(true);
      setTimeout(() => {
        setIsRefreshed(false);
      }, 2000);
    }
  }, [invitationId, isAfterInterval, isManagerInvitation, patchEmployee, patchManager]);

  return (
    <>
      {isAllowed && <Button disabled={!isAfterInterval} Icon={Send} onClick={refresh} title={refreshTooltipTitle} />}
      <Button Icon={InsertLink} onClick={copyLinkToClipboard} title={linkTooltipTitle} />
    </>
  );
}

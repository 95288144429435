import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from './Button';
import { Dialog, DialogCloseReason, DialogProps } from './Dialog';
import { Multiline } from './Multiline';

export type ConfirmCloseReason = DialogCloseReason | 'cancel' | 'confirm';

export type ConfirmProps = {
  isDisabled?: boolean;
  labels?: { cancelBtn?: string; confirmBtn?: string };
  onClose?: (reason: ConfirmCloseReason) => void;
  text?: string;
} & Omit<DialogProps, 'onClose'>;

export function Confirm({
  children,
  isDisabled,
  labels: propsLabels,
  onClose,
  text: propsText,
  ...dialogProps
}: ConfirmProps) {
  const { t } = useTranslation();

  const labels = useMemo(() => {
    const prefix = dialogProps?.name && `dialogs.${dialogProps.name}`;

    let text = propsText;
    if (!text && prefix) {
      text = t(`${prefix}.text`, { defaultValue: '' });
    }

    return {
      cancelBtn: t([`${prefix}.cancelBtn`, 'dialogs.cancelBtn']),
      confirmBtn: t([`${prefix}.confirmBtn`, 'dialogs.confirmBtn']),
      text,
      ...propsLabels,
    };
  }, [dialogProps?.name, propsLabels, propsText, t]);

  const handleConfirm = useCallback(() => {
    if (!isDisabled) {
      onClose?.('confirm');
    }
  }, [isDisabled, onClose]);

  const handleCancel = useCallback(() => {
    onClose?.('cancel');
  }, [onClose]);

  return (
    <Dialog fullWidth={false} onClose={onClose} {...dialogProps}>
      <DialogContent>
        {labels.text && (
          <DialogContentText>
            <Multiline text={labels.text} />
          </DialogContentText>
        )}
        {children}
      </DialogContent>

      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button onClick={handleCancel} variant="outlined">
          {labels.cancelBtn}
        </Button>
        <Button disabled={isDisabled} onClick={handleConfirm} autoFocus>
          {labels.confirmBtn}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

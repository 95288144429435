import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormResourceType } from '../../../../common/forms';

export function useGetErrorLabel() {
  const { t } = useTranslation();

  return useCallback(
    (formName: string, fieldName: string, key: string, resourceType?: FormResourceType) => {
      return (params?: Record<string, unknown>) =>
        t(
          [
            `forms.${formName}.fields.${fieldName}.errors.${key}`,
            `forms.${resourceType}.fields.${fieldName}.errors.${key}`,
            `forms.${formName}.fields.errors.${key}`,
            `forms.fields.${fieldName}.errors.${key}`,
            `forms.fields.errors.${key}`,
            'foms.fields.errors.invalid',
          ],
          params,
        );
    },
    [t],
  );
}

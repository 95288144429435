import { useMemo } from 'react';

import { Console } from '@sbiz/util-browser';
import { isObjectLitteral } from '@sbiz/util-common';

import { SWROptions } from '..';
import { ApiRecord, ResourceType } from '../resources';
import { ListingParams, ListingResponse } from './useApiList';
import { useApiListItems } from './useApiListItems';

export function useActiveApiListItems<const T extends ResourceType, TData = ApiRecord<T>>(
  resourceType: T,
  options?: SWROptions<ListingResponse<TData>> & { params?: ListingParams },
) {
  const activeFilter = useMemo(() => {
    const filter = { deletedAt: null };

    if (options?.params?.filter) {
      try {
        const optionsFilter = JSON.parse(options.params.filter);
        if (isObjectLitteral(optionsFilter)) {
          Object.assign(filter, optionsFilter);
        }
      } catch (error) {
        Console.error(new Error(`Invalid JSON in filter query param: ${options?.params?.filter}`));
      }
    }

    return JSON.stringify(filter);
  }, [options]);

  return useApiListItems<T, TData>(resourceType, { ...options, params: { ...options?.params, filter: activeFilter } });
}

import { useCallback, useEffect, useRef, useState } from 'react';
import Lightbox from 'react-datatrans-light-box';

import { useApi } from '../../../../common/api/hooks/useApi';
import { useCreditCard } from '../../../../common/api/hooks/useCreditCard';
import { useConfigContext } from '../../../../contexts/ConfigContext';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { Feedback } from '../../../atoms';
import { FeedbackProps } from '../../../atoms/Feedback';
import { CreditCardPanel } from './CreditCardPanel';

const ID_PARAM_NAME = 'datatransTrxId';
const RESULT_PARAM_NAME = 'datatransResult';

export function CreditCard() {
  const [[transactionIdParam, resultParam], setParams] = useQueryParams([ID_PARAM_NAME, RESULT_PARAM_NAME] as const);
  const isDatatransInitSuccess = resultParam?.value === 'success';
  const isDatatransInitError = resultParam?.value !== undefined && !isDatatransInitSuccess;

  const [datatransId, setDatatransId] = useState<string>();
  const [feedbackProps, setFeedbackProps] = useState<Omit<FeedbackProps, 'onClose'>>(() => ({
    name: 'creditCardCreation',
    ...(isDatatransInitError ? { open: true, severity: 'warning' } : { open: false }),
  }));
  const [isRefreshPending, setIsRefreshPending] = useState(isDatatransInitSuccess);

  const isDatatransResultHandled = useRef(!isDatatransInitSuccess);

  const { post } = useApi('datatrans');
  const {
    app: { environment },
  } = useConfigContext();
  const { clear, data: creditCard } = useCreditCard();

  const closeFeedback = useCallback(() => {
    setFeedbackProps((props) => ({ ...props, open: false }));
    setParams({ [ID_PARAM_NAME]: null, [RESULT_PARAM_NAME]: null });
  }, [setFeedbackProps, setParams]);

  const handleCardChange = useCallback(async (transactionId: string) => {
    setDatatransId(transactionId);
  }, []);

  const processResult = useCallback(async () => {
    let isSuccess = false;
    if (transactionIdParam?.value) {
      const { data, error } = await post<{ cardValidationError?: string } | null>('transactions/refresh', {
        data: { transactionId: transactionIdParam.value },
      });

      if (!(error || data?.cardValidationError)) {
        isSuccess = true;
      }
    }

    if (isSuccess) {
      clear();
    }

    setFeedbackProps((props) => ({ ...props, open: true, severity: isSuccess ? 'success' : 'warning' }));
    setIsRefreshPending(false);
  }, [clear, post, transactionIdParam?.value]);

  useEffect(() => {
    if (!isDatatransResultHandled.current) {
      isDatatransResultHandled.current = true;
      processResult();
    }
  }, [processResult]);

  return (
    <>
      <CreditCardPanel creditCard={isRefreshPending ? undefined : creditCard} onCardChange={handleCardChange} />
      {datatransId && <Lightbox production={environment === 'prod'} transactionId={datatransId} />}
      <Feedback onClose={closeFeedback} {...feedbackProps} />
    </>
  );
}

import { Paper, PaperTypeMap } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

import { styledProps } from '../../common/styles';

const STYLES = { width: 'fit-content', p: 8 };

export function Panel<T extends DefaultComponentProps<PaperTypeMap>>(props?: T) {
  return <Paper {...styledProps(STYLES, props)} />;
}

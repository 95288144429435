import { TableColDef, TableDefinition, TableName, TABLES_DEFINITION, TableValidRowModel } from './types';

export const TABLE_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
export const TABLES: Readonly<{ [P in TableName]: TableDefinition }> = TABLES_DEFINITION;

export const FULLNAME_TABLE_COLUMN = {
  field: 'fullname',
  sortFields: ['firstname', 'lastname'],
  valueGetter: (_: unknown, { firstname, lastname }: { firstname: string; lastname: string }) =>
    `${firstname} ${lastname}`,
} satisfies TableColDef<{ firstname: string; lastname: string } & TableValidRowModel>;

import { Switch } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyFee, CompanyJson } from '@sbiz/business';

import { useApi } from '../../../../common/api/hooks/useApi';
import { useCompanyCache } from '../../../../common/api/hooks/useCompanyCache';
import { CommonCompanySettings } from '../../../../common/api/resources/company';
import { PAGES } from '../../../../common/pages';
import { nestedValueGetter, TableColDef } from '../../../../common/tables';
import { Feedback, Span } from '../../../atoms';
import { TableCellLink } from '../../../atoms/TableCellLink';
import { CheckMarkIcon } from '../../../icons';
import { Table } from '../../../organisms';

export function CompaniesTable() {
  const [errorMessageKeys, setErrorMessageKeys] = useState<string[]>();

  const { updateOne } = useApi('companyAdmin');
  const { data: settings } = useCompanyCache<CommonCompanySettings>('settings/common');
  const { t } = useTranslation();

  const errorMessage = useMemo(() => errorMessageKeys && t(errorMessageKeys), [errorMessageKeys, t]);

  const setStatus = useCallback(
    (companyId: string) =>
      async ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        setErrorMessageKeys(undefined);

        const { error } = await updateOne(
          companyId,
          { benefitsActivated: checked },
          { fetcher: { clear: 'companyAdmin' } },
        );

        if (error) {
          setErrorMessageKeys(error.messageKeys);
        }
      },
    [updateOne],
  );

  const actionsCellRenderer = useCallback(
    ({ row: { _id: companyId, benefitsActivated } }: GridRenderCellParams<CompanyJson>) => (
      <Switch checked={benefitsActivated} color="success" onChange={setStatus(companyId)} />
    ),
    [setStatus],
  );

  const closeFeedback = useCallback(() => {
    setErrorMessageKeys(undefined);
  }, []);

  const contactCellRenderer = useCallback(
    ({ formattedValue, value }: GridRenderCellParams<CompanyJson>) => (
      <TableCellLink href={`mailto:${value}`}>{formattedValue}</TableCellLink>
    ),
    [],
  );

  const feesCellRenderer = useCallback(
    ({ value: fee }: { value?: CompanyFee }) => {
      const percentage = fee?.percentage ?? settings?.fees?.service.percentage;

      return (
        <>
          <CheckMarkIcon isChecked={Boolean(fee?.isCharged)} />
          {fee?.isCharged && percentage && <Span sx={{ ml: 1 }}>{percentage.toFixed(2)}%</Span>}
        </>
      );
    },
    [settings?.fees?.service.percentage],
  );

  const nameCellRenderer = useCallback(
    ({ formattedValue, row: company }: GridRenderCellParams<CompanyJson>) => (
      <TableCellLink href={`${PAGES.company.path}?companyId=${company._id}`}>{formattedValue}</TableCellLink>
    ),
    [],
  );

  const columns = useMemo(
    (): TableColDef<CompanyJson>[] => [
      { field: 'name', renderCell: nameCellRenderer },
      { field: 'externalReference' },
      { field: 'contactEmail', renderCell: contactCellRenderer },
      {
        field: 'fees.service',
        renderCell: feesCellRenderer,
        sortFields: ['fees.service.isCharged', 'fees.service.percentage'],
        valueGetter: nestedValueGetter,
      },
      { field: 'createdAt', valueType: 'date' },
      { field: 'updatedAt', valueType: 'date' },
      { field: 'actions', renderCell: actionsCellRenderer, sortable: false },
    ],
    [actionsCellRenderer, contactCellRenderer, feesCellRenderer, nameCellRenderer],
  );

  return (
    <>
      <Table name="companiesAdmin" columns={columns} headerProps={{ isDeletionAuthorized: false }} />

      <Feedback
        name="companyStatusChange"
        onClose={closeFeedback}
        open={Boolean(errorMessage)}
        severity="error"
        labels={{ error: errorMessage }}
      />
    </>
  );
}

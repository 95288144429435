import { Box, BoxProps } from '@mui/material';
import { BoxTypeMap } from '@mui/system';
import { ElementType, ForwardedRef, forwardRef, JSX, useMemo } from 'react';

import { getSxArray } from '../../common/styles';

export type FlexBoxProps<TComponent extends ElementType = 'div'> = {
  column?: boolean;
  component?: TComponent;
  ref?: ForwardedRef<HTMLElement>;
} & BoxProps<TComponent>;

export const FlexBox = forwardRef(FlexBoxComponent) as <TComponent extends ElementType = 'div'>(
  props: FlexBoxProps<TComponent>,
) => JSX.Element;

function FlexBoxComponent<TComponent extends ElementType = BoxTypeMap['defaultComponent']>(
  { column, sx, ...props }: Omit<FlexBoxProps<TComponent>, 'ref'>,
  ref: ForwardedRef<TComponent>,
) {
  const boxProps = useMemo(
    () => ({
      ...props,
      sx: [Boolean(column) && { flexDirection: 'column' }, ...getSxArray(sx), { display: 'flex' }],
    }),
    [column, props, sx],
  );

  return <Box ref={ref} {...boxProps} />;
}

import { Divider, List, ListItem, ListItemProps } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { Order } from '@sbiz/business';
import { toCentimes } from '@sbiz/util-common';

import { FlexBox, Money, Span } from '../../../atoms';

export const TOTAL_ROWS = [
  'fromAccount',
  'fromCheckAccount',
  'serviceFee',
  'totalOrder',
  'totalPaid',
  'totalProducts',
  'totalShipping',
] as const;
export type TotalRow = (typeof TOTAL_ROWS)[number];

export type TotalsProps = { labels: Record<TotalRow | 'discounts' | 'freeDelivery' | 'tip', string>; order: Order };

export function Totals({ labels, order }: TotalsProps) {
  const {
    benefits,
    totalOrder: totalExcludingTipCHF,
    totalOrderWithTips: totalOrderCHF,
    totalPaid: totalPaidCHF,
    totalProducts,
  } = order;

  const totalOrder = useMemo(
    () => ({ label: labels.totalOrder, value: toCentimes(totalOrderCHF) }),
    [labels.totalOrder, totalOrderCHF],
  );

  const totalPaid = useMemo(
    () => ({ label: labels.totalPaid, value: toCentimes(totalPaidCHF) }),
    [labels.totalPaid, totalPaidCHF],
  );

  const getTotalRows = useCallback(
    (props: TotalRow[]) =>
      props.filter((prop) => order[prop] > 0).map((prop) => ({ label: labels[prop], value: toCentimes(order[prop]) })),
    [labels, order],
  );

  const fees = useMemo(() => {
    const fees = getTotalRows(['totalShipping', 'serviceFee']);

    const tip = totalOrder.value - toCentimes(totalExcludingTipCHF);
    if (tip > 0) {
      fees.push({ label: labels.tip, value: tip });
    }

    return fees;
  }, [getTotalRows, labels.tip, totalExcludingTipCHF, totalOrder.value]);

  const deductions = useMemo(() => {
    const deductions = getTotalRows(['fromAccount', 'fromCheckAccount']);

    const totalBenefits = benefits.reduce((sum, { amount }) => sum + amount, 0);
    const totalDeductions = deductions.reduce((sum, { value }) => sum + value, 0);

    const totalDiscounts = totalOrder.value - totalBenefits - totalDeductions - totalPaid.value;

    if (totalDiscounts > 0) {
      deductions.push({ label: labels.discounts, value: totalDiscounts });
    }

    return deductions;
  }, [benefits, getTotalRows, labels.discounts, totalOrder.value, totalPaid.value]);

  return (
    <FlexBox>
      <List dense sx={{ ml: 'auto' }}>
        <TotalLabel isStrong>{labels.totalProducts}</TotalLabel>

        {fees.map(({ label }, index) => (
          <TotalLabel key={index}>{label}</TotalLabel>
        ))}

        <TotalDivider />

        <TotalLabel isStrong>{totalOrder.label}</TotalLabel>

        <TotalDivider />

        {benefits.map(({ code, isFreeDelivery }, index) => (
          <TotalLabel key={index}>
            <Span isStrong sx={{ color: 'primary.main' }}>
              {code}
            </Span>
            {isFreeDelivery && <Span sx={{ ml: 1 }}>({labels.freeDelivery})</Span>}
          </TotalLabel>
        ))}

        {deductions.map(({ label }, index) => (
          <TotalLabel key={index}>{label}</TotalLabel>
        ))}

        <TotalLabel isStrong>{totalPaid.label}</TotalLabel>
      </List>

      <List dense>
        <Total isStrong value={toCentimes(totalProducts)} />

        {fees.map(({ value }, index) => (
          <Total key={index} value={value} />
        ))}

        <TotalDivider />

        <Total isStrong value={totalOrder.value} />

        <TotalDivider />

        {benefits.map(({ amount }, index) => (
          <Total key={index} isStrong value={-amount} sx={{ color: 'primary.main' }} />
        ))}

        {deductions.map(({ value }, index) => (
          <Total key={index} value={-value} />
        ))}

        <Total isStrong value={totalPaid.value} />
      </List>
    </FlexBox>
  );
}

function Total({ isStrong, value, ...listItemProps }: ListItemProps & { isStrong?: boolean; value: number }) {
  return (
    <ListItem {...listItemProps}>
      <Money isStrong={isStrong} value={value} />
    </ListItem>
  );
}

function TotalDivider() {
  return <Divider sx={{ my: 0.5 }} />;
}

function TotalLabel({ children, isStrong, ...listItemProps }: ListItemProps & { isStrong?: boolean }) {
  return (
    <ListItem {...listItemProps}>
      <Span isStrong={isStrong} sx={{ ml: 'auto', textAlign: 'right' }}>
        {children}
      </Span>
    </ListItem>
  );
}

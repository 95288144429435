import { useCallback } from 'react';
import useSWR from 'swr';

import { ApiError } from '@sbiz/util-browser';

import { getFullPath, SWROptions } from '..';
import { ApiRecord, ResourceType } from '../resources';
import { useApi } from './useApi';
import { useClearCache } from './useClearCache';

export function useApiCache<const T extends ResourceType, TData = ApiRecord<T>>(
  resourceType: T,
  path: string | undefined,
  options?: SWROptions<TData>,
) {
  const { basePath, get } = useApi(resourceType);
  const { isPublic, params, ...swrOptions } = options ?? {};

  const fullPath = getFullPath(basePath, path);
  const cacheKey =
    typeof path === 'string' ? `${fullPath}${options?.params ? `:${JSON.stringify(options.params)}` : ''}` : null;

  const clearCache = useClearCache();

  const fetcher = useCallback(async () => {
    const { data, error } = await get<TData>(path ?? '', { isPublic, params });

    if (data) {
      return data;
    }

    throw error;
  }, [get, isPublic, params, path]);

  const clear = useCallback(
    (clearPath?: string) => {
      clearCache(resourceType, { path: clearPath });
    },
    [clearCache, resourceType],
  );

  const { data, error, isLoading, isValidating, mutate } = useSWR<TData, ApiError>(cacheKey, fetcher, {
    revalidateOnFocus: false,
    ...swrOptions,
  });

  return { clear, data: error ? null : data, error, isLoading: isLoading || isValidating, mutate };
}

import { Autocomplete, AutocompleteInputChangeReason, AutocompleteRenderInputParams } from '@mui/material';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuid } from 'uuid';

import { Place } from '@sbiz/common';

import { useApi } from '../../common/api/hooks/useApi';
import { useLang } from '../../hooks/useLang';
import { TextField, TextFieldProps } from '../atoms/TextField';

export type Address = string | Place;

export type AddressAutocompleteProps = {
  defaultPlace?: Place;
  defaultValue?: string;
  freeSolo?: boolean;
  onChange?: (address: Address) => void;
  sessionToken?: string;
  textFieldProps?: TextFieldProps;
};

export function AddressAutocomplete({
  defaultPlace,
  defaultValue,
  freeSolo,
  onChange,
  sessionToken: propsSessionToken,
  textFieldProps,
}: AddressAutocompleteProps) {
  const { t } = useTranslation();

  const [addresses, setAddresses] = useState<Place[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [place, setPlace] = useState(defaultPlace);
  const [search, setSearch] = useState(defaultValue ?? defaultPlace?.formatted ?? '');

  const { get } = useApi('addresses');
  const [lang] = useLang();

  const sessionToken = useMemo(() => propsSessionToken ?? uuid(), [propsSessionToken]);

  const error = useMemo(() => {
    if (isDirty) {
      if (textFieldProps?.required) {
        if (!search) {
          return t(`forms.fields.errors.empty`);
        }
      }

      if (!freeSolo && !place) {
        return t(`forms.fields.address.errors.invalid`);
      }
    }
  }, [freeSolo, isDirty, place, search, t, textFieldProps?.required]);

  const handleBlur = useCallback(() => {
    setIsDirty(true);
  }, []);

  const handleChange = useCallback(
    async (_: SyntheticEvent, address: Address | null) => {
      let place: Place | undefined = undefined;
      let search = '';

      if (address) {
        if (typeof address === 'string') {
          search = address;
        } else {
          place = address;
          search = address.formatted;
        }
      }

      setPlace(place);
      setSearch(search);
      onChange?.(place ?? search);
    },
    [onChange],
  );

  const setOptions = useCallback(
    async (search: string) => {
      if (search?.length >= 5) {
        const { data: addresses = [] } = await get<Place[]>('places', { params: { lang, search, sessionToken } });
        setAddresses(addresses);
      }
    },
    [get, lang, sessionToken],
  );

  const debouncedSetOptions = useDebouncedCallback(setOptions, 500);

  const handleInputChange = useCallback(
    (_: SyntheticEvent, search: string, reason: AutocompleteInputChangeReason) => {
      if (['clear', 'input'].includes(reason)) {
        setSearch(search);
        setPlace(undefined);
        onChange?.(search);
        debouncedSetOptions(search);
      }
    },
    [debouncedSetOptions, onChange],
  );

  const renderInput = useCallback(
    (props: AutocompleteRenderInputParams) => (
      <TextField
        error={Boolean(error)}
        helperText={error ?? ' '}
        label={t('resources.propertyNames.address')}
        name="address"
        onBlur={handleBlur}
        {...{ ...props, ...textFieldProps }}
      />
    ),
    [error, handleBlur, t, textFieldProps],
  );

  return (
    <Autocomplete
      filterOptions={(addresses) => addresses}
      freeSolo={Boolean(freeSolo)}
      getOptionLabel={(address) => (typeof address === 'string' ? address : address.formatted)}
      inputValue={search}
      noOptionsText={t('forms.fields.address.info.noOptions')}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={addresses}
      renderInput={renderInput}
    />
  );
}

import { useCallback } from 'react';

import { CsvDelimiter } from '../../../../../common/api';
import { useApi } from '../../../../../common/api/hooks/useApi';
import { FormDialogOverrideProps } from '../../../../molecules/FormDialog';
import { FileDialog } from '../../../../organisms/FileDialog';
import { CostCentersFileSelectionForm } from './CostCentersFileSelectionForm';
import { CostCentersFileUploadForm } from './CostCentersFileUploadForm';

const SAMPLE_FIELDS = ['costCenters'];

export function CostCentersFileDialog(props: FormDialogOverrideProps) {
  const { previewCsvFile } = useApi('company');

  const previewFetcher = useCallback(
    (file: File, delimiter?: CsvDelimiter) => previewCsvFile('cost-centers/file/preview', file, delimiter),
    [previewCsvFile],
  );

  return (
    <FileDialog
      fields={SAMPLE_FIELDS}
      namePrefix="costCenters"
      previewFetcher={previewFetcher}
      resourceType="company"
      SelectionForm={CostCentersFileSelectionForm}
      UploadForm={CostCentersFileUploadForm}
      {...props}
    />
  );
}

import { DialogTitle, List, ListItem, ListSubheader } from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toMoney } from '@sbiz/util-common';

import { CheckDistribution } from '../../../../common/api/resources/check';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { Dialog, Link } from '../../../atoms';

export function DatatransTransactionCheckLink({
  children,
  datatransId,
  distribution: {
    check: { amount, dayOfDistribution, name },
    createdAt,
    employees,
  },
}: {
  children: ReactNode;
  datatransId: string;
  distribution: CheckDistribution;
}) {
  const formatDate = useFormatDate();
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      amount: t('resources.propertyNames.amount'),
      day: t('resources.check.propertyNames.dayOfDistribution'),
      distribution: t('dialogs.checkDistribution.subHeader', { date: formatDate(createdAt) }),
      employeeCount: t('resources.propertyNames.profile_activeEmployeeCount'),
      creditedEmployeeCount: t('dialogs.checkDistribution.creditedEmployeeCount'),
      name: t('resources.propertyNames.name'),
    }),
    [createdAt, formatDate, t],
  );

  const [isOpen, setIsOpen] = useState(false);

  const creditedEmployeeCount = useMemo(() => employees.filter(({ isSuccess }) => isSuccess).length, [employees]);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Link onClick={handleClick}>{children}</Link>

      <Dialog fullWidth={false} open={isOpen} onClose={handleClose}>
        <DialogTitle>ID: {datatransId}</DialogTitle>
        <List subheader={<ListSubheader>{labels.distribution}</ListSubheader>}>
          <ListItem>
            {labels.name}: {name}
          </ListItem>
          <ListItem>
            {labels.day}: {dayOfDistribution}
          </ListItem>
          <ListItem>
            {labels.amount}: {toMoney(amount)}
          </ListItem>
          <ListItem>
            {labels.employeeCount}: {employees.length}
          </ListItem>
          <ListItem>
            {labels.creditedEmployeeCount}: {creditedEmployeeCount}
          </ListItem>
        </List>
      </Dialog>
    </>
  );
}

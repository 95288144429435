import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Place, PlaceDetails } from '@sbiz/common';
import { ApiError } from '@sbiz/util-browser';

import { useApi } from '../common/api/hooks/useApi';
import { AddressAutocomplete } from '../components/molecules';
import { Address, AddressAutocompleteProps } from '../components/molecules/AddressAutocomplete';
import { useLang } from './useLang';

export function useAddressAutocomplete(options?: {
  defaultPlace?: Place;
  defaultValue?: string;
  sessionToken?: string;
}) {
  const { defaultPlace, defaultValue } = options ?? {};

  const [place, setPlace] = useState(defaultPlace);
  const [search, setSearch] = useState(defaultValue ?? defaultPlace?.formatted ?? '');

  const { get } = useApi('addresses');
  const [lang] = useLang();

  const sessionToken = useMemo(() => options?.sessionToken ?? uuid(), [options?.sessionToken]);

  const getPlaceDetails = useCallback(() => {
    if (place?.id) {
      return get<PlaceDetails>(`places/${place.id}`, {
        params: { lang, provider: place.provider, sessionToken },
      });
    }

    return { data: undefined, error: new ApiError('Place is missing', { status: 400 }) };
  }, [get, lang, place?.id, place?.provider, sessionToken]);

  const Autocomplete = useCallback(
    (props: Omit<AddressAutocompleteProps, 'defaultPlace' | 'defaultValue'>) => (
      <AddressAutocomplete
        {...props}
        defaultPlace={defaultPlace}
        defaultValue={defaultValue}
        onChange={(address: Address) => {
          if (typeof address === 'string') {
            setPlace(undefined);
            setSearch(address);
          } else {
            setPlace(address);
            setSearch(address.formatted);
          }

          props?.onChange?.(address);
        }}
      />
    ),
    [defaultPlace, defaultValue],
  );

  return { Autocomplete, getPlaceDetails, place, search, sessionToken };
}

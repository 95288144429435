import { ReactNode, useCallback } from 'react';
import { createRoot } from 'react-dom/client';

import { Console, downloadPdf } from '@sbiz/util-browser';

import { useApi } from '../common/api/hooks/useApi';

export function useConvertToPdf() {
  const { post } = useApi('files');

  return useCallback(
    async (node: ReactNode, filename: string) => {
      const { html, css } = await getHtml(node);

      const result = await post<string>('pdf/convert/html', { data: { css, html } });

      if (!result.error) {
        downloadPdf(result.data, filename);
      }

      return result;
    },
    [post],
  );
}

function getHtml(node: ReactNode) {
  return new Promise<{ css: string; html: string }>((resolve) => {
    const container = document.createElement('div');
    const root = createRoot(container);
    root.render(
      <div
        ref={() => {
          const html = container?.firstElementChild?.innerHTML ?? '';
          resolve({ css: html && getStyles(getClassNames(container)), html });
        }}
      >
        {node}
      </div>,
    );
  });
}

function getClassNames(element: Element) {
  const classNames: string[] = [];

  for (const child of [...element.children]) {
    if (child.className) {
      classNames.push(...child.className.split(' '));
    }

    for (const className of getClassNames(child)) {
      classNames.push(className);
    }
  }

  return classNames;
}

function getStyles(classNames: string[]) {
  const cssRules: Set<string> = new Set();

  for (const styleSheet of document.styleSheets) {
    try {
      for (const cssRule of styleSheet.cssRules) {
        if (cssRule instanceof CSSStyleRule) {
          const { cssText, selectorText } = cssRule;

          if (['body', 'html'].includes(selectorText)) {
            cssRules.add(cssText);
          } else {
            for (const className of classNames) {
              if (selectorText.includes(`.${className}`)) {
                cssRules.add(cssText);
              }
            }
          }
        }
      }
    } catch {
      Console.debug(`Could not access styleSheet '${styleSheet.href}'`);
    }
  }

  return [...cssRules].join('\n');
}

import { Chip, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { Order } from '@sbiz/business';

import { FlexBox, Html, Link, Span } from '../../../atoms';

export type OrderDetailsTitleProps = {
  isExport?: boolean;
  labels: Record<'at' | 'download' | 'status' | 'title', string>;
  onExport?: (number: string) => Promise<void>;
  order: Order;
  storeUrl?: string;
};

export function OrderDetailsTitle({ isExport, labels, onExport, order, storeUrl }: OrderDetailsTitleProps) {
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = useCallback(async () => {
    setIsExporting(true);
    await onExport?.(order.number);
    setIsExporting(false);
  }, [onExport, order.number]);

  return (
    <>
      <Typography component={FlexBox} variant="h5" sx={{ alignItems: 'center' }}>
        <Span sx={{ fontWeight: 'bold' }}>
          <Html component="span" content={labels.title} />
        </Span>

        {!isExport && (
          <Chip color={order.status === 'complete' ? 'success' : 'warning'} label={labels.status} sx={{ ml: 3 }} />
        )}
      </Typography>

      <FlexBox sx={{ alignItems: 'baseline', gap: 1, mt: 1 }}>
        <Span>{labels.at} </Span>
        {storeUrl ? (
          <Link href={storeUrl} target="_blank" rel="noreferrer">
            {order.restaurantName}
          </Link>
        ) : (
          <Span>{order.restaurantName}</Span>
        )}

        {!isExport && (
          <Link isLoading={isExporting} onClick={handleExport} sx={{ fontSize: '0.83em', ml: 'auto' }}>
            {labels.download}
          </Link>
        )}
      </FlexBox>
    </>
  );
}

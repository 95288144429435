import { useCallback } from 'react';

import { CompanyJson } from '@sbiz/business';

import { SWROptions } from '../common/api';
import useApiResource from '../common/api/hooks/useApiResource';
import { useCompanyId } from './useCompanyId';

export function useCompany(options?: SWROptions<CompanyJson>) {
  const companyId = useCompanyId();

  const isPaused = useCallback(() => !companyId || Boolean(options?.isPaused?.()), [companyId, options]);
  return useApiResource('company', 'context', { ...options, isPaused });
}

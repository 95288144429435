import { useCallback } from 'react';

import { useConfigContext } from '../contexts/ConfigContext';
import { useLang } from './useLang';

export function useGetSmoodUrl() {
  const {
    smood: { url },
  } = useConfigContext();
  const [lang] = useLang();

  return useCallback((path: string) => `${url}/${lang}/${path}`, [lang, url]);
}

import { Add, UploadFileOutlined } from '@mui/icons-material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClearCache } from '../../../../common/api/hooks/useClearCache';
import { RestrictedButton } from '../../../atoms';
import { FormDialog, FormDialogCloseReason } from '../../../molecules/FormDialog';
import { CostCenterForm } from './CostCenterForm';
import { CostCentersFileDialog } from './CostCentersFileDialog';

export function CostCentersActions({ isReplacement }: { isReplacement: boolean }) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      addBtn: t('pages.company_costCenters.addBtn'),
      addOneBtn: t('pages.company_costCenters.addOneBtn'),
      replaceBtn: t('pages.company_costCenters.replaceBtn'),
    }),
    [t],
  );

  const [isAddition, setIsAddition] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  const clearCache = useClearCache();

  const closeDialog = useCallback(
    (reason: FormDialogCloseReason) => {
      if (reason === 'formSubmission') {
        clearCache('company', { path: 'context' });
        clearCache('costCenter');
      }
    },
    [clearCache],
  );

  const closeUploadDialog = useCallback(
    (reason: FormDialogCloseReason) => {
      setIsUpload(false);
      closeDialog(reason);
    },
    [closeDialog],
  );

  const closeAdditionDialog = useCallback(
    (reason: FormDialogCloseReason) => {
      setIsAddition(false);
      closeDialog(reason);
    },
    [closeDialog],
  );

  const openAdditionDialog = useCallback(() => {
    setIsAddition(true);
  }, []);

  const openUploadDialog = useCallback(() => {
    setIsUpload(true);
  }, []);

  return (
    <>
      <RestrictedButton onClick={openAdditionDialog} scope="companyCostCenter" startIcon={<Add />}>
        {labels.addOneBtn}
      </RestrictedButton>

      <RestrictedButton onClick={openUploadDialog} scope="companyCostCenter" startIcon={<UploadFileOutlined />}>
        {isReplacement ? labels.replaceBtn : labels.addBtn}
      </RestrictedButton>

      <CostCentersFileDialog onClose={closeUploadDialog} open={isUpload} />

      <FormDialog Form={CostCenterForm} name="costCenterCreation" open={isAddition} onClose={closeAdditionDialog} />
    </>
  );
}

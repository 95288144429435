import { SWROptions } from '..';
import { ApiRecord, ResourceType } from '../resources';
import { ListingParams, ListingResponse, useApiList } from './useApiList';

export function useApiListItems<const T extends ResourceType, TData = ApiRecord<T>>(
  resourceType: T,
  options?: SWROptions<ListingResponse<TData>> & { params?: ListingParams },
) {
  const { data, ...rest } = useApiList<T, TData>(resourceType, options);
  return { ...rest, data: data && data.data };
}

import { GridColDef } from '@mui/x-data-grid';

import { TABLES } from './constants';
import { TableName, TableValidRowModel } from './types';

export function getTableLabelKeys(tableName: TableName, labelName: string) {
  const keys = [`tables.${tableName}.labels.${labelName}`];

  if (tableName.endsWith('Admin')) {
    keys.push(`tables.${tableName.replace(/Admin$/, '')}.labels.${labelName}`);
  }

  keys.push(`tables.labels.${labelName}`);

  return keys;
}

export function getHeaderNameKeys(tableName: TableName, fieldName: string) {
  const { resourceType } = TABLES[tableName];

  const fieldKey = fieldName.replaceAll('.', '_');

  const headerKey = `headerNames.${fieldKey}`;
  const keys = [`tables.${tableName}.${headerKey}`];
  if (tableName.endsWith('Admin')) {
    keys.push(`tables.${tableName.replace(/Admin$/, '')}.${headerKey}`);
  }

  const propertyKey = `propertyNames.${fieldKey}`;
  keys.push(`tables.${headerKey}`, `resources.${resourceType}.${propertyKey}`);
  if (resourceType.endsWith('Admin')) {
    keys.push(`resources.${resourceType.replace(/Admin$/, '')}.${propertyKey}`);
  }

  keys.push(`resources.${propertyKey}`);

  return keys;
}

export function nestedValueGetter<T extends TableValidRowModel>(_: unknown, row: T, { field }: GridColDef<T>) {
  const [key, ...nestedKeys] = field.split('.');

  let value = row[key];
  while (nestedKeys.length) {
    const key = nestedKeys.shift();

    if (value && typeof value === 'object' && typeof key === 'string' && key in value) {
      value = value[key];
    } else {
      return null;
    }
  }

  return value;
}

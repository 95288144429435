import { useMemo } from 'react';

import { getCompanyId } from '../common/session';
import { useAuthUserContext } from '../contexts/AuthUserContext';

export function useCompanyId() {
  const { authUser } = useAuthUserContext();

  return useMemo(() => getCompanyId(authUser), [authUser]);
}

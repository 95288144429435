import { useCallback, useMemo } from 'react';

import { ApiError } from '@sbiz/util-browser';

import { ApiFetcherOptions } from '../../../../common/api';
import { useApi } from '../../../../common/api/hooks/useApi';
import { FormParts } from '../../../../common/forms';
import { useFormErrorAlertProp } from '../../../../hooks/useFormErrorAlertProp';
import { DialogFormProps } from '../../../molecules/FormDialog';
import { Form } from '../../../organisms/Form';

type CostCenterFormData = { name: string };

export function CostCenterForm({ costCenter, onSubmit, ...props }: { costCenter?: string } & DialogFormProps<string>) {
  const isUpdate = costCenter !== undefined;

  const { patch, post } = useApi('company');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();

  const defaultValues = useMemo(() => {
    if (isUpdate) {
      return { name: costCenter };
    }
  }, [costCenter, isUpdate]);

  const parts = useMemo((): FormParts<CostCenterFormData> => [{ fieldName: 'name', props: { required: true } }], []);

  const handleError = useCallback(
    ({ costCenter, error }: { costCenter?: string; error?: ApiError }) => {
      setErrorAlert(error);

      if (!error) {
        onSubmit(costCenter);
      }
    },
    [onSubmit, setErrorAlert],
  );

  const handleSubmit = useCallback(
    async ({ name }: CostCenterFormData) => {
      const fetcher: ApiFetcherOptions = { clear: 'company', clearOptions: { path: 'cost-centers' } };

      if (isUpdate) {
        if (name !== costCenter) {
          const { error } = await patch('cost-centers', { data: { costCenter, replacement: name }, fetcher });
          handleError({ costCenter: name, error });
        }
      } else {
        const { error } = await post('cost-centers', { data: { costCenter: name }, fetcher });
        handleError({ costCenter: name, error });
      }
    },
    [costCenter, handleError, isUpdate, patch, post],
  );

  return <Form alert={errorAlert} defaultValues={defaultValues} onSubmit={handleSubmit} parts={parts} {...props} />;
}

import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PermissionsScope } from '@sbiz/business';

import { useIsEditor } from '../../hooks/useIsEditor';
import { Button, ButtonProps } from './Button';
import { InfoTooltip } from './InfoTooltip';

export const RestrictedButton = forwardRef<HTMLButtonElement, { scope: PermissionsScope } & ButtonProps>(
  function RestrictedButton({ disabled, scope, ...buttonProps }, ref) {
    const { t } = useTranslation();

    const labels = useMemo(() => ({ tooltip: t('tooltips.buttons.unauthorized') }), [t]);

    const isAuthorized = useIsEditor(scope);

    const isDisabled = useMemo(() => {
      if (isAuthorized) {
        return disabled;
      }

      return true;
    }, [disabled, isAuthorized]);

    return (
      <>
        <Button disabled={isDisabled} ref={ref} {...buttonProps} />
        {!isAuthorized && <InfoTooltip title={labels.tooltip} />}
      </>
    );
  },
);

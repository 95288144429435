import { CircularProgress } from '@mui/material';
import { useMemo, useState } from 'react';

import { CreditCard } from '../../../../common/api/resources/company/types';
import { SxObject } from '../../../../common/styles';
import { Panel } from '../../../atoms';
import { CreditCardDisplay } from './CreditCardDisplay';
import { CreditCardForm } from './CreditCardForm';

export function CreditCardPanel({
  creditCard,
  onCardChange,
}: {
  creditCard?: CreditCard | null;
  onCardChange: (transactionId: string) => void;
}) {
  const isAddition = creditCard === null;
  const isLoading = creditCard === undefined;

  const [isReplacement, setIsReplacement] = useState(false);
  const isFormDisplay = !isLoading && (isAddition || isReplacement);

  const content = useMemo(() => {
    if (isLoading) {
      return <CircularProgress size={80} sx={{ m: 8 }} />;
    }

    if (isFormDisplay) {
      const footerLink = isReplacement
        ? {
            props: {
              href: '#',
              onClick: () => {
                setIsReplacement(false);
              },
            },
          }
        : undefined;

      return <CreditCardForm footerLink={footerLink} onChange={onCardChange} />;
    }

    return (
      <CreditCardDisplay
        creditCard={creditCard}
        onCardReplacement={() => {
          setIsReplacement(true);
        }}
      />
    );
  }, [creditCard, isFormDisplay, isLoading, onCardChange, isReplacement]);

  const styles = useMemo(
    (): SxObject => ({
      mx: 'auto',
      ...(isFormDisplay && { p: 2, minWidth: ({ breakpoints }) => breakpoints.values.sm }),
    }),
    [isFormDisplay],
  );

  return <Panel sx={styles}>{content}</Panel>;
}

import { Delete } from '@mui/icons-material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getTableLabelKeys, TableName } from '../../../common/tables';
import { Button, InfoTooltip } from '../../atoms';
import { ButtonProps } from '../../atoms/Button';

type RowDeletionButtonProps = {
  count: number;
  isIconButton?: boolean;
  tableName: TableName;
  tooltipText?: string;
} & ButtonProps;

export function RowDeletionButton({
  confirmProps,
  count,
  isIconButton,
  tableName,
  tooltipText,
  ...props
}: RowDeletionButtonProps) {
  const { t } = useTranslation();

  const getLabelKeys = useCallback(
    (labelName: string) => getTableLabelKeys(tableName, `rowDeletion.${labelName}`),
    [tableName],
  );

  const labels = useMemo(
    () => ({
      cancelBtn: t([...getLabelKeys('cancelBtn'), 'buttons.cancel']),
      confirmBtn: t([...getLabelKeys('confirmBtn'), 'buttons.confirm']),
      deleteBtn: t([...getLabelKeys('deleteBtn'), 'buttons.delete']),
      text: t(getLabelKeys('dialogText'), {
        count,
        entries: t(getTableLabelKeys(tableName, count > 1 ? 'entries' : 'entry')),
      }),
      title: t(getLabelKeys('dialogTitle')),
    }),
    [count, getLabelKeys, t, tableName],
  );

  const buttonProps = useMemo(
    () => ({
      confirmProps: {
        labels: { cancelBtn: labels.cancelBtn, confirmBtn: labels.confirmBtn },
        text: labels.text,
        ...confirmProps,
      },
      ...props,
    }),
    [confirmProps, labels.cancelBtn, labels.confirmBtn, labels.text, props],
  );

  return (
    <>
      {isIconButton ? (
        <Button Icon={Delete} title={labels.deleteBtn} {...buttonProps} />
      ) : (
        <Button size="small" startIcon={<Delete />} {...buttonProps}>
          {labels.deleteBtn}
        </Button>
      )}

      {tooltipText && <InfoTooltip title={tooltipText} />}
    </>
  );
}

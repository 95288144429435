import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyLocation } from '@sbiz/business';

import { useApi } from '../../../../common/api/hooks/useApi';
import { DeleteButton } from '../../../atoms';
import { ConfirmCloseReason } from '../../../atoms/Confirm';

export function LocationDeletionButton({
  index,
  locations,
  onDeletion,
}: {
  index: number;
  locations?: CompanyLocation[];
  onDeletion: () => void;
}) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      deletionConfirmation: t('tables.labels.rowDeletion.dialogText', {
        count: 1,
        entries: t('tables.locations.labels.entry'),
      }),
    }),
    [t],
  );

  const { updateOne } = useApi('company');

  const handleDeletion = useCallback(
    (index: number) => async (reason: ConfirmCloseReason) => {
      if (reason === 'confirm') {
        if (locations?.[index]) {
          const dropOffAreas = [...locations];
          dropOffAreas.splice(index, 1);
          await updateOne('', { dropOffAreas });
          onDeletion();
        }
      }
    },
    [locations, onDeletion, updateOne],
  );

  const confirmProps = useMemo(
    () => ({ onClose: handleDeletion(index), text: labels.deletionConfirmation }),
    [handleDeletion, index, labels.deletionConfirmation],
  );

  return <DeleteButton confirmProps={confirmProps} scope="companyLocation" />;
}

import { DialogTitle, List, ListItem, ListSubheader, capitalize } from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toMoney } from '@sbiz/util-common';

import { GiftCard } from '../../../../common/api/resources/gift-card';
import { Dialog, Link } from '../../../atoms';

export function DatatransTransactionGiftCardLink({
  children,
  datatransId,
  giftCard: { amount, nameForClient, quantity, status },
}: {
  children: ReactNode;
  datatransId: string;
  giftCard: GiftCard;
}) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      amount: t('resources.propertyNames.amount'),
      giftCard: capitalize(t('tables.giftCards.labels.entry')),
      name: t('resources.propertyNames.name'),
      quantity: t('resources.propertyNames.quantity'),
      status: t('resources.propertyNames.status'),
    }),
    [t],
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Link onClick={handleClick}>{children}</Link>

      <Dialog fullWidth={false} onClose={handleClose} open={isOpen}>
        <DialogTitle>ID: {datatransId}</DialogTitle>
        <List
          subheader={
            <ListSubheader>
              {labels.giftCard} {`'${nameForClient}'`}
            </ListSubheader>
          }
        >
          <ListItem>
            {labels.amount}: {toMoney(amount)}
          </ListItem>
          <ListItem>
            {labels.quantity}: {quantity}
          </ListItem>
          <ListItem>
            {labels.status}: {status}
          </ListItem>
        </List>
      </Dialog>
    </>
  );
}

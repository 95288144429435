import { InfoOutlined } from '@mui/icons-material';
import { SvgIconProps, Tooltip, TooltipProps } from '@mui/material';

import { styledProps } from '../../common/styles';

export function InfoTooltip({ iconProps, ...props }: Omit<TooltipProps, 'children'> & { iconProps?: SvgIconProps }) {
  return (
    <Tooltip
      arrow
      placement="left"
      slotProps={{
        arrow: { sx: { color: 'info.main' } },
        tooltip: { sx: { backgroundColor: 'info.main', fontSize: '0.9em', maxWidth: 240, padding: 1.5 } },
      }}
      {...props}
    >
      <InfoOutlined fontSize="small" color="info" {...styledProps({ ml: 0.5 }, iconProps)} />
    </Tooltip>
  );
}

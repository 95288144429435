import { SxProps, Theme } from '@mui/material';

import { SxArray } from './types';

declare module '@mui/material/styles' {
  interface TypeBackground {
    primary?: string;
  }
}

export function getSxArray(sx?: SxProps<Theme>): SxArray {
  if (sx) {
    return Array.isArray(sx) ? sx : [sx];
  }

  return [];
}

export function styledProps<T extends { sx?: SxProps<Theme> }>(styles: SxProps<Theme>, allProps?: T) {
  const props = { ...allProps } as T;
  props.sx = [...getSxArray(styles), ...getSxArray(allProps?.sx)];
  return props;
}

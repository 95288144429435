import { useMemo } from 'react';

import { toMoney } from '@sbiz/util-common';

import { TableColValueFormatter, TableColValueType } from '../common/tables';
import { useFormatDate } from './useFormatDate';

export function useValueFormatters() {
  const formatDate = useFormatDate();

  return useMemo(
    () =>
      ({
        date: (value: Date | string) => (value ? formatDate(value) : '-'),
        money: (value: string | number) => toMoney(value),
      }) as { [P in TableColValueType]: TableColValueFormatter },
    [formatDate],
  );
}

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_PERMISSIONS_NAME } from '@sbiz/business';
import { uncapitalize } from '@sbiz/util-strings';

import { Manager } from '../../../../common/api/resources/manager';
import { PAGES } from '../../../../common/pages';
import { FULLNAME_TABLE_COLUMN, TableColDef } from '../../../../common/tables';
import { useIsEditor } from '../../../../hooks/useIsEditor';
import { Link } from '../../../atoms';
import { TableCellLink } from '../../../atoms/TableCellLink';
import { Table } from '../../../organisms';
import { PermissionsSettingDialog } from './PermissionsSettingDialog';

export function ManagersAdminTable() {
  const { t } = useTranslation();

  const [editedManager, setEditedManager] = useState<Manager>();

  const isSetEditor = useIsEditor('permissionsSet');

  const columns = useMemo(
    (): TableColDef<Manager>[] => [
      {
        ...FULLNAME_TABLE_COLUMN,
        renderCell: ({ formattedValue, row: manager }) => (
          <TableCellLink
            onClick={() => {
              setEditedManager(manager);
            }}
          >
            {formattedValue}
          </TableCellLink>
        ),
      },
      { field: 'email' },
      {
        field: 'permissions.name',
        valueFormatter: (value) => t(`admin.permissions.${uncapitalize(value as string)}`),
        valueGetter: (_, manager) => manager.permissions?.name ?? DEFAULT_PERMISSIONS_NAME,
        ...(isSetEditor && {
          renderCell: ({ formattedValue, value }) => (
            <Link href={`${PAGES.permissionsSets.path}?name=${value}`}>{formattedValue}</Link>
          ),
        }),
      },
    ],
    [isSetEditor, t],
  );

  const closePermissionsDialog = useCallback(() => {
    setEditedManager(undefined);
  }, []);

  return (
    <>
      <Table columns={columns} name="managersAdmin" />
      <PermissionsSettingDialog manager={editedManager} onClose={closePermissionsDialog} />
    </>
  );
}

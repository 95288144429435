import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useApi } from '../../../../common/api/hooks/useApi';
import { Statement } from '../../../../common/api/resources/statement';
import { PAGES } from '../../../../common/pages';
import { nestedValueGetter, TableColDef } from '../../../../common/tables';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { useTableConfig } from '../../../../hooks/useTableConfig';
import { TextField } from '../../../atoms';
import { Feedback, FeedbackProps } from '../../../atoms/Feedback';
import { TableCellLink } from '../../../atoms/TableCellLink';
import { TableHeaderProps } from '../../../molecules/TableHeader';
import { Table } from '../../../organisms';
import { StatementActionButtons } from '../../common/statements/StatementActionButtons';
import { StatementPaymentDialog } from '../../common/statements/StatementPaymentDialog';

const TABLE_NAME = 'statementsAdmin';

export function StatementsAdminTable() {
  const [feedbackProps, setFeedbackProps] = useState<Omit<FeedbackProps, 'onClose'>>({
    name: 'statementAdminPayment',
    open: false,
  });
  const [paidStatement, setPaidStatement] = useState<Statement>();

  const { patch } = useApi('statementAdmin');
  const formatDate = useFormatDate();
  const [tableConfig, setTableConfigQueryParam] = useTableConfig(TABLE_NAME);
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      noFilter: t(`tables.${TABLE_NAME}.labels.noFilter`),
      paidAt: (date?: string) =>
        t(`resources.statement.propertyValues.isPaid.${date ? 'true' : 'false'}`, { date: formatDate(date) }),
      paidFilter: t(`tables.${TABLE_NAME}.labels.paidFilter`),
      paymentStatus: t(`tables.${TABLE_NAME}.labels.paymentStatus`),
      unpaidFilter: t('resources.statement.propertyValues.isPaid.false'),
    }),
    [formatDate, t],
  );

  const closeFeedback = useCallback(() => {
    setFeedbackProps((props) => ({ ...props, open: false }));
  }, []);

  const closePaymentDialog = useCallback(() => {
    setPaidStatement(undefined);
  }, []);

  const handlePaymentButtonClick = useCallback(
    (statement: Statement) => async () => {
      if (statement.paidAt) {
        const { error } = await patch(`${statement._id}/payment`, {
          data: { action: 'unpay' },
          fetcher: { clear: ['statement', 'statementAdmin'] },
        });

        if (error) {
          setFeedbackProps((props) => ({ ...props, labels: { error: error.message }, open: true, severity: 'error' }));
        }
      } else {
        setPaidStatement(statement);
      }
    },
    [patch],
  );

  const columns = useMemo(
    (): TableColDef<Statement>[] => [
      { field: 'invoiceRef', sortable: false },
      { field: 'createdAt', valueType: 'date' },
      { field: 'dueAt', valueType: 'date' },
      {
        field: 'company.name',
        renderCell: ({ formattedValue, row }) => (
          <TableCellLink href={`${PAGES.company.path}?companyId=${row.company.id}`}>{formattedValue}</TableCellLink>
        ),
        sortable: false,
        valueGetter: nestedValueGetter,
      },
      { field: 'totalNet', valueType: 'money' },
      {
        field: 'paidAt',
        valueFormatter: (value) => labels.paidAt(value as string | undefined),
      },
      {
        field: 'actions',
        renderCell: ({ row: statement }) => (
          <StatementActionButtons
            isAdmin
            onPaymentButtonClick={handlePaymentButtonClick(statement)}
            statement={statement}
          />
        ),
        sortable: false,
      },
    ],
    [handlePaymentButtonClick, labels],
  );

  const paymentStatus = useMemo(() => tableConfig.extra?.paymentStatus ?? '', [tableConfig.extra?.paymentStatus]);

  const filter = useMemo(() => {
    if (paymentStatus === 'paid') {
      return { paidAt: { $ne: null } };
    }

    if (paymentStatus === 'unpaid') {
      return { paidAt: null };
    }
  }, [paymentStatus]);

  const updatePaymentStatus = useCallback(
    (paymentStatus: string) => {
      const config = { ...tableConfig };

      if (paymentStatus) {
        config.extra = { paymentStatus };
      } else {
        delete config.extra;
      }

      setTableConfigQueryParam(JSON.stringify(config));
    },
    [setTableConfigQueryParam, tableConfig],
  );

  const headerProps = useMemo(
    (): Pick<TableHeaderProps, 'actions'> => ({
      actions: {
        extraFilter: (
          <TextField
            label={labels.paymentStatus}
            onChange={({ target: { value } }) => {
              updatePaymentStatus(value);
            }}
            options={[
              { label: labels.noFilter, value: '' },
              { label: labels.paidFilter, value: 'paid' },
              { label: labels.unpaidFilter, value: 'unpaid' },
            ]}
            select
            size="small"
            sx={{ minWidth: 200 }}
            value={paymentStatus}
          />
        ),
      },
    }),
    [labels.noFilter, labels.paidFilter, labels.paymentStatus, labels.unpaidFilter, paymentStatus, updatePaymentStatus],
  );

  return (
    <>
      <Table columns={columns} filter={filter} headerProps={headerProps} name={TABLE_NAME} />
      <StatementPaymentDialog isAdmin statement={paidStatement} onClose={closePaymentDialog} />
      <Feedback onClose={closeFeedback} {...feedbackProps} />
    </>
  );
}

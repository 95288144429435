import { DatePicker as MuiDatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { getLocale } from '@sbiz/util-dates';

import { useLang } from '../../hooks/useLang';

export function DatePicker(props: DatePickerProps<Date>) {
  const [lang] = useLang();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(lang)}>
      <MuiDatePicker {...props} />
    </LocalizationProvider>
  );
}

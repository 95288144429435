import { SvgIconProps } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getWindow } from '@sbiz/util-browser';

import { useApi } from '../../../../common/api/hooks/useApi';
import { Button } from '../../../atoms';
import { CsvIcon, PdfIcon } from '../../../icons';

export function StatementDownloadButton({
  isAdmin,
  statementId,
  type,
}: {
  isAdmin?: boolean;
  statementId: string;
  type: 'csv' | 'pdf';
}) {
  const { t } = useTranslation();

  const labels = useMemo(() => ({ title: t(`buttons.download_${type}`) }), [t, type]);

  const [isLoading, setIsLoading] = useState(false);

  const { get } = useApi('statement');
  const { get: adminGet } = useApi('statementAdmin');

  const downloadStatement = useCallback(
    (statementId: string) => async () => {
      setIsLoading(true);

      const path = `${statementId}/${type}`;
      const { data: fileUrl } = await (isAdmin ? adminGet<string>(path) : get<string>(path));

      setIsLoading(false);

      if (fileUrl) {
        getWindow()?.open(fileUrl, '_blank');
      }
    },
    [adminGet, get, isAdmin, type],
  );

  const Icon = useCallback(
    (props: SvgIconProps) => (type === 'pdf' ? <PdfIcon {...props} /> : <CsvIcon {...props} />),
    [type],
  );

  return <Button Icon={Icon} isLoading={isLoading} onClick={downloadStatement(statementId)} title={labels.title} />;
}

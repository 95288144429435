import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getDateInFuture } from '@sbiz/util-dates';

import { useFormatDate } from './useFormatDate';

export function useGetOfferedMonthsLabel() {
  const formatDate = useFormatDate();
  const { t } = useTranslation();

  return useCallback(
    (offeredMonths: boolean[], currentSelection?: Set<number>, prefix?: string) => {
      const selection =
        currentSelection ??
        new Set(offeredMonths.map((isSelected, index) => (isSelected ? index : -1)).filter((index) => index > -1));

      if (!selection?.size) {
        return t(getKeys('none', prefix));
      }

      const currentMonthIndex = 0;
      const [startIndex, ...monthIndexes] = Array.from(selection).sort((a, b) => a - b);

      if (startIndex === currentMonthIndex) {
        let endIndex = currentMonthIndex;

        for (const index of monthIndexes) {
          if (index !== endIndex + 1) {
            break;
          }
          endIndex = index;
        }

        if (endIndex === currentMonthIndex) {
          return t(getKeys('currentMonth', prefix));
        }

        const endDate = getDateInFuture(endIndex, 'months');
        return t(getKeys('until', prefix), { month: formatDate(endDate, 'MMMM') });
      }

      const startDate = getDateInFuture(startIndex, 'months');
      return t(getKeys('from', prefix), { month: formatDate(startDate, 'MMMM') });
    },
    [formatDate, t],
  );
}

function getKeys(suffix: string, prefix?: string) {
  const keys = [`common.feeOffer_${suffix}`];

  if (prefix) {
    keys.unshift(`${prefix}.feeOffer_${suffix}`);
  }

  return keys;
}

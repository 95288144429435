import { Box, BoxProps, Card, CardActions, CardContent, CardHeader, Fade, Typography } from '@mui/material';
import { forwardRef, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { styledProps } from '../../../common/styles';
import { useTourContext } from '../../../contexts/TourContext';
import { Button, CloseButton, FlexBox, Multiline, Span } from '../../atoms';

export const TourStep = forwardRef<HTMLDivElement, { arrow: ReactNode; id?: string; isVisible?: boolean } & BoxProps>(
  function TourStep({ arrow, id, isVisible, ...props }, ref) {
    const { closeTour, isFullTour, nextTourStep, previousTourStep, stepCount, stepIndex } = useTourContext();

    const { t } = useTranslation();

    const labels = useMemo(() => {
      const prefix = `tourSteps.${id}`;

      return {
        body: t(`${prefix}.body`),
        close: t(['tourSteps.labels.closeBtn', 'buttons.ok']),
        end: t('tourSteps.labels.endBtn'),
        next: t('tourSteps.labels.nextBtn'),
        previous: t('tourSteps.labels.previousBtn'),
        stepIndex: t('tourSteps.labels.stepCount', { stepIndex, stepCount }),
        title: t(`${prefix}.title`),
      };
    }, [id, stepCount, stepIndex, t]);

    const isAfterLastStep = useMemo(() => stepIndex > stepCount, [stepCount, stepIndex]);
    const isClosingFullTour = useMemo(
      () => isFullTour && stepIndex === stepCount + 1,
      [isFullTour, stepCount, stepIndex],
    );
    const isLastStep = useMemo(() => stepIndex === stepCount, [stepCount, stepIndex]);

    const handleClose = useCallback(() => {
      if (isAfterLastStep) {
        nextTourStep();
      } else {
        closeTour();
      }
    }, [closeTour, isAfterLastStep, nextTourStep]);

    const action = useMemo(() => <CloseButton onClick={handleClose} />, [handleClose]);
    const boxProps = useMemo(() => styledProps({ zIndex: ({ zIndex }) => zIndex.modal }, props), [props]);

    return (
      <Box ref={ref} {...boxProps}>
        <Fade in={isVisible}>
          <Box sx={{ maxWidth: 640 }}>
            {arrow}

            <Card sx={{ backgroundColor: 'white' }} elevation={0}>
              <CardHeader action={action} sx={{ pb: 0, px: 3 }} title={labels.title} />

              <CardContent sx={{ px: 3 }}>
                <Typography color="text.secondary">
                  <Multiline text={labels.body} />
                </Typography>
              </CardContent>

              <CardActions disableSpacing sx={{ pb: 2, px: 3 }}>
                {!isClosingFullTour && <Span>{labels.stepIndex}</Span>}

                <FlexBox sx={{ gap: 1, ml: 'auto' }}>
                  {isClosingFullTour ? (
                    <Button onClick={nextTourStep} autoFocus>
                      {labels.close}
                    </Button>
                  ) : (
                    <>
                      <Button disabled={stepIndex <= 1} onClick={previousTourStep} variant="outlined">
                        {labels.previous}
                      </Button>

                      <Button disabled={isAfterLastStep} onClick={nextTourStep} autoFocus>
                        {isLastStep ? labels.end : labels.next}
                      </Button>
                    </>
                  )}
                </FlexBox>
              </CardActions>
            </Card>
          </Box>
        </Fade>
      </Box>
    );
  },
);

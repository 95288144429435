import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InvitationInterval } from '@sbiz/business';

import { useApiCache } from '../../../common/api/hooks/useApiCache';
import { ManagerInvitation } from '../../../common/api/resources/manager-invitation';
import { FULLNAME_TABLE_COLUMN, TableColDef } from '../../../common/tables';
import { Table } from '../../organisms';
import { InvitationExtraActions } from '../common/invitations/InvitationExtraActions';

const TABLE_NAME = 'managerInvitations';

export function ManagerInvitationsTable() {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({ status: (value: string) => t(`resources.managerInvitation.propertyValues.status.${value}`) }),
    [t],
  );

  const { data: interval } = useApiCache<'managerInvitation', InvitationInterval>('managerInvitation', 'interval');

  const ExtraActions = useCallback(
    ({ row: { _id: invitationId, invitedAt } }: GridRenderCellParams<ManagerInvitation>) => (
      <InvitationExtraActions
        interval={interval}
        invitationId={invitationId}
        invitedAt={invitedAt}
        tableName="managerInvitations"
      />
    ),
    [interval],
  );

  const columns = useMemo(
    (): TableColDef<ManagerInvitation>[] => [
      FULLNAME_TABLE_COLUMN,
      { field: 'email' },
      { field: 'status', valueFormatter: (value) => labels.status(value as string) },
      { field: 'invitedAt', valueType: 'date' },
    ],
    [labels],
  );

  return (
    <Table
      columns={columns}
      ExtraActions={ExtraActions}
      isRowDeletable={({ row: { status } }) => status !== 'accepted'}
      name={TABLE_NAME}
    />
  );
}

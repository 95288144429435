import { Alert, AlertColor, AlertProps, Portal, Snackbar, SnackbarCloseReason, SnackbarProps } from '@mui/material';
import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { styledProps } from '../../common/styles';

const ALERT_CLOSE_REASON = 'alertClose';
type AlertCloseReason = typeof ALERT_CLOSE_REASON;

export type FeedbackCloseReason = SnackbarCloseReason | AlertCloseReason;

export type FeedbackProps = {
  alertProps?: AlertProps;
  labels?: { [k in AlertColor]?: string };
  name: string;
  onClose?: (reason: FeedbackCloseReason) => void;
  severity?: AlertColor;
} & Omit<SnackbarProps, 'onClose'>;

export function Feedback({ alertProps, labels, name, onClose, severity = 'info', ...snackbarProps }: FeedbackProps) {
  const { t } = useTranslation();

  const handleClose = useCallback(
    (_: Event | SyntheticEvent, reason?: SnackbarCloseReason) => {
      onClose?.(reason ?? ALERT_CLOSE_REASON);
    },
    [onClose],
  );

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={5000}
        onClose={handleClose}
        {...snackbarProps}
      >
        <Alert
          elevation={6}
          onClose={handleClose}
          severity={severity ?? 'info'}
          variant="filled"
          {...styledProps({ minWidth: '420px' }, alertProps)}
        >
          {labels?.[severity] ?? t(`feedbacks.${name}.${severity}`)}
        </Alert>
      </Snackbar>
    </Portal>
  );
}

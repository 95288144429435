import { Box } from '@mui/material';
import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Profile } from '../../../../common/api/resources/profile';
import { TextField } from '../../../atoms';

export function ProfileTransferForm({
  onChange,
  profiles,
  value,
}: {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  profiles: Profile[];
  value: string;
}) {
  const { t } = useTranslation();

  const labels = useMemo(() => ({ field: t('forms.profileTransfer.fields.profile.label') }), [t]);

  return (
    <Box sx={{ py: 1 }}>
      <TextField
        fullWidth
        label={labels.field}
        onChange={onChange}
        options={profiles?.map(({ _id, name }) => ({ value: _id, label: name }))}
        select
        value={value}
      />
    </Box>
  );
}

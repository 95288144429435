import { AlertProps } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { JSX, FormEvent } from 'react';

import {
  FieldName,
  FieldProps,
  Fields,
  FieldValueType,
  FormAlertSeverity,
  FormFieldErrorLabels,
  FormPart,
  FormParts,
} from './types';

export function getFormAlertProp(
  severity: FormAlertSeverity,
  props?: { label?: string } & Omit<AlertProps, 'severity'>,
) {
  const { label, ...alertProps } = props ?? {};
  return { label, props: { ...alertProps, severity } };
}

export function getError<TFormData, TField extends FieldProps<TFormData, FieldName<TFormData>>>(
  field: TField,
  value: TFormData[TField['fieldName']],
  formData: TFormData,
  labels: FormFieldErrorLabels,
) {
  const fieldError = field.getError?.(value, formData);

  if (typeof fieldError === 'string') {
    return fieldError;
  }

  if (value) {
    const invalidError = getInvalidError(value, field, labels);

    if (invalidError) {
      return invalidError;
    }
  } else if (field.props?.required) {
    return labels.empty();
  }
}

function getInvalidError<TFormData, TField extends FieldProps<TFormData, FieldName<TFormData>>>(
  value: TFormData[TField['fieldName']],
  field: FieldProps<TFormData, FieldName<TFormData>>,
  labels: FormFieldErrorLabels,
) {
  if (field.props?.multiple) {
    try {
      const valueArray = value;

      if (!Array.isArray(valueArray)) {
        throw new Error();
      }

      if (field.props?.required && !valueArray.length) {
        return labels.empty();
      }
    } catch {
      return labels.invalid();
    }
  } else if (typeof value === 'string') {
    switch (field.props?.type) {
      case 'email':
        if (!isValidEmail(value)) {
          return labels.invalidEmail();
        }
        break;
      case 'amount':
      case 'number': {
        const numberValue = parseFloat(value);
        if (isNaN(numberValue)) {
          return labels.invalidNumber();
        }

        const { min, max } = field.props?.inputProps ?? {};

        if (min && numberValue < min) {
          return labels.minNumber({ min });
        }

        if (max && numberValue > max) {
          return labels.maxNumber({ max });
        }
        break;
      }
      case 'tel':
        if (!isValidPhoneNumber(value)) {
          return labels.invalidPhoneNumber();
        }
        break;
      default:
        return '';
    }
  }

  return '';
}

function isValidEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
}

export function getFields<TFormData>(formParts: FormParts<TFormData>, result?: Fields<TFormData>) {
  const fields = result ?? ({} as Fields<TFormData>);

  for (const part of formParts) {
    if (Array.isArray(part)) {
      getFields(part, fields);
    } else if (isFieldPart(part)) {
      fields[part.fieldName] = part;
    }
  }

  return fields;
}

export function getFormSubmittedData<TFormData>(event: FormEvent<HTMLFormElement>) {
  return Object.fromEntries(new globalThis.FormData(event.currentTarget).entries()) as TFormData;
}

export function getInitialFormData<TFormData>(fields: Fields<TFormData>, defaultValues?: Partial<TFormData>) {
  const fieldNames = Object.keys(fields) as FieldName<TFormData>[];
  return Object.fromEntries(
    fieldNames.map((name) => [name, getDefaultValue(defaultValues, name, getValueType(fields[name]))]),
  ) as TFormData;
}

function getValueType<TFormData>(field: FieldProps<TFormData>) {
  if (field.props?.type === 'switch') {
    return 'boolean';
  }

  if (field.props?.multiple) {
    return 'array';
  }

  return 'string';
}

function getDefaultValue<T extends Record<string | number | symbol, unknown>>(
  object: T | undefined,
  key: keyof T,
  valueType?: FieldValueType,
) {
  if (object && key in object) {
    const defaultValue = object[key];

    if (valueType === 'array') {
      if (Array.isArray(defaultValue)) {
        return defaultValue;
      }
    } else if (valueType === 'boolean') {
      if (typeof defaultValue === 'boolean') {
        return defaultValue;
      }
    } else if (typeof defaultValue === 'string') {
      return defaultValue;
    }
  }

  return getEmptyValue(valueType);
}

function getEmptyValue(valueType?: FieldValueType) {
  if (valueType === 'array') {
    return [];
  }

  if (valueType === 'boolean') {
    return false;
  }

  return '';
}

export function isComponentPart<TFormData, TFieldName extends FieldName<TFormData>>(
  part: FormPart<TFormData, TFieldName>,
): part is () => JSX.Element {
  return typeof part === 'function';
}

export function isFieldPart<TFormData>(
  part: FormPart<TFormData, FieldName<TFormData>>,
): part is FieldProps<TFormData, FieldName<TFormData>> {
  return !(Array.isArray(part) || isComponentPart(part));
}

import { useMemo } from 'react';

import { PermissionsScope } from '@sbiz/business';

import { useAuthUserContext } from '../contexts/AuthUserContext';

export function useAuthorizedItems<T extends { permissionsScope?: PermissionsScope }>(items: Readonly<T[]>) {
  const { authUser } = useAuthUserContext();

  return useMemo(
    () => items.filter(({ permissionsScope }) => !permissionsScope || authUser.permissions[permissionsScope]?.read),
    [authUser?.permissions, items],
  );
}

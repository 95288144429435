import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '..';
import {
  Csv,
  CsvDelimiter,
  CsvFileUploadFormData,
  CsvPreview,
  CsvRow,
  CsvUploadResponse,
  DEFAULT_CSV_DELIMITER,
  PREVIEW_ROW_COUNT,
  SafeApiResponse,
} from '../../../common/api';
import { getFormAlertProp, FieldProps, FormApi, FormParts } from '../../../common/forms';
import { useFormErrorAlertProp } from '../../../hooks/useFormErrorAlertProp';
import { DialogFormProps } from '../../molecules/FormDialog';
import { FileInvalidRows } from './FileInvalidRows';
import { FilePreview } from './FilePreview';

export function FileUploadForm<TFormData extends object>({
  csv,
  defaultValues: propsDefaultValues,
  fileUploader,
  headers,
  onFooterLinkClick,
  onSubmit,
  parts: propsParts,
  previewFetcher,
  ...props
}: {
  csv: Csv;
  defaultValues?: Partial<TFormData>;
  fileUploader: (file: File, formData: CsvFileUploadFormData<TFormData>) => Promise<SafeApiResponse<CsvUploadResponse>>;
  headers: CsvRow;
  onFooterLinkClick: () => void;
  parts?: FormParts<TFormData>;
  previewFetcher: (file: File, delimiter: CsvDelimiter) => Promise<SafeApiResponse<CsvPreview>>;
} & DialogFormProps) {
  const { file, preview: initialPreview } = csv;
  const formName = props.name;

  const { t } = useTranslation();

  const getTranslationsKeys = useCallback(
    (key: string) => [`forms.${formName}.${key}`, `forms.fileUpload.${key}`],
    [formName],
  );

  const labels = useMemo(
    () => ({
      comma: t(getTranslationsKeys('fields.delimiter.values.comma')),
      delimiterError: t(getTranslationsKeys(`errors.invalidDelimiter`)),
      invalidRows: t(getTranslationsKeys('errors.invalidRows')),
      semicolon: t(getTranslationsKeys('fields.delimiter.values.semicolon')),
      validRows: (count: number) => {
        if (count) {
          return t(getTranslationsKeys(`validRows.${count > 1 ? `plural` : 'singular'}`), { count });
        }

        return t(getTranslationsKeys('errors.noValidrows'));
      },
    }),
    [getTranslationsKeys, t],
  );

  const [invalidResult, setInvalidResult] = useState<CsvUploadResponse>();
  const [preview, setPreview] = useState(getFormattedPreview(initialPreview));

  const formApiRef = useRef<FormApi<CsvFileUploadFormData<TFormData>>>(null);
  const isDelimiterError = useRef(false);

  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();

  const defaultValues = useMemo(
    () => ({ delimiter: preview.delimiter, ...propsDefaultValues }) as Partial<CsvFileUploadFormData<TFormData>>,
    [preview.delimiter, propsDefaultValues],
  );

  const footer = useMemo(() => {
    if (invalidResult) {
      return <FileInvalidRows rows={invalidResult.invalidRows} />;
    }
  }, [invalidResult]);

  const isDisabled = useMemo(() => preview.invalidRows?.some((cells) => cells.some(Boolean)), [preview.invalidRows]);

  const resultAlert = useMemo(() => {
    if (invalidResult) {
      const validRowCount = invalidResult.validRows?.length;
      return getFormAlertProp(validRowCount ? 'info' : 'error', {
        label: `${labels.validRows(validRowCount)} ${labels.invalidRows}`,
      });
    }
  }, [invalidResult, labels]);

  const setIsDelimiterError = useCallback((isError: boolean) => {
    isDelimiterError.current = isError;
    formApiRef?.current?.refresh();
    formApiRef?.current?.setDirty('delimiter');
  }, []);

  const handleDelimiterChange = useCallback(
    async (delimiter: CsvDelimiter) => {
      setIsDelimiterError(false);

      const { data: preview, error } = await previewFetcher(file, delimiter);

      if (error) {
        setIsDelimiterError(true);
      } else {
        setPreview(getFormattedPreview(preview));
      }
    },
    [file, previewFetcher, setIsDelimiterError],
  );

  const handleSubmit = useCallback(
    async (formData: CsvFileUploadFormData<TFormData>) => {
      setErrorAlert(undefined);
      setInvalidResult(undefined);

      const { data, error } = await fileUploader(file, formData);

      setErrorAlert(error);

      if (!error) {
        if (data.invalidRows?.length) {
          setInvalidResult(data);
        } else {
          onSubmit?.();
        }
      }
    },
    [file, fileUploader, onSubmit, setErrorAlert],
  );

  const Preview = useCallback(
    () => <FilePreview headers={headers} preview={preview} sx={{ mb: 4 }} />,
    [headers, preview],
  );

  const delimiterPart = useMemo(
    (): FieldProps<CsvFileUploadFormData, 'delimiter'> => ({
      getError: () => {
        if (isDelimiterError.current) {
          return labels.delimiterError;
        }
      },
      fieldName: 'delimiter',
      onChange: handleDelimiterChange,
      props: {
        options: [
          { label: labels.comma, value: DEFAULT_CSV_DELIMITER },
          { label: labels.semicolon, value: ';' },
        ],
        required: true,
        select: true,
        sx: { flexGrow: 0.75 },
      },
    }),
    [handleDelimiterChange, labels.comma, labels.delimiterError, labels.semicolon],
  );

  const parts = useMemo(() => {
    const parts = [propsParts ? [delimiterPart, ...propsParts] : delimiterPart] as FormParts<
      CsvFileUploadFormData<TFormData>
    >;

    if (!invalidResult) {
      parts.push(Preview);
    }

    return parts;
  }, [delimiterPart, invalidResult, Preview, propsParts]);

  const submitBtnProps = useMemo(() => {
    if (isDisabled) {
      return { disabled: true };
    }
  }, [isDisabled]);

  return (
    <Form
      alert={errorAlert ?? resultAlert}
      apiRef={formApiRef}
      defaultValues={defaultValues}
      footer={footer}
      footerLink={{ props: { onClick: onFooterLinkClick } }}
      onSubmit={handleSubmit}
      parts={parts}
      readOnly={Boolean(invalidResult)}
      submitBtnProps={submitBtnProps}
      {...props}
    />
  );
}

function getFormattedPreview(preview: CsvPreview): CsvPreview {
  const { delimiter, rows, invalidRows } = preview;

  if (rows.length > PREVIEW_ROW_COUNT) {
    rows.at(-1)?.fill('...');
    invalidRows.at(-1)?.fill(null);
  }

  return { delimiter, rows, invalidRows };
}

import { DialogContent, DialogTitle, Divider } from '@mui/material';

import { Order } from '@sbiz/business';

import { FlexBox } from '../../../atoms';
import { DeliveryPoints, DeliveryPointsProps } from './DeliveryPoints';
import { Map } from './Map';
import { OrderDetailsTitle, OrderDetailsTitleProps } from './OrderDetailsTitle';
import { Product } from './Product';
import { OrderProductDisplay, ProductProps } from './Product/Product';
import { Totals, TotalsProps } from './Totals';

export function OrderDetailsContent({
  isExport,
  labels,
  onExport,
  order,
  products,
  storeUrl,
}: {
  isExport?: boolean;
  labels: {
    deliveryPoints: DeliveryPointsProps['labels'];
    product: ProductProps['labels'];
    title: OrderDetailsTitleProps['labels'];
    totals: TotalsProps['labels'];
  };
  onExport?: (number: string) => Promise<void>;
  order: Order;
  products?: OrderProductDisplay[];
  storeUrl?: string;
}) {
  return (
    <>
      <DialogTitle>
        <OrderDetailsTitle
          isExport={isExport}
          labels={labels.title}
          onExport={onExport}
          order={order}
          storeUrl={storeUrl}
        />
      </DialogTitle>

      <DialogContent>
        {!isExport && <Map order={order} />}

        <DeliveryPoints labels={labels.deliveryPoints} order={order} sx={{ mt: 2 }} />

        <Divider sx={{ my: 2 }} />

        {products?.length && (
          <FlexBox column sx={{ gap: 1.5, px: 2 }}>
            {products.map((product) => (
              <Product isExport={isExport} key={product.id} labels={labels.product} product={product} />
            ))}
          </FlexBox>
        )}

        <Divider sx={{ my: 2 }} />

        <Totals labels={labels.totals} order={order} />
      </DialogContent>
    </>
  );
}

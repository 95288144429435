import { useCallback } from 'react';

import { DEFAULT_LANG, Translations } from '@sbiz/common';

import { useLang } from './useLang';

export function useTranslate() {
  const [lang] = useLang();

  return useCallback(
    (translations: Translations) =>
      translations[lang] || translations[DEFAULT_LANG] || Object.values(translations).find(Boolean),
    [lang],
  );
}

import { useCallback, useMemo, useState } from 'react';

import { Console } from '@sbiz/util-browser';
import { capitalize } from '@sbiz/util-strings';

import { useClearCache } from '../../../../common/api/hooks/useClearCache';
import { Button, Feedback } from '../../../atoms';
import { ConfirmCloseReason } from '../../../atoms/Confirm';

export function DatatransTransactionAction({
  confirmText,
  isDisabled,
  label,
  name,
  onConfirm,
}: {
  confirmText: string;
  isDisabled: boolean;
  label: string;
  name: 'cancel' | 'refresh';
  onConfirm: () => Promise<void>;
}) {
  const [isError, setIsError] = useState(false);

  const clearCache = useClearCache();

  const refreshListing = useCallback(() => {
    clearCache('datatransAdmin');
    setIsError(false);
  }, [clearCache]);

  const handleConfirmation = useCallback(async () => {
    if (!isDisabled) {
      setIsError(false);
      try {
        await onConfirm();
        refreshListing();
      } catch (error) {
        Console.error(error);
        setIsError(true);
      }
    }
  }, [isDisabled, onConfirm, refreshListing]);

  const confirmProps = useMemo(
    () => ({
      onClose: (reason: ConfirmCloseReason) => {
        if (reason === 'confirm') {
          handleConfirmation();
        }
      },
      text: confirmText,
    }),
    [confirmText, handleConfirmation],
  );

  return (
    <>
      <Button confirmProps={confirmProps} disabled={isDisabled} size="small">
        {label}
      </Button>

      <Feedback
        name={`datatrans${capitalize(name)}Failure`}
        open={isError}
        onClose={refreshListing}
        severity="warning"
      />
    </>
  );
}

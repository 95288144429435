import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DatatransDetails, DatatransTransaction } from '../../../../common/api/resources/datatrans-transaction';
import { nestedValueGetter, TableColDef, TableColValueGetter } from '../../../../common/tables';
import { useIsEditor } from '../../../../hooks/useIsEditor';
import { Table } from '../../../organisms';
import { OrderDetails } from '../../orders/OrderDetails';
import { OrderDetailsLink } from '../../orders/OrdersTable/OrderDetailsLink';
import { DatatatransTransactionCancelBtn } from './DatatatransTransactionCancelBtn';
import { DatatatransTransactionRefreshBtn } from './DatatatransTransactionRefreshBtn';
import { DatatransTransactionCheckLink } from './DatatransTransactionCheckLink';
import { DatatransTransactionGiftCardLink } from './DatatransTransactionGiftCardLink';
import { DatatransTransactionHistoryLink } from './DatatransTransactionHistoryLink';

function creditCardValueGetter(_: string, row: DatatransTransaction) {
  const parts = [row.values.card?.masked, row.values.card?.info?.brand];
  return parts.filter(Boolean).join(' - ');
}

function detailsRenderCell({
  row: { checkDistribution, datatransId, giftCard, details },
  formattedValue,
}: GridRenderCellParams<DatatransTransaction, string>) {
  if (checkDistribution) {
    return (
      <DatatransTransactionCheckLink distribution={checkDistribution} datatransId={datatransId}>
        {formattedValue}
      </DatatransTransactionCheckLink>
    );
  }

  if (giftCard) {
    return (
      <DatatransTransactionGiftCardLink giftCard={giftCard} datatransId={datatransId}>
        {formattedValue}
      </DatatransTransactionGiftCardLink>
    );
  }

  if (details?.orderNumber) {
    return <OrderDetailsLink number={details.orderNumber}>{formattedValue}</OrderDetailsLink>;
  }
}

export function DatatransTransactionsAdminTable() {
  const { t } = useTranslation();

  const isEditor = useIsEditor('adminDatatrans');

  const detailsValueGetter = useCallback(
    (details: DatatransDetails) => {
      const { checkName, giftCardName, orderNumber } = details ?? {};

      if (checkName) {
        return `${t('tables.checks.labels.entry')} '${checkName}'`;
      }

      if (giftCardName) {
        return `${t('tables.giftCards.labels.entry')} '${giftCardName}'`;
      }

      if (orderNumber) {
        return `${t('tables.orders.labels.entry')} '${orderNumber}'`;
      }
    },
    [t],
  );

  const columns = useMemo(() => {
    const tableColumns: TableColDef<DatatransTransaction>[] = [
      { field: 'company.name', sortable: false, valueGetter: (_, { company }) => company.name },
      { field: 'createdAt', valueType: 'date' },
      {
        field: 'details',
        valueGetter: detailsValueGetter as TableColValueGetter<DatatransTransaction>,
        renderCell: detailsRenderCell,
        sortFields: ['details.checkName', 'details.giftCardName', 'details.orderNumber'],
      },
      { field: 'amount', valueType: 'money' },
      { field: 'values.card.masked', valueGetter: creditCardValueGetter as TableColValueGetter<DatatransTransaction> },
      { field: 'datatransId' },
      {
        field: 'values.status',
        valueGetter: nestedValueGetter,
        renderCell: ({ row: { datatransId, values }, value: status }) =>
          values.history && (
            <DatatransTransactionHistoryLink
              datatransId={datatransId}
              history={values.history.sort(({ date: date1 }, { date: date2 }) => date2.localeCompare(date1))}
              status={status}
            />
          ),
      },
    ];

    if (isEditor) {
      tableColumns.push({
        field: 'actions',
        renderCell: ({ row: { datatransId, values } }) => (
          <>
            <Box sx={{ mr: 1 }}>
              <DatatatransTransactionCancelBtn datatransId={datatransId} status={values.status} />
            </Box>
            <DatatatransTransactionRefreshBtn datatransId={datatransId} status={values.status} />
          </>
        ),
        sortable: false,
      });
    }

    return tableColumns;
  }, [detailsValueGetter, isEditor]);

  return (
    <>
      <Table columns={columns} name="datatransTransactionsAdmin" />
      <OrderDetails admin />
    </>
  );
}

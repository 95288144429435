import { Translations } from '@sbiz/common';

export const GIFT_CARD_STATUSES = ['ACTIVE', 'INACTIVE', 'REJECTED'] as const;
export type GiftCardStatus = string & (typeof GIFT_CARD_STATUSES)[number];

type GiftCardVoucher = { id: string; code: string };

export type StatusChange = {
  _id: string;
  author: { _id: string; firstname: string; lastname: string };
  createdAt: string;
  status: GiftCardStatus;
};

export type GiftCard = {
  _id: string;
  amount: number;
  company?: { name: string };
  deletedAt?: string;
  lastStatusChange?: StatusChange;
  name: Translations;
  nameForClient: string;
  prefixVoucher?: string;
  quantity: number;
  status: GiftCardStatus;
  statusChanges?: StatusChange[];
  vouchers?: GiftCardVoucher[];
};

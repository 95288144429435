import { ExpandMore, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormGroup, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS_ACTIONS, PermissionsAction, PermissionsName, PermissionsScope } from '@sbiz/business';
import { ApiError } from '@sbiz/util-browser';

import { useApi } from '../../../../../common/api/hooks/useApi';
import { Permissions, PermissionsValues } from '../../../../../common/api/resources/permissions/types';
import { Button, Checkbox, FlexBox } from '../../../../atoms';
import { PermissionsStepper } from './PermissionsStepper';

export function PermissionsSetting({
  formName,
  isReadOnly,
  managerPermissions,
  onChange,
  onNameError,
  userPermissionsValues,
}: {
  formName: string;
  isReadOnly: boolean;
  managerPermissions: Permissions;
  onChange: (setting: { name: PermissionsName; values: PermissionsValues }) => void;
  onNameError: (error: ApiError) => void;
  userPermissionsValues: PermissionsValues;
}) {
  const [openScopes, setOpenScopes] = useState(new Set<PermissionsScope>());
  const [permissionsName, setPermissionsName] = useState(managerPermissions.name);
  const [setting, setSetting] = useState(getPermissionsSetting(userPermissionsValues, managerPermissions.values));

  const { get } = useApi('permissions');
  const { t } = useTranslation();

  const actionLabels = useMemo(
    () =>
      Object.fromEntries(
        PERMISSIONS_ACTIONS.map((action) => [
          action,
          t(`resources.manager.propertyValues.permissions_action.${action}`),
        ]),
      ) as Record<PermissionsAction, string>,
    [t],
  );

  const allowedActionCount = useMemo(
    () => Object.values(setting).reduce((sum, actions) => sum + Object.values(actions).filter(Boolean).length, 0),
    [setting],
  );

  const scopes = useMemo(() => Object.keys(userPermissionsValues) as PermissionsScope[], [userPermissionsValues]);

  const scopeLabels = useMemo(
    () =>
      Object.fromEntries(
        scopes.map((scope: PermissionsScope) => [
          scope,
          t(`resources.manager.propertyValues.permissions_scope.${scope}`),
        ]),
      ) as Record<PermissionsScope, string>,
    [scopes, t],
  );

  const labels = useMemo(
    () => ({
      action: actionLabels,
      count: t(`forms.${formName}.count`, {
        count: allowedActionCount,
        total: scopes.length * PERMISSIONS_ACTIONS.length,
      }),
      expandAll: (isExpanded: boolean) => t(`forms.${formName}.${isExpanded ? 'collapseAll' : 'expandAll'}`),
      scope: scopeLabels,
    }),
    [actionLabels, allowedActionCount, formName, scopeLabels, scopes, t],
  );

  const isExpanded = useMemo(
    () => Boolean(openScopes.size) && openScopes.size === scopes.length,
    [openScopes.size, scopes.length],
  );

  const expandOrShrink = useCallback(() => {
    setOpenScopes(new Set(isExpanded ? null : scopes));
  }, [isExpanded, scopes]);

  const openOrClose = useCallback(
    (scope: PermissionsScope) => () => {
      setOpenScopes((currentValue) => {
        const openItems = new Set(currentValue);

        if (openItems.has(scope)) {
          openItems.delete(scope);
        } else {
          openItems.add(scope);
        }

        return openItems;
      });
    },
    [],
  );

  const handleActionChange = useCallback(
    (scope: PermissionsScope, action: PermissionsAction) => (checked: boolean) => {
      setSetting((currentValue) => {
        const values = { ...currentValue, [scope]: { ...currentValue[scope], [action]: checked } };
        onChange({ name: permissionsName, values });
        return values;
      });
    },
    [onChange, permissionsName],
  );

  const handleNameChange = useCallback(
    async (name: PermissionsName) => {
      const { data, error } = await get<Permissions>(`sets/${name}`);

      if (data) {
        setPermissionsName(data.name);

        const values = getPermissionsSetting(userPermissionsValues, managerPermissions.values, data.values);
        setSetting(values);

        onChange({ name: data.name, values });
      } else {
        onNameError(error);
      }
    },
    [get, managerPermissions.values, onChange, onNameError, userPermissionsValues],
  );

  return (
    <>
      <PermissionsStepper
        isReadOnly={isReadOnly}
        onClick={(name) => {
          if (name !== permissionsName) {
            handleNameChange(name);
          }
        }}
        permissionsName={permissionsName}
      />

      <FlexBox sx={{ alignItems: 'center', mb: 4 }}>
        <Typography color="primary">{labels.count}</Typography>
        <Button onClick={expandOrShrink} startIcon={isExpanded ? <UnfoldLess /> : <UnfoldMore />} sx={{ ml: 'auto' }}>
          {labels.expandAll(isExpanded)}
        </Button>
      </FlexBox>

      <Box sx={{ maxHeight: 480, mb: 4, overflow: 'auto', p: 0.5, scrollbarWidth: 'thin' }}>
        {scopes.map((scope) => (
          <Accordion expanded={openScopes.has(scope)} key={scope} onChange={openOrClose(scope)}>
            <AccordionSummary expandIcon={<ExpandMore />}>{labels.scope[scope]}</AccordionSummary>
            <AccordionDetails>
              <FormGroup row>
                {PERMISSIONS_ACTIONS.map((action) => (
                  <Checkbox
                    checked={Boolean(setting?.[scope]?.[action])}
                    disabled={isReadOnly || !userPermissionsValues[scope]?.[action]}
                    key={action}
                    label={labels.action[action]}
                    onChange={handleActionChange(scope, action)}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
}

export function getPermissionsSetting(
  userPermissions: PermissionsValues,
  managerPermissions: PermissionsValues,
  overrides?: PermissionsValues,
) {
  const setting: PermissionsValues = {};

  for (const scope of Object.keys(userPermissions) as PermissionsScope[]) {
    setting[scope] = Object.fromEntries(
      PERMISSIONS_ACTIONS.map((action) => {
        const override = userPermissions[scope]?.[action] ? overrides?.[scope]?.[action] : null;
        return [action, override ?? Boolean(managerPermissions[scope]?.[action])];
      }),
    );
  }

  return setting;
}

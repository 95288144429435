import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useApi } from '../../../../common/api/hooks/useApi';
import { DatatransTransactionAction } from './DatatransTransactionAction';

export function DatatatransTransactionRefreshBtn({ datatransId, status }: { datatransId: string; status: string }) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      confirmText: t('tables.datatransTransactions.labels.refreshDialog', { id: datatransId }),
      refresh: t('buttons.refresh'),
    }),
    [datatransId, t],
  );

  const isDisabled = useMemo(() => !['authorized', 'settled'].includes(status), [status]);

  const { post } = useApi('datatransAdmin');

  const refreshTransaction = useCallback(async () => {
    await post(`transactions/${datatransId}/refresh`);
  }, [datatransId, post]);

  return (
    <DatatransTransactionAction
      confirmText={labels.confirmText}
      isDisabled={isDisabled}
      label={labels.refresh}
      onConfirm={refreshTransaction}
      name="refresh"
    />
  );
}

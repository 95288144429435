import { useMemo } from 'react';

import { PlaceDetails } from '@sbiz/common';

import { Link } from './Link';

const MAP_URL = 'https://maps.google.com/maps';

export function AddressLink({
  address: { formatted, latitude, longitude },
}: {
  address: Pick<PlaceDetails, 'formatted' | 'latitude' | 'longitude'>;
}) {
  const href = useMemo(() => {
    const url = new URL(MAP_URL);
    url.searchParams.set('q', String([latitude, longitude]));
    return url.toString();
  }, [latitude, longitude]);

  return (
    <Link href={href} target="_blank" rel="noreferrer">
      {formatted}
    </Link>
  );
}

import { Card, CardContent, CardHeader, CardProps } from '@mui/material';
import { JSX, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { styledProps } from '../../common/styles';
import { Feedback } from '../atoms';

export type CardFormProps = { name: string; onSubmit: () => void };

export function FormCard({
  Form,
  name,
  title,
  ...props
}: {
  Form: ((props: CardFormProps) => JSX.Element) | false | null | undefined;
  name: string;
} & CardProps) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({ title: title ?? t(`dialogs.${name}.title`, { defaultValue: '' }) }),
    [name, t, title],
  );

  const [isSuccess, setIsSuccess] = useState(false);

  const cardProps = useMemo(
    (): CardProps => styledProps({ maxWidth: ({ breakpoints }) => breakpoints.values.sm }, props),
    [props],
  );

  const handleSubmit = useCallback(() => {
    setIsSuccess(true);
  }, []);

  return (
    <>
      <Card {...cardProps}>
        {labels.title && <CardHeader sx={{ pb: 0, px: 3 }} title={labels.title} />}
        <CardContent sx={{ px: 3, '&:last-child': { pb: 2 } }}>
          {Form && <Form name={name} onSubmit={handleSubmit} />}
        </CardContent>
      </Card>

      <Feedback
        name={name}
        open={isSuccess}
        severity="success"
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
}

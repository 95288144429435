import { useCallback, useMemo, useState } from 'react';

import { useCheckTotals } from '../../../../common/api/hooks/useChecksTotals';
import { Check } from '../../../../common/api/resources/check';
import { nestedValueGetter, TableColDef } from '../../../../common/tables';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { FlexBox, Span } from '../../../atoms';
import { TableCellLink } from '../../../atoms/TableCellLink';
import { FormDialog } from '../../../molecules';
import { DialogFormProps } from '../../../molecules/FormDialog';
import { Table } from '../../../organisms';
import { CheckForm } from '../CheckForm';
import { CheckSummary } from './CheckSummary';

export function ChecksTable() {
  const [editedCheck, setEditedCheck] = useState<Check>();

  const formatDate = useFormatDate();
  const { data: totals } = useCheckTotals();

  const columns = useMemo(
    (): TableColDef<Check>[] => [
      {
        field: 'name',
        renderCell: ({ row: check }) =>
          check.deletedAt ? undefined : (
            <TableCellLink
              onClick={() => {
                setEditedCheck(check);
              }}
            >
              {check.name}
            </TableCellLink>
          ),
      },
      {
        field: 'profile.name',
        renderCell: ({ formattedValue, row: { profile } }) => (
          <TableCellLink href={`/profiles/${profile._id}`}>{formattedValue}</TableCellLink>
        ),
        valueGetter: nestedValueGetter,
      },
      { field: 'profile.activeEmployeeCount', valueGetter: nestedValueGetter },
      { field: 'dayOfDistribution' },
      {
        field: 'startDate',
        valueFormatter: (startDate) => (startDate ? formatDate(startDate as string, 'MMMM yyyy') : '-'),
        renderCell: ({ formattedValue }) => <Span sx={{ textTransform: 'capitalize' }}>{formattedValue}</Span>,
      },
      { field: 'amount', valueType: 'money' },
      { field: 'totalAmount', valueType: 'money' },
    ],
    [formatDate],
  );

  const Form = useMemo(
    () => editedCheck && ((props: DialogFormProps) => <CheckForm check={editedCheck} {...props} />),
    [editedCheck],
  );

  const closeFormDialog = useCallback(() => {
    setEditedCheck(undefined);
  }, []);

  return (
    <>
      {totals && (
        <FlexBox sx={{ mb: 2 }}>
          <CheckSummary totals={totals} />
        </FlexBox>
      )}

      <Table columns={columns} name="checks" />
      <FormDialog Form={Form} name="checkUpdate" onClose={closeFormDialog} open={Boolean(editedCheck)} />
    </>
  );
}

import { Alert, Box } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableColDef } from '../../../../common/tables';
import { FlexBox } from '../../../atoms';
import { ButtonConfirmProps } from '../../../atoms/Button';
import { TableCellLink } from '../../../atoms/TableCellLink';
import { FormDialog } from '../../../molecules';
import { DialogFormProps } from '../../../molecules/FormDialog';
import { TableHeaderProps } from '../../../molecules/TableHeader';
import { Table } from '../../../organisms';
import { CostCenterForm } from './CostCenterForm';

export function CostCenters({ onChange }: { onChange: () => void }) {
  const { t } = useTranslation();

  const labels = useMemo(() => ({ description: t('pages.company_costCenters.description') }), [t]);

  const [editedCostCenter, setEditedCostCenter] = useState<string>();

  const closeEditionForm = useCallback(() => {
    setEditedCostCenter(undefined);
  }, []);

  const columns = useMemo(
    (): TableColDef<{ _id: string; name: string }>[] => [
      {
        field: 'name',
        flex: 3,
        renderCell: ({ formattedValue, row }) => (
          <TableCellLink
            onClick={() => {
              setEditedCostCenter(row._id);
            }}
          >
            {formattedValue}
          </TableCellLink>
        ),
      },
    ],
    [],
  );

  const getDeletionConfirmProps = useCallback(
    (): ButtonConfirmProps => ({
      onClose: (reason) => {
        if (reason === 'confirm') {
          onChange();
        }
      },
    }),
    [onChange],
  );

  const headerProps = useMemo(
    (): Pick<TableHeaderProps, 'deletionConfirmProps'> => ({ deletionConfirmProps: getDeletionConfirmProps() }),
    [getDeletionConfirmProps],
  );

  const UpdateForm = useMemo(
    () =>
      editedCostCenter ? (props: DialogFormProps) => <CostCenterForm costCenter={editedCostCenter} {...props} /> : null,
    [editedCostCenter],
  );

  return (
    <>
      <FlexBox>
        <Box sx={{ maxWidth: ({ breakpoints }) => breakpoints.values.md, mx: 'auto', width: '100%' }}>
          <Table
            columns={columns}
            getDeletionConfirmProps={getDeletionConfirmProps}
            headerProps={headerProps}
            name="costCenters"
          />

          <Alert severity="info" sx={{ mt: 2 }}>
            {labels.description}
          </Alert>
        </Box>
      </FlexBox>

      <FormDialog
        Form={UpdateForm}
        name="costCenterUpdate"
        open={Boolean(editedCostCenter)}
        onClose={closeEditionForm}
      />
    </>
  );
}

import { SvgIconComponent } from '@mui/icons-material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PermissionsScope } from '@sbiz/business';

import { Button, ButtonProps } from './Button';
import { RestrictedButton } from './RestrictedButton';

export type IconButtonProps = { Icon: SvgIconComponent; name: string; scope?: PermissionsScope } & Omit<
  ButtonProps,
  'Icon'
>;
export type IconButtonOverrideProps = Omit<IconButtonProps, 'Icon' | 'name'>;

export function IconButton({ name, scope, title, ...props }: IconButtonProps) {
  const { t } = useTranslation();

  const buttonProps = useMemo(
    (): ButtonProps => ({ ...props, title: title ?? t(`buttons.${name}`) }),
    [name, props, t, title],
  );

  return scope ? <RestrictedButton scope={scope} {...buttonProps} /> : <Button {...buttonProps} />;
}

import { useMemo } from 'react';

import { toMoney } from '@sbiz/util-common';

import { styledProps } from '../../common/styles';
import { Span, SpanProps } from './Span';

export function Money({ value, ...spanProps }: SpanProps & { value: string | number }) {
  const [currency, number] = useMemo(() => {
    const moneyValue = toMoney(value);

    const spaceIndex = moneyValue.search(/\s/);
    if (spaceIndex > -1) {
      return [moneyValue.substring(0, spaceIndex), moneyValue.substring(spaceIndex + 1)];
    }

    const minusIndex = moneyValue.indexOf('-');
    return [moneyValue.substring(0, minusIndex), moneyValue.substring(minusIndex)];
  }, [value]);

  const props = useMemo(() => {
    const props: SpanProps = { display: 'inline-flex', gap: 1, justifyContent: 'space-between', width: '100%' };
    return styledProps(props, spanProps);
  }, [spanProps]);

  return (
    <Span {...props}>
      <Span>{currency}</Span>
      <Span>{number}</Span>
    </Span>
  );
}

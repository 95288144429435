import { ReactNode, useCallback } from 'react';

import { useQueryParam } from '../../../../hooks/useQueryParam';
import { Link } from '../../../atoms';
import { ORDER_NUMBER_PARAM } from '../OrderDetails/OrderDetailsContainer';

export function OrderDetailsLink({ children, number }: { children?: ReactNode; number: string }) {
  const [, setParam] = useQueryParam(ORDER_NUMBER_PARAM);

  const openDetails = useCallback(
    (number: string) => () => {
      setParam(number);
    },
    [setParam],
  );

  return <Link onClick={openDetails(number)}>{children ?? number}</Link>;
}

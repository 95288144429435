import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormResourceType } from '../../../../common/forms';

export function useGetFieldLabel() {
  const { t } = useTranslation();

  return useCallback(
    <TResourceType extends FormResourceType = FormResourceType>(
      formName: string,
      fieldName: string,
      resourceType?: TResourceType,
    ) => {
      const fieldKey = fieldName.replaceAll('.', '_');

      const keys = [
        `forms.${formName}.fields.${fieldKey}.label`,
        `forms.${resourceType}.fields.${fieldKey}.label`,
        `forms.fields.${fieldKey}.label`,
      ];

      if (resourceType) {
        keys.push(`resources.${resourceType}.propertyNames.${fieldKey}`);
      }

      keys.push(`resources.propertyNames.${fieldKey}`);

      return t(keys);
    },
    [t],
  );
}

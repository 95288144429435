import { useMemo } from 'react';

import { PermissionsAction, PermissionsScope } from '@sbiz/business';

import { useAuthUserContext } from '../contexts/AuthUserContext';

export function useIsAuthorized(scope: PermissionsScope | undefined, ...actions: PermissionsAction[]) {
  const { authUser } = useAuthUserContext();

  return useMemo(() => {
    if (!scope) {
      return true;
    }

    if (actions.length === 0) {
      actions.push('read');
    }

    const scopePermissions = authUser.permissions?.[scope];
    return Boolean(scopePermissions && actions.every((action) => scopePermissions[action]));
  }, [actions, authUser.permissions, scope]);
}

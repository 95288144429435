import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as dateFns from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateRange, getDateInFuture, getDateInPast, getLocale, MAX_RANGE_MONTH_COUNT } from '@sbiz/util-dates';

import { useLang } from '../../hooks/useLang';

export function TableDateRangePicker({
  dateRange,
  maxMonthCount = MAX_RANGE_MONTH_COUNT,
  onDateRangeChange,
}: {
  dateRange: DateRange;
  maxMonthCount?: number;
  onDateRangeChange?: (range: DateRange) => void;
}) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({ from: t('tables.labels.dateRangePicker.from'), to: t('tables.labels.dateRangePicker.to') }),
    [t],
  );

  const [lang] = useLang();

  const limits = useMemo(
    () => ({
      start: {
        min: dateFns.startOfDay(getDateInPast(maxMonthCount, 'months', dateRange.end)),
        max: dateFns.startOfDay(dateRange.end),
      },
      end: {
        min: dateFns.endOfDay(dateRange.start),
        max: dateFns.endOfDay(getDateInFuture(maxMonthCount, 'months', dateRange.start)),
      },
    }),
    [dateRange.end, dateRange.start, maxMonthCount],
  );

  const handleRangeBoundChange = useCallback(
    (bound: 'start' | 'end') => (date: Date | null) => {
      if (!(date && dateFns.isValid(date))) {
        return;
      }

      const boundDate = bound === 'end' ? dateFns.endOfDay(date) : dateFns.startOfDay(date);

      if (bound === 'start') {
        if (boundDate < limits.start.min || boundDate > limits.start.max) {
          return;
        }
      } else if (boundDate < limits.end.min || boundDate > limits.end.max) {
        return;
      }

      onDateRangeChange?.({ ...dateRange, [bound]: boundDate });
    },
    [dateRange, limits.end.max, limits.end.min, limits.start.max, limits.start.min, onDateRangeChange],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(lang)}>
      <TableDatePicker
        label={labels.from}
        minDate={limits.start.min}
        maxDate={limits.start.max}
        onChange={handleRangeBoundChange('start')}
        value={dateRange.start}
      />
      <TableDatePicker
        label={labels.to}
        minDate={limits.end.min}
        maxDate={limits.end.max}
        onChange={handleRangeBoundChange('end')}
        value={dateRange.end}
      />
    </LocalizationProvider>
  );
}

function TableDatePicker(props: DatePickerProps<Date>) {
  return <DatePicker slotProps={{ textField: { size: 'small' } }} sx={{ maxWidth: 180 }} {...props} />;
}

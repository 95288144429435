import { Box, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { downloadCsv } from '@sbiz/util-browser';

import { Csv, CsvPreview, CsvRow, SafeApiResponse } from '../../common/api';
import { useFormErrorAlertProp } from '../../hooks/useFormErrorAlertProp';
import { Link } from '../atoms';
import { DialogFormProps } from '../molecules/FormDialog';
import { Form } from './Form';

const MAX_FILE_SIZE = 3_000_000;

export function FileSelectionForm({
  headers,
  onSubmit,
  previewFetcher,
  sampleData,
  ...props
}: {
  headers: string[];
  previewFetcher: (file: File) => Promise<SafeApiResponse<CsvPreview>>;
  sampleData: CsvRow[];
} & DialogFormProps<Csv>) {
  const formName = props.name;

  const { t } = useTranslation();

  const getTranslationsKeys = useCallback(
    (name: string) => [`forms.${formName}.${name}`, `forms.fileSelection.${name}`],
    [formName],
  );

  const labels = useMemo(
    () => ({
      dropzone: t(getTranslationsKeys('dropzone')),
      dropzoneHelperText: t(getTranslationsKeys('dropzoneHelperText'), { maxSize: '3 MB' }),
      sample: t(getTranslationsKeys('sample')),
      sampleFilename: t(getTranslationsKeys('sampleFilename')),
    }),
    [getTranslationsKeys, t],
  );

  const [file, setFile] = useState<File>();
  const [isDropzoneError, setIsDropzoneError] = useState(false);

  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();

  const sampleRows = useMemo(() => getSampleRows(sampleData, headers.length), [headers, sampleData]);

  const FileDropzone = useCallback(
    () => (
      <Dropzone
        accept={{ 'text/csv': ['.csv'] }}
        maxFiles={1}
        maxSize={MAX_FILE_SIZE}
        multiple={false}
        onDropAccepted={(files) => {
          setIsDropzoneError(false);
          setFile(files.at(0));
        }}
        onDropRejected={() => {
          setIsDropzoneError(true);
          setFile(undefined);
        }}
      >
        {({ getInputProps, getRootProps }) => (
          <Box
            {...getRootProps()}
            sx={[
              {
                border: '2px dashed',
                borderColor: ({ palette }) => palette.divider,
                borderRadius: 2,
                color: ({ palette }) => palette.text.disabled,
                cursor: 'pointer',
                mb: 2,
                padding: 3,
                textAlign: 'center',
              },
              isDropzoneError && { borderColor: ({ palette }) => palette.error.light },
              Boolean(file?.name) && { borderColor: ({ palette }) => palette.primary.main },
            ]}
          >
            <input {...getInputProps()} />
            <Typography>{labels.dropzone}</Typography>

            {file?.name ? (
              <Typography color="primary.main" sx={{ mt: 2 }}>
                {file.name}
              </Typography>
            ) : (
              <Typography
                sx={[{ mt: 3, fontSize: 'small' }, isDropzoneError && { color: ({ palette }) => palette.error.light }]}
              >
                {labels.dropzoneHelperText}
              </Typography>
            )}
          </Box>
        )}
      </Dropzone>
    ),
    [file?.name, isDropzoneError, labels.dropzone, labels.dropzoneHelperText],
  );

  const SampleFileLink = useCallback(
    () => (
      <Link
        onClick={() => {
          downloadCsv([headers, ...sampleRows].join('\n'), labels.sampleFilename);
        }}
        sx={{ alignSelf: 'flex-start', mb: 3, mt: 1 }}
      >
        {labels.sample}
      </Link>
    ),
    [headers, labels.sample, labels.sampleFilename, sampleRows],
  );

  const parts = useMemo(() => [FileDropzone, SampleFileLink], [FileDropzone, SampleFileLink]);

  const submitBtnProps = useMemo(() => {
    if (!file?.name) {
      return { disabled: true };
    }
  }, [file?.name]);

  const handleSubmit = useCallback(async () => {
    if (file) {
      const { data, error } = await previewFetcher(file);

      setErrorAlert(error);

      if (!error) {
        onSubmit?.({ file, preview: data });
      }
    }
  }, [file, onSubmit, previewFetcher, setErrorAlert]);

  return <Form alert={errorAlert} onSubmit={handleSubmit} parts={parts} submitBtnProps={submitBtnProps} {...props} />;
}

function getSampleRows(rows: CsvRow[], cellCount: number) {
  return rows.map((cells) => {
    if (cells.length >= cellCount) {
      return cells;
    }

    return [...cells, ...Array.from({ length: cellCount - cells.length }, () => '')];
  });
}

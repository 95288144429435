import { toMoney } from '@sbiz/util-common';

import { CheckTotals } from '../resources/check';
import { SWROptions } from '../types';
import { useApiCache } from './useApiCache';

export function useCheckTotals(options?: SWROptions<CheckTotals>) {
  const { clear, data } = useApiCache('check', 'totals', options);

  return {
    clear,
    data: data && { ...data, totalAmount: toMoney(data.totalAmount) },
  };
}

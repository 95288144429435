import { arrow, autoPlacement, offset, shift, Side, useFloating } from '@floating-ui/react-dom';
import { Backdrop, Box, Fade, Portal } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { TourHighlightId } from '../../../common/pages';
import { TourContext, useTourContext } from '../../../contexts/TourContext';
import { useRoute } from '../../../hooks/useRoute';
import { Arrow } from '../../atoms';
import { ArrowDirection } from '../../atoms/Arrow';
import { NAVIGATION_COLLAPSE_DURATION } from '../../templates/Navigation/NavigationCollapse';
import { TourStep } from './TourStep';

const ARROW_DIRECTION: Record<Side, ArrowDirection> = {
  top: 'down',
  right: 'left',
  bottom: 'up',
  left: 'right',
};

const OPPOSITE_SIDE: Record<Side, Side> = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

const ARROW_SIZE = 10;
const SPOT_SPACING = 6;
type SpotSx = ReturnType<typeof getSpotSx>;

export function TourHighlight<TProps>({
  Component,
  ComponentProps,
  id,
  onClick,
}: {
  Component: (props: TProps & { ref: (node: HTMLElement | null) => void }) => ReactNode;
  ComponentProps: TProps;
  id: TourHighlightId;
  onClick?: (context: TourContext) => void;
}) {
  const [spotSx, setSpotSx] = useState<SpotSx>();
  const isStepVisible = Boolean(spotSx);

  const arrowRef = useRef<HTMLDivElement>(null);

  const [{ pathname }] = useRoute();
  const tourContext = useTourContext();
  const { getPathname, step } = tourContext;

  const isOpen = useMemo(() => {
    const stepPathname = step && getPathname(step);
    return step?.highlightId === id && (!stepPathname || stepPathname === pathname);
  }, [id, getPathname, pathname, step]);

  const { elements, floatingStyles, middlewareData, placement, refs, update } = useFloating<HTMLElement>({
    middleware: [
      offset({ mainAxis: SPOT_SPACING + ARROW_SIZE + 4 }),
      shift({ padding: 8 }),
      arrow({ element: arrowRef.current, padding: 6 }),
      autoPlacement(),
    ],
  });

  const stepArrow = useMemo(() => {
    const [side] = placement.split('-') as Side[];
    const arrowDirection = ARROW_DIRECTION[side];

    if (arrowDirection && middlewareData.arrow) {
      const isVerticalArrow = ['down', 'up'].includes(arrowDirection);
      const { x, y } = middlewareData.arrow;

      return (
        <Arrow
          ref={arrowRef}
          direction={arrowDirection}
          size={ARROW_SIZE}
          sx={{
            position: 'absolute',
            [OPPOSITE_SIDE[side]]: -ARROW_SIZE,
            visibility: middlewareData.arrow?.centerOffset ? 'hidden' : 'visible',
            ...(isVerticalArrow ? { left: x } : { top: y }),
          }}
        />
      );
    }
  }, [middlewareData.arrow, placement]);

  const handleClick = useCallback(() => {
    onClick?.(tourContext);
  }, [onClick, tourContext]);

  const updateDisplay = useCallback(() => {
    if (isOpen && elements.reference) {
      setSpotSx(getSpotSx(elements.reference));
      update();
    }
  }, [elements.reference, isOpen, update]);
  const debouncedUpdateDisplay = useDebouncedCallback(updateDisplay, NAVIGATION_COLLAPSE_DURATION);

  const refreshDisplay = useCallback(() => {
    if (isOpen && elements.reference) {
      setSpotSx(undefined);
      debouncedUpdateDisplay();
    }
  }, [debouncedUpdateDisplay, isOpen, elements.reference]);

  useEffect(() => {
    if (isOpen) {
      elements.reference?.scrollIntoView();
    }
  }, [elements.reference, isOpen]);

  useEffect(() => {
    if (isOpen) {
      refreshDisplay();
      addEventListener('resize', refreshDisplay);

      return () => {
        removeEventListener('resize', refreshDisplay);
      };
    }
  }, [isOpen, refreshDisplay]);

  return (
    <>
      <Component {...ComponentProps} ref={refs.setReference} />

      <Portal>
        <Backdrop
          data-tour-step
          open={isOpen}
          sx={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            mixBlendMode: 'hard-light',
            zIndex: ({ zIndex }) => zIndex.modal - 1,
          }}
        >
          <Fade in={isStepVisible}>
            <Box
              onClick={handleClick}
              sx={{
                backgroundColor: 'grey',
                borderRadius: '8px',
                cursor: onClick ? 'pointer' : 'default',
                position: 'absolute',
                transition: 'opacity 400ms',
                ...spotSx,
              }}
            />
          </Fade>
        </Backdrop>

        {isOpen && (
          <TourStep
            arrow={stepArrow}
            id={step?.id}
            isVisible={isStepVisible}
            ref={refs.setFloating}
            sx={floatingStyles}
          />
        )}
      </Portal>
    </>
  );
}

function getSpotSx(element: HTMLElement | null) {
  const rect = element?.getBoundingClientRect();

  if (rect) {
    const extraSize = 2 * SPOT_SPACING;

    const height = rect.height + extraSize;
    const left = rect.left - SPOT_SPACING;
    const top = rect.top - SPOT_SPACING;
    const width = rect.width + extraSize;

    return { height, left, top, width } as const;
  }
}

import { Box, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toMoney } from '@sbiz/util-common';

import { useApi } from '../../../../common/api/hooks/useApi';
import { GiftCard, GiftCardStatus } from '../../../../common/api/resources/gift-card';
import { FormParts } from '../../../../common/forms';
import { useFormErrorAlertProp } from '../../../../hooks/useFormErrorAlertProp';
import { useGiftCardStatusLabels } from '../../../../hooks/useGiftCardStatusLabels';
import { ButtonProps } from '../../../atoms/Button';
import { DialogFormProps, FormDialog, FormDialogOverrideProps } from '../../../molecules/FormDialog';
import { Form } from '../../../organisms';
import { getGiftCardSubmitBtnProps } from '../../gift-cards/GiftCardForm';

type GiftCardStatusFormData = { status: GiftCardStatus };

const ALLOWED_STATUS_CHANGE: Map<GiftCardStatus, GiftCardStatus[]> = new Map([
  ['INACTIVE', ['ACTIVE', 'REJECTED']],
  ['REJECTED', ['ACTIVE']],
]);

export function GiftCardStatusUpdateDialog({ giftCard, ...props }: { giftCard?: GiftCard } & FormDialogOverrideProps) {
  const UpdateForm = useMemo(
    () => giftCard && ((formProps: DialogFormProps) => <GiftCardStatusUpdateForm giftCard={giftCard} {...formProps} />),
    [giftCard],
  );

  return <FormDialog Form={UpdateForm} name="giftCardStatusUpdate" {...props} />;
}

function GiftCardStatusUpdateForm({
  giftCard: { _id: giftCardId, amount, quantity, status: currentStatus },
  onSubmit,
  ...props
}: { giftCard: GiftCard } & DialogFormProps) {
  const formName = props.name;

  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      confirmation: t('forms.giftCardStatusUpdate.confirmation', {
        amount: toMoney(amount),
        quantity,
        total: toMoney(amount * quantity),
      }),
      currentStatus: t(`forms.${formName}.fields.currentStatus.label`),
    }),
    [amount, formName, quantity, t],
  );

  const [newStatus, setNewStatus] = useState<GiftCardStatus>();

  const { patch } = useApi('giftCardAdmin');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();
  const statusLabels = useGiftCardStatusLabels();

  const statusOptions = useMemo(
    () =>
      ALLOWED_STATUS_CHANGE.get(currentStatus)?.map((status) => ({
        label: statusLabels[status]?.toUpperCase(),
        value: status,
      })) ?? [],
    [currentStatus, statusLabels],
  );

  const CurrentStatus = useCallback(
    () => (
      <Box sx={{ mb: 3 }}>
        <Typography color="text.secondary" sx={{ fontSize: '0.92em' }}>
          {labels.currentStatus}
        </Typography>
        <Typography color="primary" sx={{ fontSize: '1.1em', textTransform: 'uppercase' }}>
          {statusLabels[currentStatus] ?? currentStatus}
        </Typography>
      </Box>
    ),
    [currentStatus, labels.currentStatus, statusLabels],
  );

  const parts = useMemo(
    (): FormParts<GiftCardStatusFormData> => [
      CurrentStatus,
      { fieldName: 'status', onChange: setNewStatus, props: { required: true, options: statusOptions, select: true } },
    ],
    [CurrentStatus, statusOptions],
  );

  const submitBtnProps = useMemo((): ButtonProps | undefined => {
    if (newStatus === 'ACTIVE') {
      return getGiftCardSubmitBtnProps(labels.confirmation);
    }
  }, [labels.confirmation, newStatus]);

  const handleSubmit = useCallback(
    async ({ status }: GiftCardStatusFormData) => {
      const { error } = await patch(`${giftCardId}/status`, { data: { status }, fetcher: { clear: 'giftCardAdmin' } });

      setErrorAlert(error);

      if (!error) {
        onSubmit();
      }
    },
    [giftCardId, onSubmit, patch, setErrorAlert],
  );

  return <Form alert={errorAlert} onSubmit={handleSubmit} parts={parts} submitBtnProps={submitBtnProps} {...props} />;
}

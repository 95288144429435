import { Box, DialogTitle, List, ListItem, ListSubheader } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toMoney } from '@sbiz/util-common';

import { DatatransHistory, DatatransStatus } from '../../../../common/api/resources/datatrans-transaction';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { Dialog, Link } from '../../../atoms';
import { CheckMarkIcon } from '../../../icons';

export function DatatransTransactionHistoryLink({
  datatransId,
  history,
  status,
}: {
  datatransId: string;
  history: DatatransHistory;
  status: DatatransStatus;
}) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      history: t('dialogs.datatransHistory.subHeader'),
    }),
    [t],
  );

  const [isOpen, setIsOpen] = useState(false);

  const formatDate = useFormatDate();

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Link onClick={handleClick}>{status}</Link>

      <Dialog fullWidth={false} open={isOpen} onClose={handleClose}>
        <DialogTitle>ID: {datatransId}</DialogTitle>

        <List subheader={<ListSubheader>{labels.history}</ListSubheader>}>
          {history?.map(({ action, amount, autoSettle, date, success }) => (
            <ListItem key={action}>
              <Box sx={{ mr: 1 }}>
                <CheckMarkIcon isChecked={success} />
              </Box>
              {formatDate(date, 'PPp')}
              {amount ? ` - ${toMoney(amount)}` : ''} - {action}
              {autoSettle ? ' (autoSettle)' : ''}
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
}

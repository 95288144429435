import { ArrowBack } from '@mui/icons-material';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';

import { RoundButton } from './RoundButton';

export function BackButton() {
  const router = useRouter();

  const handleClick = useCallback(() => {
    router.back();
  }, [router]);

  return (
    <RoundButton
      onClick={handleClick}
      size={36}
      sx={{
        color: 'text.secondary',
        backgroundColor: 'common.white',
        '&:hover': { backgroundColor: ({ palette: { background } }) => background.primary },
      }}
    >
      <ArrowBack fontSize="small" />
    </RoundButton>
  );
}

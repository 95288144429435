import { FormControl, FormHelperText } from '@mui/material';
import { useMemo } from 'react';

import { FormSwitchProps } from '../../../common/forms';
import { styledProps } from '../../../common/styles';
import { Switch } from '../../atoms';
import { HELPER_TEXT_HEIGHT } from '../../atoms/TextField';

export function FormSwitch({ error, FormControlProps, FormHelperTextProps, helperText, ...props }: FormSwitchProps) {
  const formHelperTextProps = useMemo(
    () => styledProps({ minHeight: HELPER_TEXT_HEIGHT }, FormHelperTextProps),
    [FormHelperTextProps],
  );

  const switchProps = useMemo(() => styledProps({ flexGrow: 1 }, props), [props]);

  return (
    <FormControl error={error} {...FormControlProps}>
      <Switch {...switchProps} />
      <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>
    </FormControl>
  );
}

import { InsertDriveFileOutlined } from '@mui/icons-material';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateRange } from '@sbiz/util-dates';

import { TABLES, TableActions, TableName, getTableLabelKeys } from '../../common/tables';
import { useAuthUserContext } from '../../contexts/AuthUserContext';
import { Button, FlexBox, SearchInput } from '../atoms';
import { ButtonConfirmProps } from '../atoms/Button';
import { RowDeletionButton } from '../organisms/Table/RowDeletionButton';
import { CompanySelector } from './CompanySelector';
import { TableDateRangePicker } from './TableDateRangePicker';

export type TableHeaderProps = {
  actions?: TableActions;
  companyId: string;
  count?: number;
  dateRange?: DateRange;
  deletionConfirmProps?: ButtonConfirmProps;
  isDeletionAuthorized?: boolean;
  isDeletionDisabled?: boolean;
  isDeletionsTable?: boolean;
  name: TableName;
  onCompanyChange: (companyId: string) => void;
  onDateRangeChange?: (range: DateRange) => void;
  onDeletionsSwitchChange: (checked: boolean) => void;
  onExport?: () => void;
  onSearchChange: (search: string) => void;
  search: string;
  selectionCount?: number;
};

export function TableHeader({
  actions,
  companyId,
  count = 0,
  dateRange,
  deletionConfirmProps,
  isDeletionAuthorized,
  isDeletionDisabled,
  isDeletionsTable,
  name,
  onCompanyChange,
  onDateRangeChange,
  onDeletionsSwitchChange,
  onExport,
  onSearchChange,
  search,
  selectionCount,
}: TableHeaderProps) {
  const { hiddenActions } = TABLES[name];

  const { t } = useTranslation();

  const getLabelKeys = useCallback((labelName: string) => getTableLabelKeys(name, labelName), [name]);

  const labels = useMemo(
    () => ({
      count: t(getLabelKeys('count'), { count: count ?? 0, entries: t(getLabelKeys(count > 1 ? 'entries' : 'entry')) }),
      deleteBtn: t(getLabelKeys('deleteBtn')),
      deletionSwitch: t(getLabelKeys('deletionSwitch'), { entries: t(getLabelKeys('entries')) }),
      exportBtn: t(getLabelKeys('exportBtn')),
      searchPlaceholder: t(getLabelKeys('searchPlaceholder')),
    }),
    [count, getLabelKeys, t],
  );

  const { authUser, mutate: mutateAuthUser } = useAuthUserContext();

  const isDeletionsSwitchHidden = useMemo(() => hiddenActions?.includes('deletionsSwitch'), [hiddenActions]);

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange) => {
      if (actions?.dateRangePicker) {
        onDateRangeChange?.(dateRange);
      }
    },
    [actions?.dateRangePicker, onDateRangeChange],
  );

  const handleDeletionsSwitchChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!isDeletionsSwitchHidden) {
        onDeletionsSwitchChange(checked);
      }
    },
    [isDeletionsSwitchHidden, onDeletionsSwitchChange],
  );

  const handleExport = useCallback(() => {
    if (count) {
      onExport?.();
    }
  }, [count, onExport]);

  useEffect(() => {
    if (actions?.companySelector) {
      mutateAuthUser();
    }
  }, [actions?.companySelector, mutateAuthUser]);

  return (
    <FlexBox sx={{ alignItems: 'center', gap: 2, mb: '1rem' }}>
      <span>{labels.count}</span>

      {actions?.dateRangePicker && dateRange && (
        <TableDateRangePicker dateRange={dateRange} onDateRangeChange={handleDateRangeChange} />
      )}

      <SearchInput onChange={onSearchChange} placeholder={labels.searchPlaceholder} search={search} />

      {actions?.companySelector && authUser.companies.length > 0 && (
        <CompanySelector companyId={companyId} companies={authUser.companies} onChange={onCompanyChange} />
      )}

      {!isDeletionsSwitchHidden && (
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isDeletionsTable} onChange={handleDeletionsSwitchChange} />}
            label={labels.deletionSwitch}
            labelPlacement="start"
          />
        </FormGroup>
      )}

      {actions?.extraFilter}

      <FlexBox sx={{ gap: 2, ml: 'auto' }}>
        {actions?.extra}

        {isDeletionAuthorized && !hiddenActions?.includes('delete') && (
          <RowDeletionButton
            confirmProps={deletionConfirmProps}
            count={selectionCount ?? 0}
            disabled={isDeletionDisabled}
            tableName={name}
          />
        )}

        {!hiddenActions?.includes('export') && (
          <Button disabled={!count} onClick={handleExport} size="small" startIcon={<InsertDriveFileOutlined />}>
            {labels.exportBtn}
          </Button>
        )}
      </FlexBox>
    </FlexBox>
  );
}

import { useCallback } from 'react';

import { TAB_PARAM_NAME } from '../common/pages';
import { useQueryParam } from './useQueryParam';

export function useTabQueryParam() {
  const [tabParam, setTabParam] = useQueryParam(TAB_PARAM_NAME);

  const setTabQueryParam = useCallback(
    (value: number | string | undefined) => {
      if (!value || value === '0') {
        setTabParam(undefined);
      } else {
        setTabParam(String(value));
      }
    },
    [setTabParam],
  );

  return [tabParam, setTabQueryParam] as const;
}

import { AddLink } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InvitationInterval } from '@sbiz/business';

import { useApi } from '../../../common/api/hooks/useApi';
import { useApiCache } from '../../../common/api/hooks/useApiCache';
import { EmployeeInvitation } from '../../../common/api/resources/employee-invitation';
import { FULLNAME_TABLE_COLUMN, nestedValueGetter, TableColDef } from '../../../common/tables';
import { useIsEditor } from '../../../hooks/useIsEditor';
import { Button } from '../../atoms';
import { Feedback, FeedbackProps } from '../../atoms/Feedback';
import { TableCellLink } from '../../atoms/TableCellLink';
import { Table } from '../../organisms';
import { InvitationExtraActions } from '../common/invitations/InvitationExtraActions';

const TABLE_NAME = 'employeeInvitations';

export function EmployeeInvitationsTable() {
  const { t } = useTranslation();

  const labels = useMemo(() => ({ enrollBtn: t(`tables.${TABLE_NAME}.labels.enrollBtn`) }), [t]);

  const [feedbackProps, setFeedbackProps] = useState<Omit<FeedbackProps, 'name' | 'onClose'>>({ open: false });
  const { patch: adminPatch } = useApi('employeeInvitationAdmin');
  const { data: interval } = useApiCache<'employeeInvitation', InvitationInterval>('employeeInvitation', 'interval');
  const isAdmin = useIsEditor('adminEmployees');

  const closeFeedback = useCallback(() => {
    setFeedbackProps((props) => ({ ...props, open: false }));
  }, []);

  const confirmEnrollment = useCallback(
    (invitationId: string) => async () => {
      const { error } = await adminPatch(`${invitationId}/confirm`, {
        fetcher: { clear: ['employee', 'employeeInvitation'] },
      });

      if (error) {
        setFeedbackProps({ labels: { error: error.message }, open: true, severity: 'error' });
      } else {
        setFeedbackProps({ open: true, severity: 'success' });
      }
    },
    [adminPatch],
  );

  const ExtraActions = useCallback(
    ({ row: { _id: invitationId, invitedAt } }: GridRenderCellParams<EmployeeInvitation>) => (
      <>
        {isAdmin && <Button Icon={AddLink} onClick={confirmEnrollment(invitationId)} title={labels.enrollBtn}></Button>}
        <InvitationExtraActions
          interval={interval}
          invitationId={invitationId}
          invitedAt={invitedAt}
          tableName={TABLE_NAME}
        />
      </>
    ),
    [confirmEnrollment, interval, isAdmin, labels.enrollBtn],
  );

  const columns = useMemo(
    (): TableColDef<EmployeeInvitation>[] => [
      FULLNAME_TABLE_COLUMN,
      { field: 'email' },
      {
        field: 'profile.name',
        renderCell: ({ row: { profile }, formattedValue }) => (
          <TableCellLink href={`/profiles/${profile._id}`}>{formattedValue}</TableCellLink>
        ),
        valueGetter: nestedValueGetter,
      },
      { field: 'employeeId', sortable: false },
      { field: 'invitedAt', valueType: 'date' },
    ],
    [],
  );

  return (
    <>
      <Table columns={columns} ExtraActions={ExtraActions} name={TABLE_NAME} />
      {isAdmin && <Feedback name="employeeEnrollmentConfirmation" {...feedbackProps} onClose={closeFeedback} />}
    </>
  );
}

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiError } from '@sbiz/util-browser';

import { getFormAlertProp } from '../common/forms';

export function useFormErrorAlertProp() {
  const [messageKeys, setMessageKeys] = useState<string[]>();

  const { t } = useTranslation();

  const alertProp = useMemo(() => {
    if (messageKeys) {
      return getFormAlertProp('error', { label: t(messageKeys) });
    }
  }, [messageKeys, t]);

  const setAlertProp = useCallback((error: ApiError | undefined) => {
    setMessageKeys(error?.messageKeys);
  }, []);

  return [alertProp, setAlertProp] as const;
}

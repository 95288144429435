import { Box, capitalize, Card, CardContent, Typography } from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyJson, PermissionsScope } from '@sbiz/business';
import { DEFAULT_LANG, Lang } from '@sbiz/common';
import { toMoney } from '@sbiz/util-common';

import { useActiveApiListItems } from '../../../common/api/hooks/useActiveApiListItems';
import { useCompanyCache } from '../../../common/api/hooks/useCompanyCache';
import { CompanySettings } from '../../../common/api/resources/company';
import { Manager } from '../../../common/api/resources/manager';
import { useIsEditor } from '../../../hooks/useIsEditor';
import { useGetOfferedMonthsLabel } from '../../../hooks/useOfferedMonthsLabel';
import { EditButton, FlexBox, Link, Span } from '../../atoms';
import { CheckMarkIcon } from '../../icons';
import { FormDialog } from '../../molecules';
import { DialogFormProps, FormDialogCloseReason } from '../../molecules/FormDialog';
import { useGetFieldLabel } from '../../organisms/Form/hooks/useGetFieldLabel';
import { CompanyInformationForm } from './CompanyInformationForm';
import { CompanyInvoicingForm } from './CompanyInvoicingForm';

type InformationDisplay = { formattedValue?: ReactNode; label: ReactNode; value: ReactNode };
type ValueFormatter<T extends keyof CompanyJson> = (value: CompanyJson[T]) => ReactNode;

export function CompanyInformation({
  company,
  manager,
  onChange,
}: {
  company: CompanyJson;
  manager: Manager | null;
  onChange: () => void;
}) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      firstRebate: (threshold: string, percentage: string) =>
        t('resources.company.propertyValues.items', { percentage, threshold }),
      information: t('dialogs.companyInformation.title'),
      invoicing: t('dialogs.companyInvoicing.title'),
      language: (lang?: Lang) => t(`resources.propertyValues.lang.${lang ?? DEFAULT_LANG}`),
      rebate: t('resources.company.propertyNames.rebate'),
      serviceFee: t('resources.company.propertyNames.fees_service'),
      statementFormat: t('resources.company.propertyNames.statementFormat'),
      statementFormatValue: `${t(
        `resources.company.propertyValues.invoiceFormat.${
          company.invoiceFormat === 'detailed' ? 'detailed' : 'default'
        }`,
      )} - ${t(
        `resources.company.propertyValues.invoiceColorTheme.${company.invoiceColorTheme === 'bnw' ? 'bnw' : 'color'}`,
      )}`,
    }),
    [company.invoiceColorTheme, company.invoiceFormat, t],
  );

  const [isInformationEdition, setIsInformationEdition] = useState(false);
  const [isInvoicingEdition, setIsInvoicingEdition] = useState(false);

  const { data: managers } = useActiveApiListItems('manager');
  const { data: settings } = useCompanyCache<CompanySettings>('settings', { keepPreviousData: true });
  const getFieldLabel = useGetFieldLabel();
  const getOfferedMonthsLabel = useGetOfferedMonthsLabel();
  const isAdmin = useIsEditor('adminCompanies');

  const feeOfferLabel = useMemo(
    () => settings?.offeredMonths && getOfferedMonthsLabel(settings.offeredMonths),
    [getOfferedMonthsLabel, settings?.offeredMonths],
  );

  const firstRebate = useMemo(() => {
    if (settings?.rebate) {
      const [
        {
          threshold,
          value: { percentage },
        },
      ] = company?.rebate?.items ?? settings.rebate;

      return { percentage, threshold };
    }
  }, [company?.rebate?.items, settings?.rebate]);

  const serviceFee = useMemo(
    () => ({
      isCharged: Boolean(company.fees?.service?.isCharged),
      percentage: company.fees?.service?.percentage ?? settings?.fees?.service?.percentage,
    }),
    [company.fees?.service?.isCharged, company.fees?.service?.percentage, settings?.fees?.service?.percentage],
  );

  const getProperty = useCallback(
    <T extends keyof CompanyJson>(
      type: 'information' | 'invoicing',
      propertyName: T,
      valueFormatter?: (value: CompanyJson[T]) => ReactNode,
    ) => {
      const value = company[propertyName];
      const formattedValue = valueFormatter?.(value);

      return {
        formattedValue,
        label: getFieldLabel(`company${capitalize(type)}Update`, propertyName, 'company'),
        value,
      };
    },
    [company, getFieldLabel],
  );

  const getInformation = useCallback(
    <T extends keyof CompanyJson>(propertyName: T, valueFormatter?: ValueFormatter<T>) =>
      getProperty<T>('information', propertyName, valueFormatter),
    [getProperty],
  );

  const getInvoicingInformation = useCallback(
    <T extends keyof CompanyJson>(propertyName: T, valueFormatter?: ValueFormatter<T>) =>
      getProperty('invoicing', propertyName, valueFormatter),
    [getProperty],
  );

  const handleDialogClose = useCallback(
    (reason: FormDialogCloseReason) => {
      if (reason === 'formSubmission') {
        onChange();
      }
    },
    [onChange],
  );

  const closeInformationDialog = useCallback(
    (reason: FormDialogCloseReason) => {
      setIsInformationEdition(false);
      handleDialogClose(reason);
    },
    [handleDialogClose],
  );

  const closeInvoicingDialog = useCallback(
    (reason: FormDialogCloseReason) => {
      setIsInvoicingEdition(false);
      handleDialogClose(reason);
    },
    [handleDialogClose],
  );

  const InformationForm = useMemo(
    () =>
      company &&
      managers &&
      ((props: DialogFormProps) => <CompanyInformationForm company={company} managers={managers} {...props} />),
    [company, managers],
  );

  const InvoicingForm = useMemo(
    () =>
      company && settings
        ? (props: DialogFormProps) => <CompanyInvoicingForm company={company} settings={settings} {...props} />
        : null,
    [company, settings],
  );

  return (
    <>
      <FlexBox sx={[{ gap: 2 }, ({ breakpoints }) => ({ [breakpoints.down('lg')]: { flexDirection: 'column' } })]}>
        <Informations
          onEdition={() => {
            setIsInformationEdition(true);
          }}
          permissionsScope="companyInformation"
          title={labels.information}
        >
          <InformationRow content={[getInformation('name'), getInformation('externalReference')]} />
          <InformationRow content={[getInformation('postalAddress')]} />
          <InformationRow
            content={[
              getInformation('contactPhoneNumber'),
              getInformation('portalAdminContact', () => manager && `${manager.firstname} ${manager.lastname}`),
            ]}
          />
          <InformationRow content={[getInformation('contactEmail')]} />
          <InformationRow content={[getInformation('website')]} />
          <InformationRow
            content={[
              getInformation(
                'salesRepresentativeEmail',
                (email) => email && <Link href={`mailto:${email}`}>{email}</Link>,
              ),
            ]}
          />
        </Informations>

        <Informations
          onEdition={() => {
            setIsInvoicingEdition(true);
          }}
          permissionsScope="companyInvoiceSetting"
          title={labels.invoicing}
        >
          <InformationRow content={[getInvoicingInformation('companyRegister')]} />
          <InformationRow content={[getInvoicingInformation('invoiceAddress')]} />
          <InformationRow
            content={[
              getInvoicingInformation('vatNumber'),
              getInvoicingInformation('purchaseOrderNumber'),
              getInvoicingInformation('prefix'),
            ]}
          />
          <InformationRow
            content={[
              getInvoicingInformation('invoiceContactName'),
              getInvoicingInformation('shouldEmailForNewInvoice', (value) => <CheckMarkIcon isChecked={value} />),
            ]}
          />
          {company.shouldEmailForNewInvoice && (
            <InformationRow
              content={[getInvoicingInformation('contactEmailFinance', (value) => value || company.contactEmail)]}
            />
          )}
          <InformationRow
            content={[
              { label: labels.statementFormat, value: labels.statementFormatValue },
              getInvoicingInformation('lang', (lang) => labels.language(lang)),
            ]}
          />
          {isAdmin && (
            <>
              <InformationRow
                content={[getInvoicingInformation('receivableLimit', toMoney), getInvoicingInformation('paymentTerms')]}
              />
              <InformationRow
                content={[
                  {
                    label: labels.serviceFee,
                    value: (
                      <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
                        <CheckMarkIcon isChecked={serviceFee.isCharged} />
                        {serviceFee.isCharged && serviceFee.percentage && (
                          <>
                            <Span>{serviceFee.percentage.toFixed(2)}%</Span>
                            <Span>{feeOfferLabel}</Span>
                          </>
                        )}
                      </FlexBox>
                    ),
                  },
                  {
                    label: labels.rebate,
                    value: (
                      <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
                        <CheckMarkIcon isChecked={company?.rebate?.isActive} />
                        {company?.rebate?.isActive && firstRebate && (
                          <Span>
                            {labels.firstRebate(toMoney(firstRebate.threshold), firstRebate.percentage.toFixed(2))}
                          </Span>
                        )}
                      </FlexBox>
                    ),
                  },
                ]}
              />
            </>
          )}
        </Informations>
      </FlexBox>

      <FormDialog
        Form={InformationForm}
        maxWidth="md"
        onClose={closeInformationDialog}
        open={isInformationEdition}
        name="companyInformationUpdate"
      />

      <FormDialog
        Form={InvoicingForm}
        maxWidth="md"
        onClose={closeInvoicingDialog}
        open={isInvoicingEdition}
        name="companyInvoicingUpdate"
      />
    </>
  );
}

function InformationRow({ content }: { content: InformationDisplay[] }) {
  return (
    <FlexBox sx={{ gap: 2 }}>
      {content.map(({ label, formattedValue, value }, index) => (
        <Box sx={{ flex: '1 0' }} key={index}>
          <Typography color="text.secondary" sx={{ fontSize: '0.92em' }}>
            {label}
          </Typography>
          <Typography component="div" sx={{ fontSize: '1.1em' }}>
            {formattedValue ?? (value || '-')}
          </Typography>
        </Box>
      ))}
    </FlexBox>
  );
}

function Informations({
  children,
  onEdition,
  permissionsScope,
  title,
}: {
  children: ReactNode;
  onEdition: () => void;
  permissionsScope: PermissionsScope;
  title: string;
}) {
  return (
    <Card sx={{ flex: '1 0', p: 1 }}>
      <CardContent>
        <Typography component={FlexBox} variant="h5" sx={{ alignItems: 'center', mb: 3 }}>
          <Span>{title}</Span>
          <EditButton onClick={onEdition} scope={permissionsScope} sx={{ ml: 'auto' }} />
        </Typography>

        <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>{children}</FlexBox>
      </CardContent>
    </Card>
  );
}

import { SWROptions } from '..';
import { ApiRecord, ApiRecords } from '../resources';
import { useApiCache } from './useApiCache';

function useApiResource<T extends keyof ApiRecords, TData = ApiRecord<T>>(
  resourceType: T,
  resourceId: string | undefined,
  options?: SWROptions<TData>,
) {
  return useApiCache<T, TData>(resourceType, resourceId, options);
}

export default useApiResource;

import { Table, TableBody, TableContainer, TableContainerProps, TableHead, TableRow } from '@mui/material';

import { CsvPreview, CsvRow } from '../../../common/api';
import { styledProps } from '../../../common/styles';
import { FileCell } from './FileInvalidRows';

export function FilePreview({
  headers,
  preview,
  ...containerProps
}: { headers: CsvRow; preview: CsvPreview } & TableContainerProps) {
  return (
    <TableContainer {...styledProps({ maxHeight: 480, pb: 0.2, scrollbarWidth: 'thin' }, containerProps)}>
      <Table size="small" sx={{ borderCollapse: 'collapse' }}>
        <TableHead>
          <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
            {headers.map((header) => (
              <FileCell key={header} value={header} />
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {preview.rows?.map((cells, rowIndex) => (
            <TableRow key={rowIndex}>
              {headers.map((_, cellIndex) => (
                <FileCell
                  error={preview.invalidRows.at(rowIndex)?.at(cellIndex)}
                  key={cellIndex}
                  value={cells[cellIndex] ?? ''}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

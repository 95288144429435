import { Typography } from '@mui/material';

import { Order, OrderAddress } from '@sbiz/business';

import { styledProps } from '../../../../common/styles';
import { AddressLink, FlexBox, Span } from '../../../atoms';
import { FlexBoxProps } from '../../../atoms/FlexBox';

type AddressLabels = { date: string; title: string };

export type DeliveryPointsProps = { labels: { dropoff: AddressLabels; pickup: AddressLabels }; order: Order } & Omit<
  FlexBoxProps,
  'order'
>;

export function DeliveryPoints({ labels, order, ...boxProps }: DeliveryPointsProps) {
  return (
    <FlexBox {...styledProps({ gap: 8, justifyContent: 'space-between' }, boxProps)}>
      <DeliveryPoint address={order.pickupAddress} labels={labels.pickup} />
      <DeliveryPoint address={order.dropoffAddress} labels={labels.dropoff} />
    </FlexBox>
  );
}

function DeliveryPoint({ address, labels }: { address: OrderAddress; labels: AddressLabels }) {
  return (
    <FlexBox column>
      <Typography sx={{ fontWeight: 'bold' }} variant="h6">
        {labels.title}
      </Typography>
      <AddressLink address={address} />
      <Span sx={{ mt: 1 }}>{labels.date}</Span>
    </FlexBox>
  );
}

import { useMemo } from 'react';

import { useActiveProfiles } from './useActiveProfiles';

export function useProfileOptions() {
  const profiles = useActiveProfiles();

  return useMemo(() => {
    if (profiles?.length) {
      return profiles.map(({ _id: profileId, name }) => ({ label: name, value: profileId }));
    }

    return [];
  }, [profiles]);
}

import { Alert, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useApi } from '../../../../common/api/hooks/useApi';
import { CreditCard } from '../../../../common/api/resources/company/types';
import { Button, Feedback, FlexBox, Span } from '../../../atoms';
import { ConfirmCloseReason } from '../../../atoms/Confirm';

import styles from './styles.module.scss';

const BRAND_MODIFIERS = { AMX: 'amex', ECA: 'mastercard' } as const;

export function CreditCardDisplay({
  creditCard,
  onCardReplacement,
}: {
  creditCard: CreditCard;
  onCardReplacement: () => void;
}) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      confirmDelete: t('dialogs.creditCardDeletion.text', { name: creditCard.name }),
      deleteBtn: t('buttons.delete'),
      invalid: t('company.creditCard.invalid'),
      replaceBtn: t('company.creditCard.replaceBtn'),
      title: t('tabs.company.labels.creditCard'),
    }),
    [creditCard.name, t],
  );

  const [isDeletionError, setIsDeletionError] = useState(false);

  const { deleteRequest } = useApi('company');

  const brandClassName = useMemo(() => {
    const paymentMethod = creditCard.paymentMethod;

    if (paymentMethod === 'VIS') {
      return;
    }

    if (paymentMethod in BRAND_MODIFIERS) {
      const modifier = BRAND_MODIFIERS[paymentMethod];
      return styles[`card--${modifier}`];
    }
  }, [creditCard.paymentMethod]);

  const isInvalid = useMemo(() => !creditCard.isValidated, [creditCard.isValidated]);

  const mask = useMemo(() => {
    if (!creditCard.values?.masked) {
      return <MaskedDigits groupCount={4} />;
    }

    return (
      <>
        <span key={0}>{creditCard.values.masked.substring(0, 4)}</span>
        <MaskedDigits groupCount={2} startIndex={1} />
        <span key={3}>{creditCard.values.masked.slice(-4)}</span>
      </>
    );
  }, [creditCard.values.masked]);

  const deleteCreditCard = useCallback(async () => {
    setIsDeletionError(false);

    const { error } = await deleteRequest(`credit-card/${creditCard._id}`, { fetcher: { clear: 'company' } });
    if (error) {
      setIsDeletionError(true);
    }
  }, [creditCard._id, deleteRequest]);

  const handleConfirmationDialogClose = useCallback(
    (reason: ConfirmCloseReason) => {
      if (reason === 'confirm') {
        deleteCreditCard();
      }
    },
    [deleteCreditCard],
  );

  return (
    <>
      <FlexBox className={styles['credit-card-display']} column>
        <Typography component="h1" variant="h4">
          {creditCard.name ?? labels.title}
        </Typography>

        <Paper
          className={clsx(styles['card'], brandClassName)}
          elevation={8}
          sx={{ borderRadius: 5, my: 5, opacity: isInvalid ? 0.72 : 1, p: 4 }}
        >
          <FlexBox className={styles['card__brand']}>{creditCard.values.info.brand}</FlexBox>

          <FlexBox className={styles['card__mask']}>{mask}</FlexBox>

          <FlexBox sx={{ gap: 2 }}>
            <Span className={styles['card__holderName']}>{creditCard.cardholderName}</Span>
            <Span className={styles['card__expiry']}>
              {creditCard.values.expiryMonth}/{creditCard.values.expiryYear}
            </Span>
          </FlexBox>
        </Paper>
      </FlexBox>

      {isInvalid && (
        <Alert severity="warning" sx={{ mb: 4 }} variant="outlined">
          {`${t(labels.invalid)}${creditCard.invalidationReason ? ` : code "${creditCard.invalidationReason}"` : ''}`}
        </Alert>
      )}

      <FlexBox sx={{ alignItems: 'center', gap: 4 }}>
        <Button
          confirmProps={{ onClose: handleConfirmationDialogClose, text: labels.confirmDelete }}
          onClick={onCardReplacement}
          sx={{ backgroundColor: 'error.light', flex: '1 1 0', '&:hover': { backgroundColor: 'error.dark' } }}
        >
          {labels.deleteBtn}
        </Button>
        <Button onClick={onCardReplacement} sx={{ flex: '1 1 0' }}>
          {labels.replaceBtn}
        </Button>
      </FlexBox>

      <Feedback
        name="creditCardDeletion"
        onClose={() => {
          setIsDeletionError(false);
        }}
        open={isDeletionError}
        severity="error"
      />
    </>
  );
}

function MaskedDigits({ groupCount, startIndex = 0 }: { groupCount: number; startIndex?: number }) {
  return (
    <>
      {Array.from({ length: groupCount }, (_, index) => (
        <span className={styles['card__masked-digits']} key={index + startIndex}>
          {'·'.repeat(4)}
        </span>
      ))}
    </>
  );
}

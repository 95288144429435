import { MoveDown } from '@mui/icons-material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActiveApiListItems } from '../../../../common/api/hooks/useActiveApiListItems';
import { useApi } from '../../../../common/api/hooks/useApi';
import { Button, Feedback } from '../../../atoms';
import { ButtonConfirmProps } from '../../../atoms/Button';
import { ProfileTransferForm } from './ProfileTransferForm';

export function EmployeesTransfer({
  currentProfileId,
  employeeIds,
}: {
  currentProfileId?: string;
  employeeIds: string[];
}) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      button: t('tables.employees.labels.transferButton'),
      dialogTitle: t('dialogs.profileTransfer.title'),
    }),
    [t],
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  const { patch } = useApi('employee');

  const { data: profiles } = useActiveApiListItems('profile', {
    params: {
      filter: JSON.stringify({ ...(currentProfileId && { _id: { $ne: { $oid: currentProfileId } } }) }),
      sort: JSON.stringify({ name: 1 }),
    },
  });

  const [selectedProfileId, setSelectedProfileId] = useState('');

  const handleChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setSelectedProfileId(target.value);
  }, []);

  const transfer = useCallback(async () => {
    if (!(selectedProfileId && employeeIds.length)) {
      return;
    }

    setErrorMessage('');

    const { error } = await patch('profile', {
      data: { employeeIds, profileId: selectedProfileId },
      fetcher: { clear: 'employee' },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setSelectedProfileId('');
    }
  }, [employeeIds, patch, selectedProfileId]);

  const confirmChildren = useMemo(
    () =>
      profiles &&
      Boolean(profiles.length) && (
        <ProfileTransferForm onChange={handleChange} profiles={profiles} value={selectedProfileId} />
      ),
    [handleChange, profiles, selectedProfileId],
  );

  const confirmProps = useMemo(
    (): ButtonConfirmProps => ({
      children: confirmChildren,
      onClose: (reason) => {
        if (reason === 'confirm') {
          transfer();
        }
      },
      title: labels.dialogTitle,
    }),
    [confirmChildren, labels.dialogTitle, transfer],
  );

  return (
    <>
      <Button confirmProps={confirmProps} disabled={!employeeIds.length} size="small" startIcon={<MoveDown />}>
        {labels.button}
      </Button>

      <Feedback
        name="profileTransfer"
        open={Boolean(errorMessage)}
        onClose={() => {
          setErrorMessage(undefined);
        }}
        severity="error"
        labels={{ error: errorMessage }}
      />
    </>
  );
}

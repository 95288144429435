import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PermissionsScope } from '@sbiz/business';

import { Statement } from '../../../../common/api/resources/statement';
import { useIsEditor } from '../../../../hooks/useIsEditor';
import { Button, ButtonProps } from '../../../atoms/Button';
import { StatementPayIcon, StatementUnpayIcon } from '../../../icons';

export function StatementPaymentButton({
  onClick,
  permissionsScope,
  statement,
  ...props
}: { onClick: () => Promise<void>; permissionsScope: PermissionsScope; statement: Statement } & Omit<
  ButtonProps,
  'isLoading' | 'onClick'
>) {
  const { t } = useTranslation();

  const labels = useMemo(() => {
    const prefix = 'tables.statements.labels.paymentBtn';
    return { pay: t(`${prefix}.pay`), unpay: t(`${prefix}.unpay`) };
  }, [t]);

  const isPaid = Boolean(statement.paidAt);

  const [isLoading, setIsLoading] = useState(false);

  const isEditor = useIsEditor(permissionsScope);

  const actionProps = useMemo(
    (): Pick<ButtonProps, 'Icon' | 'title'> =>
      isPaid ? { Icon: StatementUnpayIcon, title: labels.unpay } : { Icon: StatementPayIcon, title: labels.pay },
    [isPaid, labels.pay, labels.unpay],
  );

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  }, [onClick]);

  return isEditor && <Button isLoading={isLoading} onClick={handleClick} {...actionProps} {...props} />;
}

import { Done, DoneAll } from '@mui/icons-material';
import { Step, StepConnector, stepConnectorClasses, StepLabel, Stepper } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ADMIN_PERMISSIONS_NAME, DEFAULT_PERMISSIONS_NAME, PERMISSIONS_NAMES, PermissionsName } from '@sbiz/business';

import { FlexBox, Span } from '../../../../atoms';

export function PermissionsStepper({
  isReadOnly,
  permissionsName,
  onClick,
}: {
  isReadOnly: boolean;
  permissionsName: PermissionsName;
  onClick: (name: PermissionsName) => void;
}) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      [`preset.${DEFAULT_PERMISSIONS_NAME}`]: t('resources.manager.propertyValues.permissions_name.PortalManager'),
      [`preset.${ADMIN_PERMISSIONS_NAME}`]: t('resources.manager.propertyValues.permissions_name.SmoodAdmin'),
    }),
    [t],
  );

  const activeStep = useMemo(() => PERMISSIONS_NAMES.indexOf(permissionsName), [permissionsName]);

  const handleClick = useCallback(
    (name: PermissionsName) => () => {
      if (!isReadOnly) {
        onClick(name);
      }
    },
    [isReadOnly, onClick],
  );

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={
        <StepConnector
          sx={{
            [`& .${stepConnectorClasses.line}`]: { position: 'relative', top: 12, borderWidth: 3, zIndex: 0 },
            [`&.${stepConnectorClasses.completed}`]: {
              [`.${stepConnectorClasses.line}`]: { borderColor: 'primary.main' },
            },
          }}
        />
      }
      nonLinear
      sx={{ mb: 2 }}
    >
      {PERMISSIONS_NAMES.map((name) => {
        const StepIcon = name === ADMIN_PERMISSIONS_NAME ? DoneAll : Done;
        const completed = PERMISSIONS_NAMES.indexOf(name) <= activeStep;
        const color = completed ? 'primary.main' : 'grey.400';

        return (
          <Step completed={completed} key={name}>
            <StepLabel
              StepIconProps={{
                icon: (
                  <FlexBox
                    onClick={handleClick(name)}
                    sx={{
                      alignItems: 'center',
                      backgroundColor: color,
                      borderRadius: '50%',
                      cursor: isReadOnly ? 'default' : 'pointer',
                      height: 50,
                      justifyContent: 'center',
                      width: 50,
                      zIndex: 1,
                    }}
                  >
                    <StepIcon sx={{ color: 'white' }} />
                  </FlexBox>
                ),
              }}
            >
              <Span sx={{ color }}>{labels[`preset.${name}`]}</Span>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

import { useCallback } from 'react';

import { useQueryParam } from '../../../../hooks/useQueryParam';
import { OrderDetails, OrderDetailsProps } from './OrderDetails';

export const ORDER_NUMBER_PARAM = 'orderNumber';

export default function OrderDetailsContainer({ admin }: Pick<OrderDetailsProps, 'admin'>) {
  const [param, setParam] = useQueryParam(ORDER_NUMBER_PARAM);
  const number = param?.value;

  const handleClose = useCallback(() => {
    setParam(undefined);
  }, [setParam]);

  return number ? <OrderDetails admin={admin} number={number} onClose={handleClose} /> : null;
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Manager } from '../../../../common/api/resources/manager';
import { FormDialog } from '../../../molecules';
import { DialogFormProps, FormDialogOverrideProps } from '../../../molecules/FormDialog';
import { PermissionsSettingForm } from './PermissionsSettingForm';

const DIALOG_NAME = 'permissionsUpdate';

export function PermissionsSettingDialog({
  manager,
  ...dialogProps
}: { manager?: Manager } & Omit<FormDialogOverrideProps, 'open'>) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      title: t(`dialogs.${DIALOG_NAME}.title`, {
        name: manager?.firstname && `${manager.firstname} ${manager.lastname}`,
      }),
    }),
    [manager?.firstname, manager?.lastname, t],
  );

  const PermissionsForm = useMemo(
    () => manager && ((formProps: DialogFormProps) => <PermissionsSettingForm manager={manager} {...formProps} />),
    [manager],
  );

  return (
    <FormDialog
      Form={PermissionsForm}
      maxWidth="md"
      name={DIALOG_NAME}
      open={Boolean(manager)}
      title={labels.title}
      {...dialogProps}
    />
  );
}

import { Dialog as MuiDialog, DialogProps as MuiDialogProps, DialogTitle } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton } from './CloseButton';

export type DialogCloseReason = 'backdropClick' | 'buttonClick' | 'escapeKeyDown';
export type DialogProps = Omit<MuiDialogProps, 'onClose'> & {
  isBackdropClickDisabled?: boolean;
  isCloseButtonEnabled?: boolean;
  name?: string;
  onClose?: (reason: DialogCloseReason) => void;
  onOpen?: () => void;
  title?: string;
};

export function Dialog({
  children,
  isBackdropClickDisabled,
  isCloseButtonEnabled,
  maxWidth,
  name,
  onClose,
  onOpen,
  title: propsTitle,
  ...props
}: DialogProps) {
  const { t } = useTranslation();

  const labels = useMemo(() => {
    const prefix = name && `dialogs.${name}`;

    let title = propsTitle;
    if (!title && prefix) {
      title = t(`${prefix}.title`, { defaultValue: '' });
    }

    return { title };
  }, [name, propsTitle, t]);

  const titleProps = useMemo(() => {
    if (isCloseButtonEnabled) {
      return { sx: { paddingRight: 6 } };
    }
  }, [isCloseButtonEnabled]);

  const handleClose = useCallback(
    (_: object, reason: DialogCloseReason) => {
      if (!isBackdropClickDisabled || reason !== 'backdropClick') {
        onClose?.(reason);
      }
    },
    [isBackdropClickDisabled, onClose],
  );

  const handleCloseBtnClick = useCallback(() => {
    onClose?.('buttonClick');
  }, [onClose]);

  useEffect(() => {
    if (props.open) {
      onOpen?.();
    }
  }, [onOpen, props.open]);

  return (
    <MuiDialog disableRestoreFocus fullWidth maxWidth={maxWidth ?? 'sm'} onClose={handleClose} {...props}>
      {isCloseButtonEnabled && (
        <CloseButton onClick={handleCloseBtnClick} sx={{ position: 'absolute', right: 8, top: 8 }} />
      )}

      {labels.title && (
        <DialogTitle variant="h5" {...titleProps}>
          {labels.title}
        </DialogTitle>
      )}

      {children}
    </MuiDialog>
  );
}

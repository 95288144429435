import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Fields,
  FORM_FIELD_ERROR_KEYS,
  FORM_LABELS,
  FormFieldErrorLabels,
  FormLabelOverrides,
  FormResourceType,
  Labels,
} from '../../../../common/forms';
import { useGetErrorLabel } from './useGetErrorLabel';
import { useGetFieldLabel } from './useGetFieldLabel';

export function useGetLabels() {
  const { t } = useTranslation();

  const getErrorLabel = useGetErrorLabel();
  const getFieldLabel = useGetFieldLabel();

  return useCallback(
    <TFormData>(
      formName: string,
      fields: Fields<TFormData>,
      overrides?: FormLabelOverrides<TFormData>,
      resourceType?: FormResourceType,
    ) => {
      const prefix = `forms.${formName}`;
      const labels = { fields: {}, form: {} } as Labels<TFormData>;

      for (const label of FORM_LABELS) {
        labels.form[label] = overrides?.form?.[label] ?? t([`${prefix}.${label}`, `forms.${label}`]);
      }

      for (const name of Object.keys(fields) as (keyof Fields<TFormData>)[]) {
        const errors = {} as FormFieldErrorLabels;

        for (const key of FORM_FIELD_ERROR_KEYS) {
          errors[key] = overrides?.fields?.[name]?.errors?.[key] ?? getErrorLabel(formName, name, key, resourceType);
        }

        labels.fields[name] = {
          errors,
          label: overrides?.fields?.[name]?.label ?? getFieldLabel(formName, name, resourceType),
          tooltip:
            overrides?.fields?.[name]?.tooltip ??
            t([`${prefix}.fields.${name}.tooltip`, `forms.fields.${name}.tooltip`]),
        };
      }

      return labels;
    },
    [getErrorLabel, getFieldLabel, t],
  );
}

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PermissionsName } from '@sbiz/business';
import { EmptyObject } from '@sbiz/common';

import { useApi } from '../../../../../common/api/hooks/useApi';
import { Manager } from '../../../../../common/api/resources/manager';
import { PermissionsValues } from '../../../../../common/api/resources/permissions/types';
import { FormParts } from '../../../../../common/forms';
import { useAuthUserContext } from '../../../../../contexts/AuthUserContext';
import { useFormErrorAlertProp } from '../../../../../hooks/useFormErrorAlertProp';
import { useIsEditor } from '../../../../../hooks/useIsEditor';
import { ButtonProps } from '../../../../atoms/Button';
import { DialogFormProps } from '../../../../molecules/FormDialog';
import { Form } from '../../../../organisms/Form';
import { PermissionsSetting } from './PermissionsSetting';

export function PermissionsSettingForm({ manager, onSubmit, ...props }: { manager: Manager } & DialogFormProps) {
  const { _id: managerId, firstname, lastname, permissions } = manager;

  const [setting, setSetting] = useState<{ name: PermissionsName; values: PermissionsValues }>();

  const { updateOne } = useApi('permissions');
  const { authUser } = useAuthUserContext();
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();
  const isEditor = useIsEditor('adminPermissions');
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      confirmation: t('dialogs.permissionsNameUpdate.text', {
        currentPermissionsName: t(`resources.manager.propertyValues.permissions_name.${permissions.name}`),
        name: `${firstname} ${lastname}`,
        newPermissionsName: t(`resources.manager.propertyValues.permissions_name.${setting?.name}`),
      }),
    }),
    [firstname, lastname, permissions.name, setting?.name, t],
  );

  const isReadOnly = useMemo(() => managerId === authUser._id || !isEditor, [authUser._id, isEditor, managerId]);

  const Permissions = useCallback(
    () => (
      <PermissionsSetting
        formName={props.name}
        isReadOnly={isReadOnly}
        managerPermissions={manager.permissions}
        onChange={setSetting}
        onNameError={setErrorAlert}
        userPermissionsValues={authUser.permissions}
      />
    ),
    [authUser.permissions, isReadOnly, manager, props.name, setErrorAlert],
  );

  const parts = useMemo((): FormParts<EmptyObject> => [Permissions], [Permissions]);

  const handleSubmit = useCallback(async () => {
    if (setting) {
      const { error } = await updateOne(permissions._id, setting, { fetcher: { clear: 'managerAdmin' } });

      setErrorAlert(error);

      if (error) {
        return;
      }
    }

    onSubmit();
  }, [permissions._id, onSubmit, setErrorAlert, setting, updateOne]);

  const submitBtnProps = useMemo((): ButtonProps | undefined => {
    if (setting?.name && setting?.name !== permissions.name) {
      return { confirmProps: { text: labels.confirmation } };
    }
  }, [labels.confirmation, permissions.name, setting?.name]);

  return (
    <Form
      alert={errorAlert}
      onSubmit={handleSubmit}
      parts={parts}
      readOnly={isReadOnly}
      submitBtnProps={submitBtnProps}
      {...props}
    />
  );
}

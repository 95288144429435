import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogFormProps, FormDialog, FormDialogOverrideProps } from '../../../../molecules/FormDialog';
import { Form } from '../../../../organisms';
import { OfferMonths } from './OfferMonths';

export function ServiceFeeOffersDialog({
  offeredMonths,
  ...props
}: { offeredMonths: boolean[] } & FormDialogOverrideProps<Set<number>>) {
  const OfferForm = useCallback(
    (props: DialogFormProps<Set<number>>) => <OfferMonthsForm offeredMonths={offeredMonths} {...props} />,
    [offeredMonths],
  );

  return <FormDialog disableFeedback Form={OfferForm} name="serviceFeeOffer" {...props} />;
}

function OfferMonthsForm({
  offeredMonths,
  onSubmit,
  ...props
}: { offeredMonths: boolean[] } & DialogFormProps<Set<number>>) {
  const monthCount = offeredMonths.length;

  const defaultSelection = useMemo(
    () => new Set(offeredMonths.map((isSelected, index) => (isSelected ? index : -1)).filter((index) => index > -1)),
    [offeredMonths],
  );

  const [selection, setSelection] = useState(defaultSelection);

  const { t } = useTranslation();

  const submitBtnLabel = useMemo(() => {
    const key = 'forms.serviceFeeOffer.submitBtn';

    if (selection.size === 0) {
      return t(`${key}_empty`);
    }

    if (selection.size === 1) {
      return t(`${key}_single`);
    }

    return t(key, { count: selection.size });
  }, [t, selection.size]);

  const labels = useMemo(() => ({ form: { submitBtn: submitBtnLabel } }), [submitBtnLabel]);

  const handleMonthSelectionChange = useCallback((selection: Set<number>) => {
    setSelection(selection);
  }, []);

  const Months = useCallback(
    () => (
      <OfferMonths monthCount={monthCount} defaultSelection={defaultSelection} onChange={handleMonthSelectionChange} />
    ),
    [defaultSelection, handleMonthSelectionChange, monthCount],
  );

  const parts = useMemo(() => [Months], [Months]);

  const handleSubmit = useCallback(() => {
    onSubmit(selection);
  }, [onSubmit, selection]);

  return <Form labels={labels} onSubmit={handleSubmit} parts={parts} {...props} />;
}

import { useCallback, useMemo } from 'react';

import useApiResource from '../common/api/hooks/useApiResource';
import { useClearCache } from '../common/api/hooks/useClearCache';
import { getPageTabs, PageTab } from '../common/pages';
import { TAB_COMPONENTS } from '../components/pages/common/tabs';
import { CompanyInformation } from '../components/pages/company/CompanyInformation';
import { CompanyLocations } from '../components/pages/company/CompanyLocations';
import { CostCenters } from '../components/pages/company/CostCenters';
import { CostCentersActions } from '../components/pages/company/CostCenters/CostCentersActions';
import { useAuthorizedItems } from './useAuthorizedItems';
import { useCompany } from './useCompany';

export function useCompanyTabs() {
  const clearCache = useClearCache();
  const { data: company, mutate } = useCompany({ keepPreviousData: true });
  const isCostCentersReplacement = Boolean(company?.costCenters?.list?.length);
  const { data: companyManager } = useApiResource('manager', company?.portalAdminContact, { keepPreviousData: true });
  const manager = company?.portalAdminContact ? companyManager : null;

  const handleCompanyChange = useCallback(() => {
    mutate();
    clearCache('company', { path: 'settings' });
  }, [clearCache, mutate]);

  const tabs = useMemo(
    (): PageTab[] =>
      getPageTabs('company').map((tab) => {
        switch (tab.key) {
          case 'costCenters':
            return {
              ...tab,
              actions: <CostCentersActions isReplacement={isCostCentersReplacement} />,
              component: <CostCenters onChange={handleCompanyChange} />,
            };
          case 'information':
            return {
              ...tab,
              component:
                company && manager !== undefined ? (
                  <CompanyInformation company={company} manager={manager} onChange={handleCompanyChange} />
                ) : null,
            };
          case 'locations':
            return {
              ...tab,
              component: company ? <CompanyLocations company={company} onChange={handleCompanyChange} /> : null,
            };
          default:
            return { ...tab, component: TAB_COMPONENTS['company'][tab.key] };
        }
      }),
    [company, handleCompanyChange, isCostCentersReplacement, manager],
  );

  return useAuthorizedItems(tabs);
}

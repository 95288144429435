import { useMemo } from 'react';

import { nestedValueGetter, TableColDef } from '../../../common/tables';
import { Table } from '../../organisms/Table';

export function CheckDistributionsTable() {
  const columns = useMemo(
    (): TableColDef<{ _id: string }>[] => [
      { field: 'check.name', valueGetter: nestedValueGetter },
      { field: 'profile.name', valueGetter: nestedValueGetter },
      { field: 'createdAt', valueType: 'date' },
      { field: 'check.amount', valueGetter: nestedValueGetter, valueType: 'money' },
      { field: 'successCount' },
      { field: 'failureCount' },
      { field: 'totalAmount', valueType: 'money' },
    ],
    [],
  );

  return <Table columns={columns} name="checkDistributions" />;
}

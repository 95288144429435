import { GridColDef, GridSortDirection, GridValidRowModel } from '@mui/x-data-grid';
import { ReactNode } from 'react';

import { ValueProperty } from '@sbiz/common';

import { ResourceType } from '../api/resources';

export const TABLES_DEFINITION = {
  benefits: { resourceType: 'benefit' },
  checkDistributions: { resourceType: 'checkDistribution', hiddenActions: ['delete', 'deletionsSwitch'] },
  checks: { resourceType: 'check', sortField: 'updatedAt' },
  companiesAdmin: { hiddenActions: ['delete', 'deletionsSwitch'], resourceType: 'companyAdmin' },
  costCenters: {
    hiddenActions: ['deletionsSwitch'],
    resourceType: 'costCenter',
    sortDirection: 'asc',
    sortField: 'name',
  },
  datatransTransactionsAdmin: {
    actions: { companySelector: 1, dateRangePicker: 1 },
    hiddenActions: ['delete', 'deletionsSwitch'],
    resourceType: 'datatransAdmin',
  },
  employeeInvitations: { resourceType: 'employeeInvitation' },
  employees: { resourceType: 'employee', sortField: 'enrolledAt' },
  giftCards: { resourceType: 'giftCard' },
  giftCardsAdmin: { actions: { companySelector: 1 }, resourceType: 'giftCardAdmin' },
  managerInvitations: { resourceType: 'managerInvitation' },
  managers: { resourceType: 'manager' },
  managersAdmin: { actions: { companySelector: 1 }, resourceType: 'managerAdmin' },
  orders: {
    actions: { dateRangePicker: 1 },
    dateField: 'dropoffDeadline',
    hiddenActions: ['delete', 'deletionsSwitch'],
    resourceType: 'order',
    sortField: 'dropoffDeadline',
  },
  profileEmployees: { resourceType: 'employee', sortField: 'enrolledAt' },
  profiles: { resourceType: 'profile' },
  statements: { resourceType: 'statement', hiddenActions: ['delete', 'deletionsSwitch'] },
  statementsAdmin: {
    actions: { companySelector: 1 },
    hiddenActions: ['delete', 'deletionsSwitch'],
    resourceType: 'statementAdmin',
  },
} as const;
type Tables = typeof TABLES_DEFINITION;

export type TableName = keyof Tables;
export type TableDateField = ValueProperty<Tables, 'dateField'>;
export type TableSortField = ValueProperty<Tables, 'sortField'>;

export const DEFAULT_TABLE_ACTIONS = ['delete', 'deletionsSwitch', 'export'] as const;
export type DefaultTableAction = (typeof DEFAULT_TABLE_ACTIONS)[number];

const TABLE_ACTIONS = ['companySelector', 'dateRangePicker'] as const;
export type TableActionName = (typeof TABLE_ACTIONS)[number];
export type TableActions = { [k in TableActionName]?: 1 | true } & { extra?: ReactNode; extraFilter?: ReactNode };

type TableColDisplayPredicate = (config: { isDeletionsTable: boolean }) => boolean;
export type TableColValueGetter<T extends TableValidRowModel> = (
  value: unknown,
  row: T,
  column: GridColDef<T>,
) => unknown;
export type TableColValueType = 'date' | 'money';
export type TableColValueFormatter = (value: unknown) => unknown;

export type TableColDef<T extends TableValidRowModel> = Omit<GridColDef<T>, 'valueFormatter' | 'valueGetter'> & {
  isHidden?: TableColDisplayPredicate;
  sortFields?: string[];
  valueType?: TableColValueType;
  valueFormatter?: TableColValueFormatter;
  valueGetter?: TableColValueGetter<T>;
};

export type TableDefinition = {
  actions?: TableActions;
  dateField?: TableDateField;
  hiddenActions?: Readonly<DefaultTableAction[]>;
  resourceType: ResourceType;
  sortDirection?: GridSortDirection;
  sortField?: TableSortField;
};

export type TableValidRowModel = GridValidRowModel & { _id: string };

import { useCallback, useMemo, useRef } from 'react';

import { CompanyJson } from '@sbiz/business';
import { pick } from '@sbiz/util-common';
import { formatPhoneNumber } from '@sbiz/util-strings';

import { useApi } from '../../../common/api/hooks/useApi';
import { Manager } from '../../../common/api/resources/manager';
import { FormApi, FormParts } from '../../../common/forms';
import { useAddressAutocomplete } from '../../../hooks/useAddressAutoComplete';
import { useFormErrorAlertProp } from '../../../hooks/useFormErrorAlertProp';
import { useIsEditor } from '../../../hooks/useIsEditor';
import { SelectOption } from '../../atoms/TextField';
import { DialogFormProps } from '../../molecules/FormDialog';
import { Form } from '../../organisms';
import { useGetErrorLabel } from '../../organisms/Form/hooks/useGetErrorLabel';
import { useGetFieldLabel } from '../../organisms/Form/hooks/useGetFieldLabel';

const COMPANY_INFORMATION_FIELDS = [
  'contactEmail',
  'contactPhoneNumber',
  'externalReference',
  'name',
  'portalAdminContact',
  'salesRepresentativeEmail',
  'website',
] as const;
type CompanyInformationField = (typeof COMPANY_INFORMATION_FIELDS)[number];

type CompanyInformationFormData = Record<CompanyInformationField, string>;

export function CompanyInformationForm({
  company,
  managers,
  onSubmit,
  ...props
}: { company: CompanyJson; managers: Manager[] } & DialogFormProps) {
  const formName = props.name;

  const getFieldLabel = useGetFieldLabel();

  const labels = useMemo(
    () => ({ postalAddress: getFieldLabel(formName, 'postalAddress', 'company') }),
    [getFieldLabel, formName],
  );

  const formApiRef = useRef<FormApi<CompanyInformationFormData>>(null);
  const isInvalidUid = useRef(false);

  const { updateOne } = useApi('company');
  const [errorAlert, setErrorAlert] = useFormErrorAlertProp();
  const getErrorLabel = useGetErrorLabel();
  const isAdmin = useIsEditor('adminCompanies');

  const { search: postalAddress, Autocomplete: PostalAddressAutocomplete } = useAddressAutocomplete({
    defaultValue: company.postalAddress,
  });

  const managerOptions = useMemo(
    (): SelectOption[] =>
      managers?.map(({ _id, firstname, lastname }) => ({ label: `${firstname} ${lastname}`, value: _id })) ?? [],
    [managers],
  );

  const defaultValues = useMemo(() => {
    const values: Partial<CompanyInformationFormData> = pick(company, COMPANY_INFORMATION_FIELDS);

    if (!managers?.some(({ _id: managerId }) => managerId === company.portalAdminContact)) {
      delete values.portalAdminContact;
    }

    return values;
  }, [company, managers]);

  const parts = useMemo(
    (): FormParts<CompanyInformationFormData> => [
      { fieldName: 'name', props: { required: true } },
      () => <PostalAddressAutocomplete freeSolo textFieldProps={{ label: labels.postalAddress, required: true }} />,
      [
        { fieldName: 'contactPhoneNumber', format: formatPhoneNumber, props: { required: true, type: 'tel' } },
        { fieldName: 'portalAdminContact', props: { options: managerOptions, select: true } },
      ],
      [{ fieldName: 'contactEmail', props: { required: true, type: 'email' } }, { fieldName: 'website' }],
      [
        {
          getError: () => {
            if (isInvalidUid.current) {
              return getErrorLabel(formName, 'externalReference', 'invalid')();
            }
          },
          fieldName: 'externalReference',
          onChange: () => {
            isInvalidUid.current = false;
          },
          props: { InputProps: { readOnly: Boolean(company.externalReference) && !isAdmin } },
        },
        {
          fieldName: 'salesRepresentativeEmail',
          props: { InputProps: { readOnly: !isAdmin }, type: 'email' },
        },
      ],
    ],
    [
      company.externalReference,
      formName,
      getErrorLabel,
      isAdmin,
      labels.postalAddress,
      managerOptions,
      PostalAddressAutocomplete,
    ],
  );

  const handleSubmit = useCallback(
    async (formData: CompanyInformationFormData) => {
      const { error } = await updateOne('', { ...formData, postalAddress });

      setErrorAlert(error);

      if (error) {
        if (error.code.startsWith('COMPANIES_UID')) {
          isInvalidUid.current = true;
          formApiRef.current?.refresh();
          formApiRef.current?.setDirty('externalReference');
        }
      } else {
        onSubmit?.();
      }
    },
    [onSubmit, postalAddress, setErrorAlert, updateOne],
  );

  return (
    <Form
      alert={errorAlert}
      apiRef={formApiRef}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      parts={parts}
      resourceType="company"
      {...(!postalAddress && { submitBtnProps: { disabled: true } })}
      {...props}
    />
  );
}

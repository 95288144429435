import { Box, Divider, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PageTab, TabDefinition, TourHighlightId } from '../../common/pages';
import { useAuthorizedItems } from '../../hooks/useAuthorizedItems';
import { useTabQueryParam } from '../../hooks/useTabQueryParam';
import { FlexBox } from '../atoms';
import { TourHighlight } from './TourHighlight';

type Tab = { index: number; label: ReactNode } & PageTab;

const LABEL_PADDING = 4;

export function Tabs({
  onChange,
  name,
  tabs: propsTabs,
}: {
  name: string;
  onChange?: (tab: Tab) => void;
  tabs: Readonly<TabDefinition[]>;
}) {
  const authorizedTabs = useAuthorizedItems(propsTabs);
  const [tabParam, setTabParam] = useTabQueryParam();
  const { t } = useTranslation();

  const tabs = useMemo(
    (): Readonly<Tab[]> =>
      authorizedTabs.map((tab, index) => ({ ...tab, index, label: tab.label ?? t(`tabs.${name}.labels.${tab.key}`) })),
    [authorizedTabs, name, t],
  );

  const tabIndex = useMemo(() => {
    const index = Number(tabParam?.value ?? '0');
    return index > 0 && index < tabs.length ? index : 0;
  }, [tabParam?.value, tabs.length]);

  const handleTabChange = useCallback(
    (_: SyntheticEvent, index: number) => {
      setTabParam(index);
    },
    [setTabParam],
  );

  const HighlightedLabel = useCallback(
    ({ highlightId, label }: { highlightId: TourHighlightId; label: ReactNode }) => (
      <TourHighlight
        Component={FlexBox}
        ComponentProps={{ children: label, sx: { alignItems: 'center', height: '100%', px: LABEL_PADDING } }}
        id={highlightId}
      />
    ),
    [],
  );

  useEffect(() => {
    if (tabs.length) {
      onChange?.(tabs[tabIndex]);
    }
  }, [onChange, tabIndex, tabs]);

  return (
    <>
      <MuiTabs
        onChange={handleTabChange}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'background.default',
            borderLeft: 1,
            borderRight: 1,
            transition: 'none',
          },
        }}
        value={tabIndex}
        variant="scrollable"
      >
        {tabs.map(({ highlightId, index, key, label }) => {
          return (
            <MuiTab
              key={key}
              label={highlightId ? <HighlightedLabel highlightId={highlightId} label={label} /> : label}
              sx={[
                {
                  borderColor: 'common.black',
                  borderRadius: '7px 7px 0 0',
                  borderStyle: 'solid',
                  fontWeight: 600,
                  px: LABEL_PADDING,
                  textTransform: 'none',
                },
                index === tabIndex
                  ? { backgroundColor: 'background.default', borderWidth: 1 }
                  : { backgroundColor: ({ palette: { background } }) => background.primary, borderBottomWidth: 1 },
                index === 0 && { ml: 2 },
                index < tabs.length - 1 && { mr: 0.8 },
                Boolean(highlightId) && { p: 0 },
              ]}
              value={index}
            />
          );
        })}
      </MuiTabs>

      <Divider sx={{ borderColor: 'common.black', position: 'relative', top: -1, zIndex: -1 }} />

      <Box sx={{ mt: 2 }}>{tabs[tabIndex]?.component}</Box>
    </>
  );
}

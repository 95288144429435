import {
  InputAdornment,
  MenuItem,
  MenuItemProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  TextFieldVariants,
} from '@mui/material';
import { ButtonHTMLAttributes, ReactNode, useMemo } from 'react';

import { CURRENCY, Lang } from '@sbiz/common';
import { omit } from '@sbiz/util-common';

import { styledProps } from '../../common/styles';
import { FlexBox } from './FlexBox';

export const HELPER_TEXT_HEIGHT = 32;

const NUMBER_TYPES = ['amount', 'number'] as const;
type NumberType = (typeof NUMBER_TYPES)[number];

export type SelectOptionObject<TValue = string> = {
  label: string;
  menuItemProps?: MenuItemProps;
  renderValue?: ReactNode;
  value: TValue;
};

export type SelectOption<TValue = string> = SelectOptionObject<TValue> | (() => ReactNode);

export type TextFieldProps<T extends TextFieldVariants = TextFieldVariants, TValue = string> = Omit<
  MuiTextFieldProps<T>,
  'select'
> &
  (
    | {
        multiple?: boolean;
        options: SelectOption<TValue extends unknown[] ? TValue[number] : TValue>[];
        select: true;
      }
    | { multiple?: never; options?: never; select?: false | undefined }
  );

export function TextField<T extends TextFieldVariants>({
  multiple,
  options: propsOptions,
  ...props
}: TextFieldProps<T>) {
  const options = useMemo(() => {
    if (propsOptions) {
      return propsOptions;
    }

    if (props?.type === 'lang-select') {
      return [
        { label: 'FR', value: 'fr' },
        { label: 'DE', value: 'de' },
        { label: 'IT', value: 'it' },
        { label: 'EN', value: 'en' },
      ] as SelectOption<Lang>[];
    }
  }, [props?.type, propsOptions]);

  const textFieldProps = useMemo(() => {
    const { FormHelperTextProps, inputProps, InputProps, onBlur, onChange, SelectProps, select, type } = props;
    const textFieldProps = { ...props };

    textFieldProps.FormHelperTextProps = styledProps(
      { minHeight: HELPER_TEXT_HEIGHT },
      { component: FlexBox, ...FormHelperTextProps },
    );

    textFieldProps.InputProps = styledProps(
      { backgroundColor: InputProps?.readOnly ? 'action.disabledBackground' : 'white' },
      InputProps,
    );

    if (select || type?.endsWith('-select')) {
      textFieldProps.select = true;
      textFieldProps.SelectProps = {
        ...SelectProps,
        MenuProps: styledProps({ maxHeight: 300 }, SelectProps?.MenuProps),
        multiple,
      };
    }

    if (!multiple) {
      textFieldProps.onBlur = (event) => {
        const { value } = event.target;

        let formattedValue = value.trim();

        if (NUMBER_TYPES.includes(type as NumberType)) {
          formattedValue = getFormattedNumber(formattedValue, inputProps?.step);
        }

        if (formattedValue !== value) {
          event.target.value = formattedValue;
          onChange?.(event);
        }

        onBlur?.(event);
      };

      if (NUMBER_TYPES.includes(type as NumberType)) {
        if (type === 'amount') {
          textFieldProps.InputProps.startAdornment = (
            <>
              <InputAdornment position="start">{CURRENCY}</InputAdornment>
              {InputProps?.startAdornment}
            </>
          );
        }

        textFieldProps.type = 'text';
        textFieldProps.inputProps = { inputMode: 'decimal', ...inputProps };

        textFieldProps.onChange = (event) => {
          const { value } = event.target;

          if (value && !isValidNumber(value, inputProps?.max)) {
            event.preventDefault();
          } else {
            if (inputProps?.step && value && /\d+[.,]\d{2}$/.test(value)) {
              event.target.value = getFormattedNumber(value, inputProps.step);
            }

            onChange?.(event);
          }
        };
      } else if (inputProps) {
        const { maxLength, pattern } = inputProps;

        if (maxLength || pattern) {
          textFieldProps.onChange = (event) => {
            const { value } = event.target;

            const isInvalidLength = value && typeof maxLength === 'number' && value.length > maxLength;
            const isInvalidPattern = value && pattern && !pattern.test(value);

            if (isInvalidLength || isInvalidPattern) {
              event.preventDefault();
            } else {
              onChange?.(event);
            }
          };
        }
      }
    }

    if (textFieldProps.inputProps?.pattern instanceof RegExp) {
      textFieldProps.inputProps = omit(textFieldProps.inputProps, ['pattern']);
    }

    return textFieldProps;
  }, [multiple, props]);

  return (
    <MuiTextField {...textFieldProps}>
      {textFieldProps?.select &&
        options?.map((option, index) => {
          if (typeof option === 'function') {
            const Option = option;
            return <Option key={index} />;
          }

          const { label, menuItemProps, value } = option;
          return (
            <MenuItem key={index} value={value as ButtonHTMLAttributes<unknown>['value']} {...menuItemProps}>
              {label}
            </MenuItem>
          );
        })}
    </MuiTextField>
  );
}

export function getFormattedNumber(value: number | string, step?: number) {
  if (value) {
    const number = typeof value === 'string' ? parseFloat(getFormattedSeparator(value)) : value;

    if (step && step !== 0.01) {
      const digitCount = step % 1 === 0 ? 0 : 2;
      return (Math.round(number / step) * step).toFixed(digitCount);
    }

    return number.toFixed(2);
  }

  return '';
}

function getFormattedSeparator(numberValue: string) {
  return numberValue.replace(',', '.');
}

function isValidNumber(value: string, max?: number) {
  const isValidString = /^\d+(?:[.,]\d{0,2})?$/.test(value);

  if (isValidString) {
    const number = parseFloat(getFormattedSeparator(value));
    return number <= (max ?? 1_000_000);
  }

  return false;
}

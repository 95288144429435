import { LinearProgress, Paper } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSlots,
  GridSortModel,
  gridClasses,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { pick } from '@sbiz/util-common';

import { styledProps } from '../../../common/styles';
import {
  getHeaderNameKeys,
  getTableLabelKeys,
  TABLE_PAGE_SIZE_OPTIONS,
  TableColDef,
  TableName,
  TableValidRowModel,
} from '../../../common/tables';
import { NoRowsOverlay } from './NoRowsOverlay';

export type TableDataGridProps<T extends TableValidRowModel> = {
  columns: TableColDef<T>[];
  count?: number;
  isLoading?: boolean;
  name: TableName;
  onRowSelectionModelChange?: (ids: string[], details: GridCallbackDetails) => void;
  rows: T[];
} & Omit<DataGridProps, 'columns' | 'onRowSelectionModelChange' | 'rows'>;

export function TableDataGrid<T extends { _id: string }>({
  checkboxSelection,
  columns,
  count,
  isLoading,
  name,
  onPaginationModelChange,
  onRowSelectionModelChange,
  onSortModelChange,
  rows,
  ...props
}: TableDataGridProps<T>) {
  const { t } = useTranslation();

  const getLabelKeys = useCallback((labelName: string) => getTableLabelKeys(name, labelName), [name]);

  const labels = useMemo(
    () => ({
      checkboxSelectionHeaderName: t(getHeaderNameKeys(name, 'checkboxSelection')),
      columnMenuFilter: t(getLabelKeys('columnMenuFilter')),
      columnMenuHideColumn: t(getLabelKeys('columnMenuHideColumn')),
      columnMenuManageColumns: t(getLabelKeys('columnMenuManageColumns')),
      columnMenuShowColumns: t(getLabelKeys('columnMenuShowColumns')),
      columnMenuSortAsc: t(getLabelKeys('columnMenuSortAsc')),
      columnMenuSortDesc: t(getLabelKeys('columnMenuSortDesc')),
      columnMenuUnsort: t(getLabelKeys('columnMenuUnsort')),
      columnsPanelHideAllButton: t(getLabelKeys('columnsPanelHideAllButton')),
      columnsPanelShowAllButton: t(getLabelKeys('columnsPanelShowAllButton')),
      columnsPanelTextFieldLabel: t(getLabelKeys('columnsPanelTextFieldLabel')),
      columnsPanelTextFieldPlaceholder: t(getLabelKeys('columnsPanelTextFieldPlaceholder')),
      footerRowSelected: (count: number) =>
        t(getLabelKeys(`footerRowSelected.${count > 1 ? 'plural' : 'singular'}`), { count }),
      rowsPerPage: t(getLabelKeys('rowsPerPage')),
    }),
    [getLabelKeys, name, t],
  );

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const apiRef = useGridApiRef();

  const datagridProps = useMemo(() => {
    const cellFocusSelector = [gridClasses.cell, gridClasses.columnHeader]
      .flatMap((className) => ['focus', 'focus-within'].map((pseudoClass) => `& .${className}:${pseudoClass}`))
      .join(', ');

    const headerSelector = `.${gridClasses.columnHeader}`;

    return styledProps(
      {
        [headerSelector]: { backgroundColor: ({ palette: { background } }) => background.primary },
        [cellFocusSelector]: { outline: 'none' },
      },
      props,
    );
  }, [props]);

  const localeText = useMemo(
    () => ({
      ...pick(labels, [
        'checkboxSelectionHeaderName',
        'columnMenuFilter',
        'columnMenuHideColumn',
        'columnMenuManageColumns',
        'columnMenuShowColumns',
        'columnMenuSortAsc',
        'columnMenuSortDesc',
        'columnMenuUnsort',
        'columnsPanelHideAllButton',
        'columnsPanelShowAllButton',
        'columnsPanelTextFieldLabel',
        'columnsPanelTextFieldPlaceholder',
        'footerRowSelected',
      ]),
      MuiTablePagination: { labelRowsPerPage: labels.rowsPerPage },
    }),
    [labels],
  );

  const handlePaginationModelChange = useCallback(
    (pagination: GridPaginationModel, details: GridCallbackDetails) => {
      onPaginationModelChange?.(pagination, details);
    },
    [onPaginationModelChange],
  );

  const handleRowSelectionModelChange = useCallback(
    (selectedIds: GridRowSelectionModel, details: GridCallbackDetails) => {
      const rowIds = selectedIds as string[];
      onRowSelectionModelChange?.(rowIds, details);
      setSelectedIds(rowIds);
    },
    [onRowSelectionModelChange],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel, details: GridCallbackDetails) => {
      onSortModelChange?.(sortModel, details);
    },
    [onSortModelChange],
  );

  return (
    <Paper>
      <DataGrid
        apiRef={datagridProps?.apiRef ?? apiRef}
        autoHeight
        checkboxSelection={checkboxSelection}
        columns={columns}
        disableColumnFilter
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id}
        loading={isLoading}
        localeText={localeText}
        onPaginationModelChange={handlePaginationModelChange}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        onSortModelChange={handleSortModelChange}
        pagination
        paginationMode="server"
        rowCount={count ?? 0}
        pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
        rows={rows}
        rowSelectionModel={selectedIds}
        slots={{ loadingOverlay: LinearProgress as GridSlots['loadingOverlay'], noRowsOverlay: NoRowsOverlay }}
        sortingMode="server"
        {...datagridProps}
      />
    </Paper>
  );
}

import { Box } from '@mui/material';

import { OrderProduct, OrderProductChoice } from '@sbiz/business';

import { FlexBox, Money, Span } from '../../../../atoms';
import { ProductOptions, ProductOptionsProps } from './ProductOptions';

export type OrderProductDisplay = {
  name: string;
  options: ({ name: string } & Omit<OrderProductChoice, 'name'>)[];
} & Omit<OrderProduct, 'combinationChoices' | 'name'>;

export type ProductProps = { isExport?: boolean; labels: ProductOptionsProps['labels']; product: OrderProductDisplay };

export function Product({
  isExport,
  labels,
  product: { name, description, options, quantity, totalPrice },
}: ProductProps) {
  return (
    <FlexBox sx={{ gap: 2, alignItems: 'center' }}>
      <Span sx={{ padding: 1, border: 1, borderColor: 'grey.300', fontWeight: 'bold' }}>{quantity}</Span>

      <FlexBox column sx={{ gap: 0.3 }}>
        <Box>
          <Span isStrong>{name}</Span>
        </Box>

        {description && <Box sx={{ fontSize: '0.92em', color: 'grey.600' }}>{description}</Box>}

        <ProductOptions isExport={isExport} labels={labels} options={options} />
      </FlexBox>

      <Span sx={{ ml: 'auto' }}>
        <Money sx={{ fontWeight: 'bold' }} value={totalPrice * 100} />
      </Span>
    </FlexBox>
  );
}

import { ReactNode } from 'react';

import { PageTabs } from '../../../../common/pages';
import { CompaniesTable } from '../../../../components/pages/admin/Company/CompaniesTable';
import { DatatransTransactionsAdminTable } from '../../../../components/pages/admin/DatatransTransactionsAdminTable';
import { GiftCardsAdminTable } from '../../../../components/pages/admin/GiftCardRequest/GiftCardsAdminTable';
import { ManagersAdminTable } from '../../../../components/pages/admin/ManagersAdminTable';
import { StatementsAdminTable } from '../../../../components/pages/admin/OpenInvoices/StatementsAdminTable';
import { CreditCard } from '../../../../components/pages/company/CreditCard';
import { EmployeeInvitationsTable } from '../../../../components/pages/employees/EmployeeInvitationsTable';
import { EmployeesTable } from '../../../../components/pages/employees/EmployeesTable';
import { ManagerInvitationsTable } from '../../../../components/pages/managers/ManagerInvitationsTable';
import { ManagersTable } from '../../../../components/pages/managers/ManagersTable';
import { CheckDistributionsTable } from '../../smood-checks/CheckDistributionsTable';
import { ChecksTable } from '../../smood-checks/ChecksTable';

export const TAB_COMPONENTS = {
  admin: {
    companies: <CompaniesTable />,
    giftCards: <GiftCardsAdminTable />,
    payments: <DatatransTransactionsAdminTable />,
    permissions: <ManagersAdminTable />,
    statements: <StatementsAdminTable />,
  },
  company: { costCenters: null, creditCard: <CreditCard />, information: null, locations: null },
  employees: { employees: <EmployeesTable />, invitations: <EmployeeInvitationsTable /> },
  managers: { invitations: <ManagerInvitationsTable />, managers: <ManagersTable /> },
  smoodChecks: { checks: <ChecksTable />, distributions: <CheckDistributionsTable /> },
} as const satisfies { [K in keyof PageTabs]?: { [P in PageTabs[K][number]['key']]: ReactNode } };

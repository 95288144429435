import { useCallback, useMemo, useState } from 'react';

import { EmployeeInvitation } from '../../../../common/api/resources/employee-invitation';
import { FULLNAME_TABLE_COLUMN, nestedValueGetter, TableColDef, TableName } from '../../../../common/tables';
import { useIsEditor } from '../../../../hooks/useIsEditor';
import { useTableConfigQueryParam } from '../../../../hooks/useTableConfigQueryParam';
import { TableCellLink } from '../../../atoms/TableCellLink';
import { FormDialog } from '../../../molecules';
import { DialogFormProps } from '../../../molecules/FormDialog';
import { Table } from '../../../organisms';
import { TableProps } from '../../../organisms/Table';
import { EmployeeForm } from '../EmployeeForm';
import { EmployeesTransfer } from './EmployeesTransfer';

const EMPLOYEES_TABLE_NAME = 'employees';
const PROFILE_EMPLOYEES_TABLE_NAME = 'employees';

export function EmployeesTable({ profileId }: { profileId?: string }) {
  const [editedEmployee, setEditedEmployee] = useState<EmployeeInvitation>();
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);

  const isEditor = useIsEditor('employees');
  const [tableConfig] = useTableConfigQueryParam(EMPLOYEES_TABLE_NAME);

  const columns = useMemo(() => {
    const columns: TableColDef<EmployeeInvitation>[] = [
      {
        ...FULLNAME_TABLE_COLUMN,
        renderCell: ({ formattedValue, row: employee }) =>
          employee.deletedAt ? undefined : (
            <TableCellLink
              onClick={() => {
                setEditedEmployee(employee);
              }}
            >
              {formattedValue}
            </TableCellLink>
          ),
      },
      { field: 'email' },
    ];

    if (!profileId) {
      columns.push({
        field: 'profile.name',
        renderCell: ({ row: { profile }, formattedValue }) => (
          <TableCellLink href={`/profiles/${profile._id}`}>{formattedValue}</TableCellLink>
        ),
        valueGetter: nestedValueGetter,
      });
    }

    columns.push(
      { field: 'employeeId', sortable: false },
      { field: 'enrolledAt', valueType: 'date' },
      { field: 'startDate', valueType: 'date' },
      { field: 'endDate', valueType: 'date' },
    );

    return columns;
  }, [profileId]);

  const dataGridProps = useMemo(
    (): TableProps<EmployeeInvitation>['dataGridProps'] => ({
      onRowSelectionModelChange: (ids) => setEmployeeIds(ids),
    }),
    [],
  );

  const Form = useMemo(
    () => editedEmployee && ((props: DialogFormProps) => <EmployeeForm employee={editedEmployee} {...props} />),
    [editedEmployee],
  );

  const filter = useMemo(() => (profileId ? { 'profile._id': { $oid: profileId } } : undefined), [profileId]);

  const headerProps = useMemo(
    (): TableProps<EmployeeInvitation>['headerProps'] =>
      tableConfig?.isDeletions || !isEditor
        ? undefined
        : { actions: { extra: <EmployeesTransfer currentProfileId={profileId} employeeIds={employeeIds} /> } },
    [employeeIds, isEditor, profileId, tableConfig?.isDeletions],
  );

  const name = useMemo((): TableName => (profileId ? PROFILE_EMPLOYEES_TABLE_NAME : EMPLOYEES_TABLE_NAME), [profileId]);

  const closeFormDialog = useCallback(() => {
    setEditedEmployee(undefined);
  }, []);

  return (
    <>
      <Table headerProps={headerProps} columns={columns} dataGridProps={dataGridProps} filter={filter} name={name} />
      <FormDialog Form={Form} name="employeeUpdate" onClose={closeFormDialog} open={Boolean(editedEmployee)} />
    </>
  );
}

import { useMemo } from 'react';

import { styledProps } from '../../common/styles';
import { Span, SpanProps } from './Span';

export function Multiline({ text, ...props }: { text: string } & SpanProps) {
  const spanProps = useMemo(() => styledProps({ whiteSpace: 'pre-line' }, props), [props]);

  return <Span {...spanProps}>{text}</Span>;
}

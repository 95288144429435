import { Add } from '@mui/icons-material';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyJson } from '@sbiz/business';

import { FlexBox, RestrictedButton } from '../../../atoms';
import { FormDialog } from '../../../molecules';
import { DialogFormProps } from '../../../molecules/FormDialog';
import { LocationForm } from './LocationForm';
import { LocationList } from './LocationList';

export function CompanyLocations({ company, onChange }: { company: CompanyJson; onChange: () => void }) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      addBtn: t('pages.company_locations.addBtn'),
      title: t('pages.company_locations.title'),
    }),
    [t],
  );

  const [isCreation, setIsCreation] = useState(false);

  const closeCreationDialog = useCallback(() => {
    setIsCreation(false);
  }, []);

  const CreationForm = useCallback(
    (props: DialogFormProps) => <LocationForm company={company} {...props} />,
    [company],
  );

  const openCreationDialog = useCallback(() => {
    setIsCreation(true);
  }, []);

  return (
    <>
      <Card sx={{ maxWidth: ({ breakpoints }) => breakpoints.values.md, mx: 'auto', p: 2 }}>
        <CardHeader title={labels.title}></CardHeader>

        <CardContent>
          <FlexBox column sx={{ gap: 4 }}>
            <LocationList company={company} onDeletion={onChange} />
            <RestrictedButton
              onClick={openCreationDialog}
              scope="companyLocation"
              startIcon={<Add />}
              sx={{ mx: 'auto' }}
            >
              {labels.addBtn}
            </RestrictedButton>
          </FlexBox>
        </CardContent>
      </Card>

      <FormDialog Form={CreationForm} name="locationCreation" onClose={closeCreationDialog} open={isCreation} />
    </>
  );
}

import NextImage, { ImageProps } from 'next/legacy/image';

import { useConfigContext } from '../../contexts/ConfigContext';

const PREFIX = 'cdn:';

export function Image(props: ImageProps) {
  const {
    cdn: { imagesPath, url },
  } = useConfigContext();

  if (typeof props.src === 'string' && props.src.startsWith(PREFIX)) {
    props.src = props.src.replace(PREFIX, `${url}/${imagesPath}/`);
  }

  return <NextImage {...props} />;
}

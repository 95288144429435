import { ReactNode, useMemo } from 'react';

import { useGetSmoodUrl } from '../../hooks/useGetSmoodUrl';
import { Link } from './Link';

export function SmoodLink({ children, path }: { children: ReactNode; path: string }) {
  const getSmoodUrl = useGetSmoodUrl();

  const href = useMemo(() => getSmoodUrl(path), [getSmoodUrl, path]);

  return (
    <Link href={href} target="_blank" rel="noreferrer">
      {children}
    </Link>
  );
}

import { useCallback } from 'react';

import { CsvFileUploadFormData } from '../../../../../common/api';
import { useApi } from '../../../../../common/api/hooks/useApi';
import { FileUploadFormProps } from '../../../../organisms/FileDialog';
import { FileUploadForm } from '../../../../organisms/FileUploadForm';

export function CostCentersFileUploadForm(props: FileUploadFormProps) {
  const { uploadCsvFile } = useApi('company');

  const fileUploader = useCallback(
    (file: File, formData: CsvFileUploadFormData) => uploadCsvFile('cost-centers/file', file, formData),
    [uploadCsvFile],
  );

  return <FileUploadForm fileUploader={fileUploader} {...props} />;
}

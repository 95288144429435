import { forwardRef, useMemo } from 'react';

import { styledProps } from '../../common/styles';
import { Button, ButtonProps } from './Button';

export const RoundButton = forwardRef<HTMLButtonElement, { size: number } & Omit<ButtonProps, 'size'>>(
  function RoundButton({ size, ...props }, ref) {
    const buttonProps = useMemo(
      () => styledProps({ borderRadius: '50%', height: size, minWidth: size, p: 0, width: size }, props),
      [props, size],
    );

    return <Button ref={ref} {...buttonProps} />;
  },
);

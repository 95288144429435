import { Box, BoxProps } from '@mui/material';
import { forwardRef, Ref } from 'react';

import { styledProps } from '../../common/styles';

export type SpanProps = Omit<BoxProps, 'component'> & { ref?: Ref<HTMLSpanElement>; isStrong?: boolean | undefined };

export const Span = forwardRef<HTMLSpanElement, SpanProps>(function Span({ isStrong, ...props }, ref) {
  return <Box {...styledProps({ fontWeight: isStrong ? 'bold' : 'inherit' }, props)} component="span" ref={ref} />;
});

import { DialogContent, DialogContentText } from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, Feedback } from '../atoms';
import { DialogCloseReason, DialogProps } from '../atoms/Dialog';
import { FeedbackProps } from '../atoms/Feedback';

export type DialogFormProps<T = unknown> = {
  name: string;
  onSubmit: (data?: T, options?: { feedbackText?: string }) => void;
};

export type FormDialogCloseReason = 'formSubmission' | DialogCloseReason;
export type FormDialogOverrideProps<T = unknown> = Omit<FormDialogProps<T>, 'Form' | 'name'>;
export type FormDialogProps<T = unknown> = {
  disableFeedback?: boolean;
  Form: ((props: DialogFormProps<T>) => ReactNode) | false | null | undefined;
  name: string;
  onClose: (reason: FormDialogCloseReason, data?: T) => void;
  text?: string;
} & Omit<DialogProps, 'onClose'>;

export function FormDialog<T>({
  disableFeedback: isFeedbackDisabled,
  Form,
  name,
  text: propsText,
  ...props
}: FormDialogProps<T>) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({ text: propsText ?? t(`dialogs.${name}.text`, { defaultValue: '' }) }),
    [name, propsText, t],
  );

  const [feedbackProps, setFeedbackProps] = useState<Pick<FeedbackProps, 'open' | 'labels'>>({ open: false });

  const closeFeedback = useCallback(() => {
    setFeedbackProps((props) => ({ ...props, open: false }));
  }, []);

  const handleSubmit = useCallback(
    (data?: T, options?: { feedbackText?: string }) => {
      if (!isFeedbackDisabled) {
        setFeedbackProps({ open: true, ...(options?.feedbackText && { labels: { success: options.feedbackText } }) });
      }

      props.onClose?.('formSubmission', data);
    },
    [isFeedbackDisabled, props],
  );

  return (
    <>
      <Dialog isBackdropClickDisabled isCloseButtonEnabled name={name} {...props}>
        <DialogContent>
          {labels.text && <DialogContentText>{labels.text}</DialogContentText>}
          {Form && <Form name={name} onSubmit={handleSubmit} />}
        </DialogContent>
      </Dialog>

      <Feedback name={name} severity="success" onClose={closeFeedback} {...feedbackProps} />
    </>
  );
}

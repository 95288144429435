import { DialogContent, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StatusChange } from '../../../../common/api/resources/gift-card';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { Dialog, FlexBox } from '../../../atoms';
import { DialogProps } from '../../../atoms/Dialog';

export function GiftCardHistoryDialog({ changes, ...props }: { changes?: StatusChange[] } & DialogProps) {
  const formatDate = useFormatDate();
  const { t } = useTranslation();

  return (
    <Dialog name="giftCardHistory" isCloseButtonEnabled {...props}>
      <DialogContent>
        <FlexBox column sx={{ gap: 2 }}>
          {changes?.map(({ _id, author, createdAt, status }) => (
            <Paper elevation={0} key={_id} sx={{ border: '2px solid', borderColor: 'primary.main', p: 1 }}>
              <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                {t(`status.${status.toLowerCase()}`)}
              </Typography>

              <Typography>
                {formatDate(createdAt, 'PPp')} - {author.firstname} {author.lastname}
              </Typography>
            </Paper>
          ))}
        </FlexBox>
      </DialogContent>
    </Dialog>
  );
}

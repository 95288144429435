import { Box, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Order, OrderProduct, OrderStore } from '@sbiz/business';

import useApiResource from '../../../../common/api/hooks/useApiResource';
import { useConvertToPdf } from '../../../../hooks/useConvertToPdf';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { useGetSmoodUrl } from '../../../../hooks/useGetSmoodUrl';
import { useTranslate } from '../../../../hooks/useTranslate';
import { Dialog, Feedback } from '../../../atoms';
import { OrderDetailsContent } from './OrderDetailsContent';
import { TOTAL_ROWS, TotalRow } from './Totals';

export type OrderDetailsProps = { admin?: boolean; onClose: () => void; number: string };

export function OrderDetails({ admin, onClose, number }: OrderDetailsProps) {
  const convertToPdf = useConvertToPdf();
  const formatDate = useFormatDate();
  const getSmoodUrl = useGetSmoodUrl();
  const { palette } = useTheme();
  const translate = useTranslate();
  const { t } = useTranslation();

  const { data: order, error } = useApiResource<
    'order' | 'orderAdmin',
    { products: OrderProduct[]; restaurant: OrderStore } & Order
  >(`order${admin ? 'Admin' : ''}`, number);

  const deliveredAt = useMemo(() => formatDate(order?.dropoffDeadline, 'PPp'), [formatDate, order?.dropoffDeadline]);
  const orderedAt = useMemo(() => formatDate(order?.createdAt, 'PPp'), [formatDate, order?.createdAt]);

  const labels = useMemo(() => {
    const totalRowLabels = Object.fromEntries(
      TOTAL_ROWS.map((row) => [row, t(`resources.order.propertyNames.${row}`)]),
    ) as Record<TotalRow, string>;

    return {
      deliveryPoints: {
        dropoff: {
          date: t(`dialogs.orderDetails.deliveryPoints.dropoffAddress.date`, { date: deliveredAt }),
          title: t(`dialogs.orderDetails.deliveryPoints.dropoffAddress.title`),
        },
        pickup: {
          date: t(`dialogs.orderDetails.deliveryPoints.pickupAddress.date`, { date: orderedAt }),
          title: t(`dialogs.orderDetails.deliveryPoints.pickupAddress.title`),
        },
      },
      product: {
        showLess: t('dialogs.orderDetails.productOptions.showLess'),
        showMore: `...${t('dialogs.orderDetails.productOptions.showMore')}`,
      },
      title: {
        at: t('dialogs.orderDetails.atStore'),
        download: t('buttons.download_pdf'),
        status: t(`resources.order.propertyValues.status.${order?.status}`),
        title: t('dialogs.orderDetails.title', {
          interpolation: { escapeValue: false },
          number: `<span style="color:${palette.primary.main}">${number}</span>`,
        }),
      },
      totals: {
        ...totalRowLabels,
        freeDelivery: t('dialogs.orderDetails.freeDelivery'),
        discounts: t('dialogs.orderDetails.discounts'),
        tip: t('dialogs.orderDetails.tip'),
      },
    };
  }, [deliveredAt, number, order?.status, orderedAt, palette.primary.main, t]);

  const products = useMemo(
    () =>
      order?.products?.map(({ combinationChoices, description, name, ...product }) => {
        const { categoryName } = product;
        const options =
          combinationChoices?.map(({ name, ...option }) => ({
            name: translate(name) as string,
            ...option,
          })) ?? [];

        return {
          ...product,
          description: description ?? (categoryName && translate(categoryName)),
          name: translate(name) as string,
          options,
        };
      }),
    [order?.products, translate],
  );

  const storeUrl = useMemo(() => {
    if (order?.restaurant?.url) {
      return getSmoodUrl(`store/${order.restaurant.url}`);
    }
  }, [getSmoodUrl, order?.restaurant?.url]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleExport = useCallback(
    async (orderNumber: string) => {
      if (order) {
        const node = (
          <Box sx={{ maxWidth: ({ breakpoints }) => breakpoints.values.sm, mx: 'auto' }}>
            <OrderDetailsContent isExport labels={labels} order={order} products={products} storeUrl={storeUrl} />
          </Box>
        );

        await convertToPdf(node, orderNumber);
      }
    },
    [convertToPdf, labels, order, products, storeUrl],
  );

  return (
    <>
      <Dialog isCloseButtonEnabled onClose={handleClose} open={Boolean(order)}>
        {order && (
          <OrderDetailsContent
            labels={labels}
            order={order}
            onExport={handleExport}
            products={products}
            storeUrl={storeUrl}
          />
        )}
      </Dialog>

      <Feedback name="orderDetails" open={Boolean(error)} onClose={handleClose} severity="warning" />
    </>
  );
}

import { Close } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';

export function CloseButton(props: IconButtonProps) {
  return (
    <IconButton {...props}>
      <Close />
    </IconButton>
  );
}
